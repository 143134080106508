import { Tooltip } from "@mui/material";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AddNewUserCard } from "src/common/components/AddNewUserCard/AddNewUserCard";
import { IsLoading } from "src/common/components/AppState/IsLoading";
import AvailableFeatureCounter from "src/common/components/AvailableCounter/AvailableFeatureCounter";
import Icon from "src/common/components/Icon/Icon";
import { useAppDispatch } from "src/common/state/hooks";
import { RootState } from "src/common/state/store";
import { setAlert } from "src/features/alert/state/alertsSlice";
import { getCurrentUser } from "src/features/profile/state/profileSlice";
import {
  setUserFromCsv,
  uploadNewParticipant,
} from "src/features/projectGroups/state/projectGroupsSlice";
import {
  fetchSubscription,
  getSubscriptionFeature,
} from "src/features/subscription/state/subscriptionSlice";
import { getEndusers } from "src/features/team/state/teamSlice";

const NEW_PARTICIPANTS_COMPONENT_ID = "newParticipantsComponentId";

const NewParticipants = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const hiddenFileInput = useRef(null);

  const participantsFeature = useSelector((state: RootState) =>
    getSubscriptionFeature(state, "number-of-available-enduser-accounts"),
  );
  const participants = useSelector(getEndusers);
  const currentUser = useSelector(getCurrentUser);

  const isComingSoon = false;

  const hasAvailableParticipantSlot = participantsFeature
    ? participantsFeature.QuantityCurrent > 0
    : false;

  const currentNumberOfAvailableParticipants = participantsFeature
    ? participantsFeature.QuantityCurrent
    : 0;

  useEffect(() => {
    dispatch(fetchSubscription({ componentId: NEW_PARTICIPANTS_COMPONENT_ID }));
  }, []);

  return (
    <div className="w-full h-full flex-col flex justify-center items-center">
      <IsLoading componentId={NEW_PARTICIPANTS_COMPONENT_ID} showSuccess={false} spinnerPlaceholder>
        <div className="w-full flex items-center justify-evenly mt-8">
          <div className="w-1/3">
            <AvailableFeatureCounter
              className="w-80 h-28"
              currentNumberOfAvailable={currentNumberOfAvailableParticipants}
              totalNumberOfAvailable={
                participantsFeature ? participantsFeature.QuantityCurrent + participants.length : 0
              }
              text={t("pages.participants.participants")}
            />
          </div>

          <div className="flex flex-col items-center w-1/3">
            <h3 className="text-2xl font-bold mb-2">{t("pages.participants.addNewParticipant")}</h3>
            <p>{t("pages.participants.chooseOneWayToAddParticipants")}</p>
          </div>
          <div className="w-1/3"></div>
        </div>

        <div className="h-full flex justify-center items-center mb-40">
          <AddNewUserCard
            icon={<Icon icon="UserAddIcon" className="w-8 h-8" />}
            title={t("pages.participants.addNewParticipant")}
            subtitle={<p className="text-sm">{t("pages.participants.addNewParticipantSubtext")}</p>}
            buttonText={t("pages.participants.classical")}
            buttonOnClick={() => navigate("/participants/new/invite-authenticated")}
            buttonDisabled={!hasAvailableParticipantSlot}
            buttonDisabledTooltip={t("pages.participants.noAvailableParticipantSlot")}
            additionalButtonText={t("pages.participants.simplified")}
            additionalButtonOnClick={() => navigate("/participants/new/invite-simplified")}
            additionalButtonDisabled={!hasAvailableParticipantSlot}
            additionalButtonDisabledTooltip={t("pages.participants.noAvailableParticipantSlot")}
          />
          <AddNewUserCard
            icon={<Icon icon="DocumentAddIcon" className="w-8 h-8" />}
            title={t("pages.participants.importCsv")}
            subtitle={
              <p className="text-sm text-center">
                {t("pages.participants.importCsvSubtext1")}&nbsp;
                <Tooltip title={t("tooltip.csvValidFormatShortVersion")}>
                  <p className="text-green-500 inline cursor-pointer">
                    {t("pages.participants.importCsvExampleLink")}
                  </p>
                </Tooltip>
                &nbsp;{t("pages.participants.importCsvSubtext2")}
              </p>
            }
            buttonText={t("general.upload")}
            buttonOnClick={() => {
              const hfi = hiddenFileInput?.current as any;
              hfi.click();
            }}
            buttonDisabled={!hasAvailableParticipantSlot || isComingSoon}
            buttonDisabledTooltip={
              isComingSoon
                ? t("pages.participants.comingSoonTooltip")
                : t("pages.participants.noAvailableParticipantSlot")
            }
          />
        </div>
        <input
          id="upload-input-csv"
          type="file"
          ref={hiddenFileInput}
          style={{ display: "none" }}
          accept={".csv, .xlsx"}
          onChange={(e: any) => {
            const uploadedFile = e.target.files[0] as File;

            if (uploadedFile) {
              dispatch(
                uploadNewParticipant({
                  userId: currentUser?.id ? currentUser.id : -1,
                  withMail: 0,
                  file: uploadedFile,
                  componentId: NEW_PARTICIPANTS_COMPONENT_ID,
                }),
              )
                .then((res: any) => {
                  e.target.value = null;
                  if (res.payload !== undefined) {
                    dispatch(setUserFromCsv(res.payload.data));
                    navigate("/participants/new/csv-import");
                  }
                })
                .catch(() => {
                  dispatch(
                    setAlert({
                      type: "danger",
                      msg: t("alerts.participantAddedFail"),
                    }),
                  );
                });
            }
          }}
        ></input>
      </IsLoading>
    </div>
  );
};

export default NewParticipants;
