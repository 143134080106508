import * as AllIcons from "@heroicons/react/outline";

interface ButtonSquareProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant:
    | "btn-primary-fill"
    | "btn-secondary-fill"
    | "btn-alternative-fill"
    | "btn-primary-outline"
    | "btn-secondary-outline"
    | "btn-alternative-outline";
  iconColor: string;
  className?: string;
  innerClassName?: string;
  text?: string;
  textClassName?: string;
}

const ButtonSquare = ({
  variant,
  iconColor,
  className = "",
  innerClassName = "grid grid-rows-6 w-36 h-28",
  text = "",
  textClassName = "text-white text-xs",
  ...props
}: ButtonSquareProps) => {
  return (
    <button
      className={`grid place-content-center rounded-xl font-medium w-full ${className} ${variant}`}
      {...props}
    >
      <div className={innerClassName}>
        <div className="row-span-2"></div>
        <span className="flex justify-center items-center row-span-2">
          <AllIcons.PlusIcon className={`w-6 h-6 ${iconColor}`} />
        </span>
        <div className={`row-span-2 flex items-center justify-center ${textClassName}`}>
          <p>{text}</p>
        </div>
      </div>
    </button>
  );
};

export default ButtonSquare;
