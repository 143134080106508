import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Navigation from "../../common/components/Navigation/Navigation";
import StepperMUI from "../../common/components/Stepper/Stepper";
import { getCurrentProject } from "../../features/projects/state/projectsSlice";
import ProjectNewName from "./ProjectNewName";
import ProjectThumbnail from "./ProjectThumbnail";
import ProjectLanguage from "./ProjectLanguage";

export default function EditProjectNameWrapper() {
  const { t } = useTranslation();

  const currentProject = useSelector(getCurrentProject);

  const [step, setStep] = useState(0);
  const incrementStep = () => setStep(step + 1);
  const decrementStep = () => setStep(step - 1);

  const renderStep = (step: number) => {
    switch (step) {
      case 0:
      default:
        return (
          <ProjectNewName incrementStep={incrementStep} decrementStep={decrementStep} mode="edit" />
        );
      case 1:
        return (
          <ProjectLanguage
            incrementStep={incrementStep}
            decrementStep={decrementStep}
            mode="edit"
          />
        );
      case 2:
        return <ProjectThumbnail decrementStep={decrementStep} mode="edit" />;
    }
  };
  return (
    <>
      <Navigation title={`${t("pages.editProjectName.title")}: ${currentProject.name}`} />{" "}
      <StepperMUI step={step} mode="editProject" />
      {renderStep(step)}
    </>
  );
}
