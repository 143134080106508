import { useState } from "react";
import { useTranslation } from "react-i18next";
import Navigation from "../../common/components/Navigation/Navigation";
import StepperMUI from "../../common/components/Stepper/Stepper";
import CreateProjectGroupAccess from "./CreateProjectGroupAccess";
import CreateProjectGroupName from "./CreateProjectGroupName";
import CreateProjectGroupThumbnail from "./CreateProjectGroupThumbnail";
import CreateProjectGroupTheme from "./CreateProjectGroupTheme";

export default function CreateProjectGroupWrapper() {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const incrementStep = () => setStep(step + 1);
  const decrementStep = () => setStep(step - 1);

  const renderStep = (step: number) => {
    switch (step) {
      case 0:
      default:
        return <CreateProjectGroupName incrementStep={incrementStep} />;
      case 1:
        return (
          <CreateProjectGroupThumbnail
            incrementStep={incrementStep}
            decrementStep={decrementStep}
          />
        );
      case 2:
        return (
          <CreateProjectGroupTheme incrementStep={incrementStep} decrementStep={decrementStep} />
        );
      case 3:
        return <CreateProjectGroupAccess decrementStep={decrementStep} />;
    }
  };
  return (
    <>
      <Navigation title={t("pages.createProjectGroup.title")} />
      <StepperMUI step={step} mode="newProjectGroup" />
      {renderStep(step)}
    </>
  );
}
