import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { IProjectGroupWithProjects } from "../../../features/projectGroups/state/projectGroupsSlice";
import { IProject } from "../../../model/model";
import PlaceholderWithIcon from "../PlaceholderWithIcon/PlaceholderWithIcon";
import ButtonSquareIconsProject from "./ButtonSquareIconsProject";
import useScrollbarVisibility from "../../util/useScrollbarVisibility";
import ButtonSquare from "./ButtonSquare";
import "./ProjectsInProjectGroup.scss";

interface IProjectsInProjectGroupProps {
  handleThreeDotButtonClick: ({
    item,
    itemId,
    action,
  }: {
    item: string;
    itemId: number;
    action: string;
  }) => void;
  numberOfRelevantProjectElements: number;
  projectGroupWithProjects?: IProjectGroupWithProjects;
  handleClick: ({
    project,
    projectGroup,
  }: {
    project: IProject;
    projectGroup: IProjectGroupWithProjects;
  }) => void;
  allProjectsEver: IProject[];
  simplified?: boolean;
  createNewProject: ({ projectGroup }: { projectGroup: IProjectGroupWithProjects }) => void;
}

const projectCardHeight = "h-[7.7rem]";

const ProjectsInProjectGroup = ({
  handleThreeDotButtonClick,
  projectGroupWithProjects,
  handleClick,
  createNewProject,
}: IProjectsInProjectGroupProps) => {
  const { t } = useTranslation();

  const ProjectGroupWithProjectsAndScrollbar = ({
    projectGroupWithProjects,
    handleClick,
  }: {
    projectGroupWithProjects: IProjectGroupWithProjects;
    handleClick({ project, projectGroup }: { project: IProject; projectGroup: any }): void;
  }) => {
    const MappedComponentsForProjectCardsInsideNewPGContainer = (
      <>
        <div className="w-36  mr-3 pb-2">
          <ButtonSquare
            className="h-[7.8rem]"
            variant="btn-primary-fill"
            iconColor="stroke-white"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation();
              createNewProject({ projectGroup: projectGroupWithProjects });
            }}
            text={t("pages.project.newProject")}
          />
        </div>
        {[...projectGroupWithProjects.project_group_elements]
          .filter((pge) => pge.project?.is_deleted !== true)

          .sort((a, b) => a.order - b.order)
          .map((customProjectGroupWithProjectGroupElementsKeyItem: any, i: number) => {
            const project = customProjectGroupWithProjectGroupElementsKeyItem?.project;
            const projectGroup = customProjectGroupWithProjectGroupElementsKeyItem?.project_group;
            return (
              <div
                key={
                  i +
                  customProjectGroupWithProjectGroupElementsKeyItem?.project.id +
                  `${Math.random()}`
                }
                className="w-40 mr-3"
              >
                <ButtonSquareIconsProject
                  width="w-36"
                  height={projectCardHeight}
                  showThreeDotsButton={false}
                  handleThreeDotButtonClick={(e: any) => {
                    handleThreeDotButtonClick(e);
                  }}
                  currentProject={project.id}
                  project={project}
                  onClick={() => {
                    handleClick({
                      project,
                      projectGroup,
                    });
                  }}
                />
              </div>
            );
          })}
      </>
    );

    if (projectGroupWithProjects.project_group_elements.length > 0) {
      return MappedComponentsForProjectCardsInsideNewPGContainer;
    } else {
      return (
        <div className="mx-auto">
          <button
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation();
              createNewProject({ projectGroup: projectGroupWithProjects });
            }}
            className="flex flex-col items-center h-4/6 "
          >
            <PlaceholderWithIcon
              title={t("pages.project.noProjectBody")}
              heroIcon="FolderAddIcon"
              stroke="rgb(0 200 137)"
              className="flex flex-col items-center h-full"
              strokeWidth={1}
              decorationTitle="text-green-500 underline underline-offset-1"
            />
          </button>
        </div>
      );
    }
  };

  /* Handle fancy gradients on scroll */
  const [scrollPosition, setScrollPosition] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const coverDivRef = useRef<HTMLDivElement>(null);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    setScrollPosition(e.currentTarget.scrollLeft);
  };

  const applyGradient = () => {
    if (containerRef.current && coverDivRef.current) {
      const container = containerRef.current;
      const scrollPosition = container.scrollLeft;
      const atStart = scrollPosition === 0;
      const numericalAtEnd = Math.abs(container.scrollWidth - container.offsetWidth);
      const atEnd = Math.abs(scrollPosition - numericalAtEnd) <= 1;

      const classesToRemove = ["right-gradient", "left-gradient", "left-and-right-gradient"];
      coverDivRef.current.classList.remove(...classesToRemove);

      if (atStart) {
        coverDivRef.current.classList.add("right-gradient");
      } else if (atEnd) {
        coverDivRef.current.classList.add("left-gradient");
      } else {
        coverDivRef.current.classList.add("left-and-right-gradient");
      }
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScroll as any);
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("scroll", handleScroll as any);
      }
    };
  }, []);

  useEffect(() => {
    applyGradient();
  }, [scrollPosition]);

  useEffect(() => {
    const handleWidthUpdate = () => {
      if (containerRef.current && coverDivRef.current) {
        const containerWidth = containerRef.current.clientWidth;
        coverDivRef.current.style.width = `${containerWidth + 1}px`;
      }
    };

    handleWidthUpdate();

    window.addEventListener("resize", handleWidthUpdate);
    return () => window.removeEventListener("resize", handleWidthUpdate);
  }, []);

  const isHorizontalScrollbarVisible =
    projectGroupWithProjects && useScrollbarVisibility(containerRef);

  const windowWidth = window.innerWidth;
  const pixelsTolost = 1920 - windowWidth;

  const pixelsForDiv = 1696 - pixelsTolost;
  const pixelsTorRem = String(pixelsForDiv / 16);

  return (
    <div className="relative ml-4 h-[61.5%]">
      <div
        ref={containerRef}
        onScroll={handleScroll}
        // not with tailwind because it doesn't work when changin the width of the div dynamically
        style={{ maxWidth: `${pixelsTorRem}rem` }}
        className={`
          h-full relative horizontal-project-cards-mapping flex overflow-y-hidden scroll whitespace-nowrap scroll-smooth  mr-3 personalize-scroll-visible project-group-projects-container`}
      >
        {projectGroupWithProjects && (
          <ProjectGroupWithProjectsAndScrollbar
            projectGroupWithProjects={projectGroupWithProjects}
            handleClick={handleClick}
          />
        )}
      </div>
      <div
        ref={coverDivRef}
        className="cover-project-cards"
        style={{ visibility: isHorizontalScrollbarVisible ? "visible" : "hidden" }}
      ></div>
    </div>
  );
};

export default ProjectsInProjectGroup;
