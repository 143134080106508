import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useAppDispatch } from "../../common/state/hooks";
import InformationComponent from "../ProjectGroups/InformationComponent";
import { prettifyFileSizeInBytes } from "../../common/util/prettifyFileSizeInBytes";
import { ILanguage } from "../../model/model";
import {
  getCurrentInteractionElements,
  getCurrentInteractions,
  getCurrentProject,
  getProjectSize,
} from "../../features/projects/state/projectsSlice";
import { IsLoading } from "../../common/components/AppState/IsLoading";
import { useTranslation } from "react-i18next";
import { getCurrentUser } from "../../features/profile/state/profileSlice";
import { languageConfig } from "../../common/util/langConfig";
import { dateConfig } from "src/common/util/DateConfig";
import { useNameFormatter } from "src/features/graph/util/NameFormatter";
export const PROJECT_ELEMENTS_COMPONENT_ID = "projectElements";
export const PROJECT_SIZE_COMPONENT_ID = "projectSize";

const ProjectInfo = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentUser = useSelector(getCurrentUser);
  const currentLocale = languageConfig((currentUser?.preferred_language as ILanguage)?.name);
  const currentProject = useSelector(getCurrentProject);

  useEffect(() => {
    dispatch(
      getCurrentInteractions({
        projectId: currentProject.id,
        componentId: PROJECT_ELEMENTS_COMPONENT_ID,
      }),
    );
    dispatch(
      getProjectSize({
        projectId: currentProject.id,
        componentId: PROJECT_SIZE_COMPONENT_ID,
      }),
    );
  }, [dispatch, currentProject.id]);

  const getElementsInteractions = useSelector(getCurrentInteractionElements);
  const { getSameFormattedModelNameThanUnity } = useNameFormatter();
  const elementsInteractionLength = Object.values(getElementsInteractions.interactions).length;
  const currentProjectCreator = currentProject;
  const createdByTranslate = currentProjectCreator.created_by
    ? currentProjectCreator.created_by
    : t("general.noCreator");

  const getDateCurrentLocale = (date) => {
    return new Date(date).toLocaleDateString(dateConfig(currentLocale));
  };

  return (
    <IsLoading componentId={PROJECT_SIZE_COMPONENT_ID} showSuccess={false}>
      <div className="relative grow w-full px-4 xl:px-0 mx-auto overflow-x-auto personalize-scroll-visible">
        <div className="ml-4">
          <h2 className="text-2xl font-medium mb-2 mt-4">Description</h2>
          <h3 className="py-3">{t("general.informationModule")}</h3>
          <InformationComponent title={t("general.moduleName")} moduleName={currentProject.name} />
          <InformationComponent
            title={t("general.weight")}
            moduleName={String(
              prettifyFileSizeInBytes(getElementsInteractions.sizeInMbs, currentLocale),
            )}
            infoTooltipText={t("tooltip.mediasWeightDetail")}
          />
          {currentProjectCreator.creator && (
            <InformationComponent title={"Creator"} moduleName={String(createdByTranslate)} />
          )}
          <InformationComponent
            title={t("general.createdAt")}
            moduleName={getDateCurrentLocale(currentProject.createdAt)}
          />
          <InformationComponent
            title={t("general.updatedAt")}
            moduleName={getDateCurrentLocale(currentProject.updatedAt)}
          />
          {currentProject.cloned_from?.name && (
            <InformationComponent
              title={t("general.duplicatedFrom")}
              moduleName={String(currentProject.cloned_from.name)}
            />
          )}
          <div className="relative grow w-full px-4 xl:px-0 mx-auto">
            <h2 className="text-2xl font-medium mb-2 mt-4 xl:mt-8">Elements</h2>
            <h3 className="py-3">{t("general.interactionsElements")}</h3>
            {elementsInteractionLength > 0 ? (
              Object.keys(getElementsInteractions.interactions).map((interaction, key) => {
                const title = getSameFormattedModelNameThanUnity(interaction);
                const objectValuesFromInteractions = Object.values(
                  getElementsInteractions.interactions,
                );

                return (
                  <InformationComponent
                    key={interaction}
                    title={String(title)}
                    moduleName={String(objectValuesFromInteractions[key])}
                  />
                );
              })
            ) : (
              <div className="text-center text-gray-500">{t("general.noElementsFound")}</div>
            )}
          </div>
        </div>
      </div>
    </IsLoading>
  );
};

export default ProjectInfo;
