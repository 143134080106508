import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ButtonText } from "../../common/components/Button";
import { useAppDispatch } from "../../common/state/hooks";
import { IsLoading } from "../../common/components/AppState/IsLoading";
import EnduserStatsOverview from "../../features/stats/components/EnduserStatsOverview/EnduserStatsOverview";

import {
  deleteProjectGroup,
  fetchAllCurrentElements,
  setSelectedProjectGroupFromId,
} from "../../features/projectGroups/state/projectGroupsSlice";
import ProjectGroupList, {
  PROJECT_GROUP_LIST_COMPONENT_ID,
} from "../../features/projectGroups/components/ProjectGroupList";
import { getCurrentUser } from "../../features/profile/state/profileSlice";
import handleThreeDotsButtonClickForProjectsAndProjectGroups from "../../common/util/handleThreeDotsButtonClickForProjectsAndProjectGroups";
import {
  closeConfirmationModal,
  openConfirmationModal,
} from "../../common/state/slice/modal/modalSlice";
import ModalConfirmation from "../../common/components/Modal/ModalConfirmation";
import {
  fetchCurrentProjectGroupCompletion,
  getEnduserProjectGroupsCompletion,
} from "../../features/enduserStats/state/enduserStatsSlice";
import IProjectGroupsCompletionItem from "../../features/enduserStats/dataTypes/IProjectGroupsCompletionItem";
import { ENDUSER_PROJECT_LIST_COMPONENT_ID } from "../EnduserProjectsList/EnduserProjectsList";
import { ENDUSER_STATS_PER_PROJECT_PAGE_ID } from "src/pages/Statistics/StatisticsDetail";
import { getAllSessionsByProjectGroup } from "src/features/stats/state/statsSlice";

const EnduserDashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const user = useSelector(getCurrentUser);
  const projectGroups = useSelector(getEnduserProjectGroupsCompletion);

  useEffect(() => {
    dispatch(getAllSessionsByProjectGroup({ componentId: ENDUSER_STATS_PER_PROJECT_PAGE_ID }));
  }, []);

  const [clickedProjectGroupId, setClickedProjectGroupId] = useState(0);

  const handleClick = (projectGroup: IProjectGroupsCompletionItem) => {
    dispatch(
      fetchCurrentProjectGroupCompletion({
        componentId: ENDUSER_PROJECT_LIST_COMPONENT_ID,
        buildId: projectGroup.latestVersion.buildId,
      }),
    );
    navigate(`../project-groups/${projectGroup.projectGroupId}/list`);
  };

  return (
    <>
      <header className="bg-white shadow sticky top-0 z-50">
        <div className="max-w-screen-2xl py-6 px-4 sm:px-6 lg:px-8 overflow-y-auto">
          <h1 className="text-3xl font-bold text-black">
            {t("pages.dashboard.hello")} {user?.firstname}
          </h1>
        </div>
      </header>

      <div className="w-full max-w-9xl mx-auto pt-6 sm:px-6 lg:px-8">
        <div className="flex space-x-6 items-end">
          <h2 className="text-2xl text-black font-medium">{t("general.projectGroups")}</h2>
          <ButtonText text={t("general.viewAll")} onClick={() => navigate("project-groups")} />
        </div>

        <div className="py-6 ">
          <IsLoading
            componentId={PROJECT_GROUP_LIST_COMPONENT_ID}
            showSuccess={false}
            spinnerPlaceholder
          >
            <ProjectGroupList
              isEnduser
              projectGroups={projectGroups as any}
              handleClick={handleClick}
              handleThreeDotButtonClick={(e: any) => {
                setClickedProjectGroupId(e.itemId);
                dispatch(setSelectedProjectGroupFromId(e.itemId));
                dispatch(
                  fetchAllCurrentElements({
                    projectGroupId: e.itemId,
                    componentId: PROJECT_GROUP_LIST_COMPONENT_ID,
                  }),
                );
                handleThreeDotsButtonClickForProjectsAndProjectGroups({
                  payload: e,
                  deleteCallback: () => {
                    dispatch(openConfirmationModal("deleteProjectGroupConfirmation"));
                  },
                  t,
                  navigate,
                });
              }}
              simplified
            />
          </IsLoading>
        </div>
        <EnduserStatsOverview />

        <ModalConfirmation
          id="deleteProjectGroupConfirmation"
          onClickSubmit={() => {
            dispatch(closeConfirmationModal("deleteProjectGroupConfirmation"));
            dispatch(
              deleteProjectGroup({
                id: clickedProjectGroupId,
                componentId: `project-group-${clickedProjectGroupId}`,
              }),
            );
          }}
          title={t("pages.projectGroup.deletionModal.title")}
          text={t("pages.projectGroup.deletionModal.body")}
          componentId={PROJECT_GROUP_LIST_COMPONENT_ID}
        />
      </div>
    </>
  );
};

export default EnduserDashboard;
