import { useSelector } from "react-redux";
import { IsLoading } from "src/common/components/AppState/IsLoading";
import { getNumberOfPaywalledAnonymousSessions } from "../state/subscriptionSlice";
import { useTranslation } from "react-i18next";

interface IAnonymousSessionCounterProps {
  numberOfPaywalledAnonymousSessions: number;
  className?: string;
}

export const ANONYMOUS_SESSION_COUNTER_COMPONENT = "anonymousSessionCounterComponent";

export const AnonymousSessionCounter: React.FC<IAnonymousSessionCounterProps> = ({
  className = "",
}: IAnonymousSessionCounterProps) => {
  const { t } = useTranslation();
  const numberOfPaywalledAnonymousSessions = useSelector(getNumberOfPaywalledAnonymousSessions);

  return (
    <IsLoading componentId={ANONYMOUS_SESSION_COUNTER_COMPONENT} showSuccess={false}>
      {numberOfPaywalledAnonymousSessions > 0 ? (
        <div className={`flex flex-col items-center justify-center w-1/2 ${className}`}>
          <p className="text-lg">
            {t("pages.profile.subscription.anonymousSessionWarningPart1")}&nbsp;
            <strong>{numberOfPaywalledAnonymousSessions}</strong>&nbsp;
            {t("pages.profile.subscription.anonymousSessionWarningPart2")}
          </p>
          <p className="text-sm italic">
            {t("pages.profile.subscription.anonymousSessionWarningSubtext")}
          </p>
        </div>
      ) : (
        <></>
      )}
    </IsLoading>
  );
};
