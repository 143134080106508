/* eslint-disable indent */

import { Field } from "../util/graphFieldsExtractor";
import { ControllerRenderProps, UseFormRegister } from "react-hook-form";
import { Input } from "../../../common/components/Input";
import { IMedia } from "../../../model/unityObject";
import { MediaSelector } from "./MediaSelector";
import { useTranslation } from "react-i18next";
import { menuItems } from "../../../common/components/Icon/ThreeDotsDropdownMenu";
import { ICustomRemoveButton } from "src/common/components/CustomXIcon/CustomXIcon";

export const DynamicField = ({
  field,
  maxLength = 254,
  register,
  disabled = false,
  className = undefined,
}: {
  maxLength?: number;
  field: Field;
  register: UseFormRegister<any>;
  disabled?: boolean;
  className?: string;
  // openMediaPicker: () => void;
  // setCurrentMediaField: (value: string) => void;
}) => {
  switch (field.type) {
    case "text":
      return (
        <Input
          maxLength={maxLength}
          registerFct={() => register(field.fieldName)}
          type="text"
          label={field.fieldName}
          defaultValue={field.currentValue as any}
          disabled={disabled}
          className={className}
        />
      );
  }
  return <></>;
};

export const DynamicMedia = ({
  fieldType,
  handleButtonClick,
  media,
  currentMedia,
  field,
  customMenuItems,
  disabled = false,
  CustomRemoveButton = undefined,
  className = undefined,
}: {
  field: ControllerRenderProps;
  fieldType: string;
  register: UseFormRegister<any>;
  handleButtonClick: (mediaId: number, item: any, index: number) => void;
  media: IMedia;
  currentMedia: number | undefined;
  customMenuItems?: menuItems;
  disabled?: boolean;
  stopPlayingMedia?: boolean;
  CustomRemoveButton?: ICustomRemoveButton;
  className?: string;
  // openMediaPicker: () => void;
  // setCurrentMediaField: (value: string) => void;
}) => {
  const { t } = useTranslation();
  const med = media?.id
    ? media
    : ({
        createdAt: "",
        id: -1,
        name: `${t("pages.gallery.noMedia")}`,
        label: "",
        organization: 3,
        s3_url: "https://s3.eu-west-3.amazonaws.com/storage.wixar.io/NOMEDIA.png",
        type: "image",
        updatedAt: "",
        filename: "NOMEDIA.png",
        tags: [],
        upload_status: "completed",
      } as IMedia);
  const currentMed = Number(currentMedia) > 0 ? currentMedia : -1;
  switch (fieldType) {
    case "media":
      return (
        <MediaSelector
          field={field}
          handleButtonClick={handleButtonClick}
          media={med}
          currentMedia={currentMed}
          customMenuItems={customMenuItems}
          disabled={disabled}
          CustomRemoveButton={CustomRemoveButton}
          className={className}
        />
      );
  }
  return <></>;
};
