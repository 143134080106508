import Icon from "../../common/components/Icon/Icon";
import { useNavigate } from "react-router-dom";
import { TrashIcon, SaveAsIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import {
  setCurrentProjectFromId,
  setEdited as setEditedProject,
} from "src/features/projects/state/projectsSlice";
import { useAppDispatch } from "../../common/state/hooks";

interface IDisplayActions {
  handleClick?: (e: any) => void;
  itemsForList: any;
  deleteOneFromList?: (id: number) => void;
  isProjectList?: boolean;
  openMediaDetail?: (id: number, item: any, index: number) => void;
  onDeleteMedia?: (id: number) => void;
  isDeleteDisabled?: boolean;
  deleteDisabledTooltip?: string;
  isModalEdit?: boolean;
  isMediaList?: boolean;
  setEditedProjectGroupId?: (id: number, projectGroup: any) => void;
}

const DisplayActions = ({
  itemsForList,
  deleteOneFromList = () => [],
  isProjectList,
  openMediaDetail = () => [],
  onDeleteMedia = () => [],
  isModalEdit,
  isMediaList,
  isDeleteDisabled = false,
  deleteDisabledTooltip = "",
  setEditedProjectGroupId = () => [],
}: IDisplayActions) => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const navigate = useNavigate();
  let buttonsDynamic = (
    <>
      <Tooltip title={t("tooltip.modifyCourse")}>
        <span>
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              setEditedProjectGroupId(itemsForList.id, itemsForList);
              navigate(`/project-groups/${itemsForList.id}/edit`);
            }}
          >
            <Icon icon={"PencilAltIcon"} className={"w-6 h-6 mr-2 stroke-green-500"} />
          </button>
        </span>
      </Tooltip>
      <Tooltip title={deleteDisabledTooltip ? deleteDisabledTooltip : t("tooltip.deleteCourse")}>
        <span>
          <button
            onClick={(e) => {
              e.stopPropagation();
              deleteOneFromList(itemsForList?.id);
            }}
            disabled={isDeleteDisabled}
            className={`${isDeleteDisabled ? "cursor-not-allowed" : "cursor-pointer"}`}
          >
            <TrashIcon
              className={`w-6 h-6 mr-3 ${isDeleteDisabled ? "stroke-gray-500" : "stroke-red-500"}`}
            />
          </button>
        </span>
      </Tooltip>
    </>
  );
  if (isProjectList) {
    const isEditDisabled = itemsForList?.status === "Published";
    buttonsDynamic = (
      <>
        <Tooltip
          title={
            isEditDisabled ? t("tooltip.cannotEditPublishedModule") : t("tooltip.modifyModule")
          }
        >
          <span>
            <button
              className={`mr-2 ${isEditDisabled ? "cursor-not-allowed" : "cursor-pointer"}`}
              onClick={(e) => {
                e.stopPropagation();
                dispatch(setCurrentProjectFromId(Number(itemsForList.id)));
                dispatch(setEditedProject());
                setEditedProjectGroupId(itemsForList.id, itemsForList);
                navigate(`../list/${itemsForList.id}/edit`);
              }}
              disabled={isEditDisabled}
            >
              <Icon
                icon={"PencilAltIcon"}
                className={`w-6 h-6 ${isEditDisabled ? "stroke-gray-500" : "stroke-green-500"}`}
              />
            </button>
          </span>
        </Tooltip>

        <Tooltip title={t("tooltip.faitTemplate")} placement="right-end">
          <button
            onClick={(e) => {
              e.stopPropagation();
              navigate(`../template/${itemsForList?.id}`);
            }}
          >
            <SaveAsIcon className="w-6 h-6 stroke-green-500 mr-2 mb-1" />
          </button>
        </Tooltip>

        <Tooltip title={deleteDisabledTooltip ? deleteDisabledTooltip : t("tooltip.deleteModule")}>
          <span>
            <button
              onClick={(e) => {
                e.stopPropagation();
                deleteOneFromList(itemsForList?.id);
              }}
              disabled={isDeleteDisabled}
              className={`${isDeleteDisabled ? "cursor-not-allowed" : "cursor-pointer"}`}
            >
              <TrashIcon
                className={`w-6 h-6 mr-3 ${
                  isDeleteDisabled ? "stroke-gray-500" : "stroke-red-500"
                }`}
              />
            </button>
          </span>
        </Tooltip>
      </>
    );
  }

  const ActionButtons = () => {
    if (isMediaList) {
      if (itemsForList.organization.id !== "3") {
        return (
          <div className="flex">
            <Tooltip title={t("tooltip.editMedia")}>
              <button
                className="mr-2"
                onClick={(e) => {
                  e.stopPropagation();
                  openMediaDetail(itemsForList.id, 0, 0);
                }}
              >
                <Icon icon={"PencilAltIcon"} className={" w-6 h-6 stroke-green-500"} />
              </button>
            </Tooltip>
            {isModalEdit && (
              <Tooltip title={t("tooltip.deleteMedia")}>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    onDeleteMedia(itemsForList);
                  }}
                >
                  <TrashIcon className="w-6 h-6 stroke-red-500 mr-3" />
                </button>
              </Tooltip>
            )}
          </div>
        );
      } else {
        return <></>;
      }
    } else {
      return <>{buttonsDynamic}</>;
    }
  };

  return (
    <div className="flex">
      <ActionButtons />
    </div>
  );
};

export default DisplayActions;
