/* eslint-disable indent */
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  combineReducers,
  PayloadAction,
  createSelector,
  createAction,
} from "@reduxjs/toolkit";
import axios from "axios";
import { Project } from "../util/dataTypes/Project";
import { FlatGraphResponse } from "../util/dataTypes/FlatGraphResponse";
import { Action } from "../util/dataTypes/Action";
import { Content } from "../util/dataTypes/Content";
import {
  ContentType,
  ContentTypeAsset,
  ContentTypeScene,
  ModelName,
  ValidContentType,
} from "../util/dataTypes/ContentType";
import { buildGraph } from "../util/graphBuilder";
import { normalizeContentTypeObject } from "../util/schemas";
import apiUrls from "../../../api";
import { IMedia } from "../../../model/unityObject";
import { RootState } from "../../../common/state/store";
import { IAppLoaderAction } from "../../../common/state/loaderHandleMiddleware";
import { ILanguage, IProjectLanguageData } from "src/model/model";

// export let throwError:any = null;
// export const setThrowError = (th:any) => throwError = th;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const throwError = (_error: any, _userMessage = "") => {
  //@todo
  // const a = getThrowErrorFuncEditProjectScenes(store.);
  /*const func: any = dirtyGetThrowErrorFuncEditProjectScenes();
  func(error);*/
};

export const loadGraph = createAsyncThunk("data/loadGraph", (projectId: number) => {
  try {
    return Promise.resolve(
      axios.get<FlatGraphResponse>(`${apiUrls.projects.graph.flat}/${projectId}?isForGraph=true`),
    );
  } catch (error) {
    throwError(error);
    return Promise.reject(error);
  }
});

export type UpdateContentTypeParams = {
  contentType: ContentTypeScene | ContentTypeAsset;
  updatedProjectLanguageData: { id: number } & any;
  changes: any;
  callback?: any;
  allMedias?: IMedia[];
  shouldDirtyRefreshOnCompletion?: boolean;
};

export const updateContentType = createAsyncThunk(
  "data/updateContentType",
  async ({
    contentType,
    updatedProjectLanguageData,
    changes,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    callback = () => {},
    shouldDirtyRefreshOnCompletion = false,
  }: UpdateContentTypeParams & IAppLoaderAction) => {
    try {
      // here we handle the fact that the 'name' displayed actually belongs to the content (thats also the case in VR)
      const contentId = contentType?.object?.content;
      if (contentId && Object.keys(changes).includes("name")) {
        const relevantObjectCurrentName = contentType?.object.name;
        const relevantIncomingName = changes["name"];
        if (relevantObjectCurrentName !== relevantIncomingName) {
          await axios
            .put(`${apiUrls.contents}/${contentId}`, { name: relevantIncomingName })
            .then((res) => res);
        }
      }
      const path = deduceEndpointFromContentType(contentType);
      const pldPayload = { ...updatedProjectLanguageData };
      delete pldPayload.id;
      const promiseToUpdatePld = () =>
        axios.put(`${apiUrls.projects.projectLanguageDatas}/${updatedProjectLanguageData.id}`, {
          data: pldPayload,
        });
      const promiseToUpdateContentType = () => axios.put(path, changes);
      const [, contentTypeResponse] = await Promise.all([
        // This is valid, peak TS, so the compiler stfu about unused variable
        promiseToUpdatePld(),
        promiseToUpdateContentType(),
      ]);
      callback();

      if (shouldDirtyRefreshOnCompletion) {
        const dirtyLoc = window.location.href;
        //eslint-disable-next-line
        window.location.href = dirtyLoc;
      }
      return normalizeContentTypeObject(
        //snowflake ? snowflake :
        contentTypeResponse.data,
      );
    } catch (error) {
      console.error("Error in updateCotentType : ", error);
      throwError(error);
      // Since this is a thunk, and we might not want to throw errors to the Redux middleware,
      // we can return a rejected promise instead to ensure all paths return a value.
      return Promise.reject(error);
    }
  },
);

const deduceEndpointFromContentType = (contentType: ContentTypeScene | ContentTypeAsset) => {
  const { modelName } = contentType;
  const { id } = contentType.object;
  return deduceEndpointFromModelName(modelName, id);
};

const deduceEndpointFromModelName = (modelName: ModelName, id?: number) => {
  // the endpoints are named in a systematic manner, and the rules can be inferred from simple examples
  // - by default, adding a final "s" to the name:
  //   "content-audio" ➡︎ /content-audios
  //   "content-clean" ➡︎ /content-cleans
  // - but beware: when the modelName ends with a number, we add a "-" inbetween
  //   "content-quizz-2" ➡︎ /content-quizz-2-s
  //   "content-button-1" ➡︎ /content-button-1-s
  // - also not that this rule (strangely ?) applies when the number is not at the end:
  //   "content-video-2d" ➡︎ /content-video-2-ds

  // step 1: handle the numbers, if any
  let path = modelName;
  const numberMatch = path.match(/(\d+)([^\d]*)$/);
  if (numberMatch) {
    // If there's a number at the end, add '-' before 's', adjusting for additional non-numeric characters if present
    path += numberMatch[2] ? `-${numberMatch[2]}` : "-";
  }
  // step 2: add the "s"
  if (path === "content-photo-catch") path += "e";
  // extra step to handle the fact that "/content-photo-catches" is the correct endpoint (not /content-photo-catchs)
  path += "s";
  // step 3: when given an id, we suffix
  if (id !== undefined) {
    path += `/${id}`;
  }
  // step 4: finally we prefix with the base URL
  return `${apiUrls.base}/api/${path}`;
};

export const resetGraphInfo = createAction("data/resetGraph");

//
// PROJECTS
//

const graphProjectSlice = createSlice({
  name: "graph/project",
  initialState: null as Project | null,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadGraph.fulfilled, (_state, action) => {
      return action?.payload?.data.project;
    });
    builder.addCase(resetGraphInfo, () => null);
  },
});

//
// ACTIONS
//

const actionsAdapter = createEntityAdapter<Action>();

const graphActionsSlice = createSlice({
  name: "graph/actions",
  initialState: actionsAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadGraph.fulfilled, (state, action) => {
      try {
        actionsAdapter.setAll(state, action?.payload?.data?.actions ?? {});
      } catch (error) {
        throwError(error);
      }
    });
    builder.addCase(resetGraphInfo, (state) => {
      actionsAdapter.removeAll(state);
    });
  },
});

export const { selectAll: selectAllGraphActions } = actionsAdapter.getSelectors(
  (state: RootState) => state.graphLegacy.actions,
);

//
// SOURCE-MEDIAS
//

const sourceMediasAdapter = createEntityAdapter<IMedia>();

const graphSourceMedias = createSlice({
  name: "graph/sourceMedias",
  initialState: sourceMediasAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadGraph.fulfilled, (state, action) => {
      try {
        sourceMediasAdapter.setAll(state, action?.payload?.data?.projectSourceMedias ?? {});
      } catch (error) {
        throwError(error);
      }
    });
    builder.addCase(resetGraphInfo, (state) => {
      sourceMediasAdapter.removeAll(state);
    });
  },
});

//
// CONTENTS
//
const contentsAdapter = createEntityAdapter<Content>();

const graphContentsSlice = createSlice({
  name: "graph/contents",
  initialState: contentsAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadGraph.fulfilled, (state, action) => {
      contentsAdapter.setAll(state, action?.payload?.data?.contents ?? {});
    });
    builder.addCase(resetGraphInfo, (state) => {
      contentsAdapter.removeAll(state);
    });
  },
});

export const { selectAll: selectAllGraphContents, selectEntities: selectGraphContentByIds } =
  contentsAdapter.getSelectors((state: RootState) => state.graphLegacy.contents);

//
// PROJECT LANGUAGE DATAS
//

export const fetchMapContentKeysWithPldKeys = createAsyncThunk(
  "data/getMapContentKeysWithPldKeys",
  async () => {
    const response = await axios.get(
      `${apiUrls.projects.projectLanguageDatas}/mapContentKeysWithPldKeys`,
    );
    return response.data;
  },
);

const projectLanguageDatasAdapter = createEntityAdapter<IProjectLanguageData>();

const initialState = projectLanguageDatasAdapter.getInitialState({
  currentLanguagePldsMappedByContent: {},
  mapContentKeysWithPldKeys: {},
  relevantPldAfterUpdate: {},
});

export const getMapContentKeysWithPldKeys = (state: RootState) =>
  state.graphLegacy.projectLanguageDatas.mapContentKeysWithPldKeys;

const graphProjectLanguageDatasSlice = createSlice({
  name: "graph/projectLanguageDatas",
  initialState,
  reducers: {
    resetProjectLanguageDatas: (state) => {
      projectLanguageDatasAdapter.removeAll(state);
      state.currentLanguagePldsMappedByContent = {};
    },
    updateRelevantPld: (state, action: PayloadAction<{ contentId: string; updatedPld: any }>) => {
      const { contentId, updatedPld } = action.payload;
      state.currentLanguagePldsMappedByContent[contentId] = {
        ...state.currentLanguagePldsMappedByContent[contentId],
        projectLanguageData: updatedPld,
      };
    },

    setRelevantPldAfterUpdate: (state, action: PayloadAction<IProjectLanguageData>) => {
      state.relevantPldAfterUpdate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadGraph.fulfilled, (state, action) => {
      projectLanguageDatasAdapter.setAll(state, action.payload?.data?.projectLanguageDatas);

      // Assuming project and contents are also loaded here
      const project = action.payload?.data.project;
      const contents = action.payload?.data.contents.reduce((acc, content) => {
        acc[content.id] = content;
        return acc;
      }, {});

      // Reset currentLanguagePldsMappedByContent before populating
      state.currentLanguagePldsMappedByContent = {};

      Object.values(state.entities).forEach((pld) => {
        const projectLanguageId =
          typeof project.language === "object" && project.language !== null
            ? (project.language as ILanguage).id
            : project.language;
        if (project && pld.language === projectLanguageId) {
          state.currentLanguagePldsMappedByContent[pld.content] = {
            languageId: projectLanguageId,
            content: contents[pld.content],
            projectLanguageData: pld,
          };
        }
      });
    });

    builder.addCase(fetchMapContentKeysWithPldKeys.fulfilled, (state, action) => {
      state.mapContentKeysWithPldKeys = action.payload;
    });

    builder.addCase(resetGraphInfo, (state) => {
      projectLanguageDatasAdapter.removeAll(state);
      state.currentLanguagePldsMappedByContent = {};
      // We want to persist the mapContentKeysWithPldKeys
      const preservedMapContentKeysWithPldKeys = state.mapContentKeysWithPldKeys;
      state.mapContentKeysWithPldKeys = preservedMapContentKeysWithPldKeys;
    });
  },
});

export const { resetProjectLanguageDatas, updateRelevantPld, setRelevantPldAfterUpdate } =
  graphProjectLanguageDatasSlice.actions;

//
//  CONTENT TYPES
//
export const makeContentTypeUniqueKey = (contentType: ContentType) => {
  try {
    const x = `${contentType.modelName}-${contentType.object.id}`;
    return x;
  } catch (error) {
    throwError(error);
    return null;
  }
};

const contentTypesAdapter = createEntityAdapter<ContentType>({
  selectId: makeContentTypeUniqueKey as any,
});

const graphContentTypesSlice = createSlice({
  name: "graph/contentTypes",
  initialState: contentTypesAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadGraph.fulfilled, (state, action) => {
      try {
        contentTypesAdapter.setAll(state, action?.payload?.data?.contentTypes ?? {});
        // here: create a new state.graphLegacy.projectLanguageData to store them.
      } catch (error) {
        throwError(error);
      }
    });
    builder.addCase(updateContentType.fulfilled, (state, action) => {
      try {
        // ⚠️ the endpoint returns the content type OBJECT !
        // we need to "rebuild" a content-type from the result and the modelName of the args
        const contentTypeObject =
          action?.payload?.entities.contentTypeObjects[action.payload.result];
        const { modelName } = action.meta.arg.contentType;
        const contentType = { modelName, object: contentTypeObject } as ValidContentType;

        // then we can update the local valu
        contentTypesAdapter.updateOne(state, {
          id: makeContentTypeUniqueKey(contentType) as any,
          changes: contentType,
        });
      } catch (error) {
        throwError(error);
      }
    });
    builder.addCase(updateContentType.rejected, (_state, action) => {
      try {
        return action.meta.arg.callback && typeof action.meta.arg.callback === "function"
          ? action.meta.arg.callback()
          : null;
      } catch (error) {
        throwError(error);
      }
    });
    builder.addCase(resetGraphInfo, (state) => {
      try {
        contentTypesAdapter.removeAll(state);
      } catch (error) {
        throwError(error);
      }
    });
  },
});

export const { selectAll: selectAllGraphContentTypes } = contentTypesAdapter.getSelectors(
  (state: RootState) => state.graphLegacy.contentTypes,
);

export const getNodeDetail = (state: RootState, nodeId: string) => {
  return selectAllGraphContentTypes(state).find((node) => node.object === nodeId);
};

//
// GLOBAL "DATA-LEVEL" STATE
//

const selectedContentSlice = createSlice({
  name: "graph/selectedContent",
  initialState: null as number | null,
  reducers: {
    /// defines a project as "the active project"
    setSelectedContent: (_state: any, action: PayloadAction<number | null>) => action.payload,
  },
  extraReducers: (builder) => {
    builder.addCase(resetGraphInfo, () => null);
  },
});

export const { setSelectedContent } = selectedContentSlice.actions;
export const selectSelectedContentId = (state: RootState) => state.graphLegacy.selectedContent;
export const projectContents = (state: RootState) => state.graphLegacy.contents;

export const selectSelectedContent = createSelector(
  [selectSelectedContentId, selectGraphContentByIds],
  (contentId, byId) => (contentId ? byId[contentId] : null),
);

export const selectSelectedContentType = createSelector(
  [selectSelectedContentId, selectAllGraphContentTypes],
  (contentId, contentTypes) => {
    return contentId
      ? contentTypes.find((contentType: any) => {
          return Number(contentType?.object?.content) === Number(contentId);
        })
      : null;
  },
);

const graphErrorMessagesSlice = createSlice({
  name: "graph/errorMessages",
  initialState: [] as string[],
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadGraph.fulfilled, (_state, action) => {
      return action?.payload?.data?.errorMessages || [];
    });
    builder.addCase(resetGraphInfo, () => []);
  },
});

export const selectErrorMessages = (state: RootState) => state.graphLegacy.errorMessages;

const loading = (state = false, action: any) => {
  if (loadGraph.pending.match(action) || updateContentType.pending.match(action)) {
    return false;
  } else if (loadGraph.rejected.match(action) || loadGraph.fulfilled.match(action)) {
    return false;
  } else if (
    updateContentType.rejected.match(action) ||
    updateContentType.fulfilled.match(action)
  ) {
    return false;
  } else if (resetGraphInfo.match(action)) {
    return false;
  }
  return state;
};

export const selectGraphLoading = (state: RootState) => state.graphLegacy.loading;

// Aurelien's error handling (see https://redux-toolkit.js.org/api/createAsyncThunk#type )
const error = (state: Error | null = null, action: any) => {
  if (loadGraph.pending.match(action) || loadGraph.fulfilled.match(action)) {
    return null;
  } else if (loadGraph.rejected.match(action)) {
    throwError(action.error);
    return action.error;
  } else if (updateContentType.pending.match(action) || updateContentType.fulfilled.match(action)) {
    return null;
  } else if (updateContentType.rejected.match(action)) {
    throwError(action.error);
    return action.error;
  } else if (resetGraphInfo.match(action)) {
    return null;
  }
  return state;
};

// FINAL DATA REDUCER

const graph = combineReducers({
  loading,
  error,
  selectedContent: selectedContentSlice.reducer,
  project: graphProjectSlice.reducer,
  actions: graphActionsSlice.reducer,
  contents: graphContentsSlice.reducer,
  contentTypes: graphContentTypesSlice.reducer,
  sourceMedias: graphSourceMedias.reducer,
  projectLanguageDatas: graphProjectLanguageDatasSlice.reducer,
  errorMessages: graphErrorMessagesSlice.reducer,
});

export type Graph = ReturnType<typeof graph>;

// SELECTORS

export const getGraphLayout = createSelector(
  [selectAllGraphContents, selectAllGraphContentTypes, selectAllGraphActions],
  buildGraph,
);

export const selectcurrentLanguagePldsMappedByContent = (state: RootState) =>
  state.graphLegacy.projectLanguageDatas.currentLanguagePldsMappedByContent;

export const getNodeById = (state: RootState, nodeId: string, modelName?: string) => {
  const allContentTypes = selectAllGraphContentTypes(state);
  return allContentTypes.find(
    (node) =>
      Number(node.object.id) === Number(nodeId) && (node.modelName === modelName || !modelName),
  );
};

export const getRelevantPldAfterUpdate = (state: RootState) =>
  state.graphLegacy.projectLanguageDatas.relevantPldAfterUpdate;

export const currentLanguagePldsWithMappedContents = createSelector(
  [
    (state: RootState) => state.graphLegacy.project,
    (state: RootState) => state.graphLegacy.contents.entities,
    (state: RootState) => state.graphLegacy.projectLanguageDatas.entities,
  ],
  (project, contentEntities, projectLanguageDatasEntities) => {
    if (!project || !project.language) return new Map();

    const map = new Map();

    Object.values(projectLanguageDatasEntities).forEach((pld: any) => {
      if (pld.language === project.language) {
        map.set(pld.content, {
          languageId: project.language,
          content: contentEntities[pld.content],
          projectLanguageData: pld,
        });
      }
    });

    return map;
  },
);

export const relevantPldForContent = (contentId) =>
  createSelector(
    (state: RootState) => state.graphLegacy.projectLanguageDatas.currentLanguagePldsMappedByContent,
    (pldsMappedByContent) => {
      const pld = pldsMappedByContent[contentId];
      if (!pld) {
        console.log("OOPSIE WOOPSIE XD contentId  ", contentId);

        console.error(
          `No project language data found for content ID ${contentId}; pldsMappedByContent:`,
          pldsMappedByContent,
        );
        return undefined;
      }
      return pld;
    },
  );

export const relevantPldsForContent = (contentId) =>
  createSelector(
    (state: RootState) => state.graphLegacy.projectLanguageDatas.entities,
    (pldEntities) => {
      const plds = Object.values(pldEntities).filter(
        (pld) => Number(pld.content) === Number(contentId),
      );

      if (plds.length === 0) {
        console.log("OOPSIE WOOPSIE XD contentId  ", contentId);

        console.error(`No project language data found for content ID ${contentId}`);
        return undefined;
      }
      return plds;
    },
  );

export default graph;
