import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ZendeskAPI } from "react-zendesk";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { SidebarItemSmall, SidebarItem } from "./SidebarItem";
import { IconName } from "../Icon/Icon";
import { logoutAuth } from "../../../features/auth/state/authSlice";
import { setSelectedProjectId } from "../../../features/stats/state/statsSlice";
import { currentUserSelector } from "../../state/selectors/authSelector";
import { RootState } from "../../state/store";
import { getIsAdminOfOrganization } from "src/features/profile/state/profileSlice";
import { UsersManagementItem } from "./UsersManagementItems";
import { Tooltip } from "@mui/material";
import ThemeIcon from "../Icon/ThemeIcon";

type SideBarLinkProps = {
  title: string;
  iconName?: IconName;
  routePath: string;
  customIcon?: any;
  classNameOnboarding?: string;
};

const Sidebar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname.split("/");

  const { user, isAdmin } = useSelector((state: RootState) => {
    return {
      user: currentUserSelector(state),
      isAdmin: getIsAdminOfOrganization(state),
    };
  });

  const [isChatOpen, setIsChatOpen] = useState(false);

  const sidebarLinks: SideBarLinkProps[] = [
    { title: t("sidebar.dashboard"), iconName: "HomeIcon", routePath: "/" },
    { title: t("sidebar.projectGroups"), iconName: "FolderIcon", routePath: "/project-groups" },
  ];

  if (user?.role.name === "Creator") {
    sidebarLinks.push({
      title: t("sidebar.media"),
      iconName: "PhotographIcon",
      routePath: "/medias",
      classNameOnboarding: "introOnboarding-step-3",
    });
    sidebarLinks.push({
      title: t("sidebar.themes"),
      customIcon: <ThemeIcon />,
      routePath: "/themes",
    });
    sidebarLinks.push({
      title: t("sidebar.statistics"),
      iconName: "ChartBarIcon",
      routePath: "/statistics",
    });
  }

  const isBetaVersion = Boolean(Number(process.env.REACT_APP_IS_BETA_VERSION ?? 0));

  const chatClickHandler = () => {
    if (isChatOpen) {
      ZendeskAPI("webWidget", "hide");
      setIsChatOpen(false);
    } else {
      ZendeskAPI("webWidget", "show");
      ZendeskAPI("webWidget", "open");
      setIsChatOpen(true);
    }
  };

  if (isChatOpen) {
    ZendeskAPI("webWidget:on", "userEvent", function (userEvent: any) {
      if (userEvent.action === "Web Widget Minimised") {
        // when mimizing, hide the widget instead
        chatClickHandler();
      }
    });
  }

  const logout = () => {
    setIsChatOpen(false);
    dispatch(logoutAuth());
  };

  const SidebarItems = ({ isAdmin = false }: { isAdmin: boolean }) => {
    const items = sidebarLinks.map((link, i) => {
      return (
        <SidebarItem
          classNameOnboarding={link.classNameOnboarding}
          routeTo={link.routePath}
          key={i}
          title={link.title}
          icon={link?.iconName}
          customIcon={link?.customIcon}
          isSelected={path[1] === link.routePath.replace("/", "")}
          onClick={() => {
            // dirty reset of statistic view.
            // todo: refactor so this variable lives in one useState of wrapper component ~
            dispatch(setSelectedProjectId(0));
          }}
        />
      );
    });
    if (isAdmin) {
      items.splice(
        2,
        0,
        <UsersManagementItem path={path} onClick={() => dispatch(setSelectedProjectId(0))} />,
      );
    }
    return <>{items}</>;
  };

  return (
    <div
      id="sidebar"
      className="flex flex-col justify-between xxl:justify-start w-32 px-2 pt-3 pb-6 bg-blue-500 z-50"
    >
      <div>
        <img
          className="object-scale-down"
          src="https://s3.eu-west-3.amazonaws.com/storage.wixar.io/Wixar_Editor_Logo_Capital_500x500.png"
          alt="wixar-logo"
        />
        {isBetaVersion && (
          <span className="text-white absolute text-m left-[60px] top-[80px]">Beta</span>
        )}
        <SidebarItems isAdmin={isAdmin} />
      </div>
      <div className="flex flex-col justify-between">
        <div className="divide-y">
          <div className="flex flex-col items-center introOnboarding-step-4"></div>

          <div className="py-4 xxl:mt-1">
            <SidebarItemSmall
              icon="UserIcon"
              title={t("sidebar.profile")}
              routeTo={"/profile/me"}
              isSelected={path[1] === "profile"}
            />
            <SidebarItemSmall icon="ShareIcon" title={"Ressources"} routeTo={"/download-client"} />
            {user?.role.name === "Creator" && (
              <>
                <Tooltip
                  title={`${t("pages.help.exitDomainWarningTooltip")}`}
                  placement="right-end"
                >
                  <div className="introOnboarding-step-5">
                    <SidebarItemSmall
                      icon="InformationCircleIcon"
                      title={t("sidebar.help")}
                      routeTo={""}
                      onClick={() => open(t("pages.help.url"))}
                    />
                  </div>
                </Tooltip>
                <div className="introOnboarding-step-7">
                  <SidebarItemSmall
                    icon="ChatIcon"
                    title={t("sidebar.chat")}
                    fillIcon={isChatOpen ? "#fffc9b" : undefined}
                    routeTo={""}
                    onClick={() => {
                      chatClickHandler();
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <SidebarItemSmall
          icon="LogoutIcon"
          title={t("sidebar.logout")}
          onClick={logout}
          routeTo={"/login"}
        />
      </div>
    </div>
  );
};

export default Sidebar;
