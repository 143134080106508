import CollaboratorIcon2 from "../Icon/CollaboratorIcon2";
import ParticipantsIcons from "../Icon/ParticipantsIcon";
import { SidebarItem } from "./SidebarItem";
import { useTranslation } from "react-i18next";

interface IUsersManagementItemProps {
  path: string[];
  onClick(): any;
}

export const UsersManagementItem = ({ path, onClick }: IUsersManagementItemProps) => {
  const { t } = useTranslation();
  return (
    <>
      <SidebarItem
        routeTo={"/collaborators"}
        key={"collaborators"}
        title={t("general.collaborators")}
        customIcon={<CollaboratorIcon2 fill={"white"} height={24} width={24} />}
        isSelected={path[1] === "collaborators"}
        onClick={() => {
          // dirty reset of statistic view.
          // todo: refactor so this variable lives in one useState of wrapper component ~
          onClick();
        }}
      />
      <SidebarItem
        routeTo={"/participants"}
        key={"participants"}
        title={t("general.participants")}
        customIcon={<ParticipantsIcons fill="white" height={24} width={24} />}
        isSelected={path[1] === "participants"}
        onClick={() => {
          // dirty reset of statistic view.
          // todo: refactor so this variable lives in one useState of wrapper component ~
          onClick();
        }}
      />
    </>
  );
};
