import { Tooltip } from "../Tooltip/Tooltip";

export interface SelectableCard {
  component: any;
  id: string;
  disabled?: boolean;
  selected?: boolean;
}

export interface ICardsSelectorProps {
  parentClassName?: string;
  cardClassName?: string;
  disabledTooltipText: string;
  cards: SelectableCard[];
  cardClickCb: any;
}

const CardsSelector = ({
  parentClassName = "flex items-center justify-center",
  cardClassName = "p-4 m-1 w-28 h-full border-2 cursor-pointer select-none",
  cards = [],
  cardClickCb = () => null,
  disabledTooltipText = "",
}: ICardsSelectorProps) => {
  const computeStyle = (card: SelectableCard) => {
    if (card.disabled) {
      return "cursor-not-allowed opacity-40";
    } else if (card.selected) {
      return "border-green-500";
    } else {
      return "border-gray-500";
    }
  };

  return (
    <div className={parentClassName}>
      {cards.map((card: SelectableCard, i: number) => {
        return (
          <Tooltip
            className={`${card.disabled ? "cursor-not-allowed" : ""}`}
            classNameChildren="left-0"
            forceSingleLine={false}
            key={`${i}=_${i}`}
            message={card.disabled ? disabledTooltipText : ""}
          >
            <div
              className={`${cardClassName} ${computeStyle(card)} w-full`}
              onClick={() => cardClickCb(card.id)}
            >
              {card.component}
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
};

export default CardsSelector;
