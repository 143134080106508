import { createMD5 } from "hash-wasm";

interface IComputeUploadPanelParams {
  file: File;
  from: "profile" | "organization" | "graph";
}

export interface cUPP {
  shouldPreview: boolean;
  needAskMedia360Tags: boolean;
  media360Tags: string[];
  forcedTag: ["P_R_I_V_A_T_E"] | null;
  sourceMediaType: "video" | "image" | "audio" | "pdf" | "model3d";
}

/** Return relevant infos when uploading something from somewhere
@param File - Upload candidate that was selected by user
@param from - The context in which the upload is taking place
*/
export const computeUploadPanelParams = ({ file, from }: IComputeUploadPanelParams): cUPP => {
  try {
    const output: cUPP = {
      shouldPreview: true,
      needAskMedia360Tags: false,
      media360Tags: ["other"],
      forcedTag: null,
      sourceMediaType: "video",
    };

    const extension = file.name.split(".")[file.name.split(".").length - 1];
    const type = deduceMediaType(extension);
    output.sourceMediaType = type;

    // We don't preview pdf & model3d for the time being
    if (type === "model3d" || type === "pdf") {
      output.shouldPreview = false;
    }

    // Videos can have several 'source_media.media_360_tag' (that must be user defined)
    if (from === "graph") {
      if (type === "video") {
        output.media360Tags = ["media360", "media2d", "video180stereo"];
      }
      if (type === "image") {
        output.media360Tags = ["media360", "media2d"];
      }
    }
    // Images uploaded from profile & organization view are 2d by definition
    if (from !== "graph") {
      if (type === "image") {
        output.media360Tags = ["media2d"];
      }
    }
    if (output.media360Tags.length > 1) {
      output.needAskMedia360Tags = true;
    }

    // We enforce a dedicated "P_R_I_V_A_T_E" tag onto avatars & organization logos
    // Wacky syntax should limit the risk of conflict with an user defined tag
    if (from === "profile" || from === "organization") {
      output.forcedTag = ["P_R_I_V_A_T_E"];
    }

    return output;
  } catch (error) {
    console.error("Error in computeUploadPanelParams : ", error);
    throw error;
  }
};

export const deduceMediaType = (extension: string) => {
  const toLower = extension.toLocaleLowerCase();
  switch (toLower) {
    case "mp4":
    case "mov":
      return "video";
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
      return "image";
    case "mp3":
    case "wav":
    case "ogg":
    case "oga":
      return "audio";
    case "pdf":
      return "pdf";
    case "zip":
    case "fbx":
      return "model3d";
    default:
      throw Error("Unknown file type");
  }
};

interface MD5AndSize {
  MD5: string;
  size: number;
}

/**
 * Async computing of md5 hash for a given file
 * @param file
 * @returns checksum as string
 */
export const fromFileReturnMD5AndSize = async (file: File) => {
  return new Promise<MD5AndSize>((resolve, reject) => {
    const size = file.size;
    try {
      const reader = new FileReader();
      reader.onload = function (event) {
        if (event.target) {
          const binary = event.target?.result as ArrayBuffer;
          if (binary) {
            createMD5().then((md5Inst) => {
              md5Inst.init();
              let index = 0;
              const chunkSize = 100e7;
              while (index < binary.byteLength) {
                let endIndex = index + chunkSize;
                if (endIndex > binary.byteLength) endIndex = binary.byteLength;
                const tab = new Uint8Array(binary.slice(index, endIndex));
                md5Inst.update(tab);

                index = endIndex;
              }
              const md5 = md5Inst.digest();
              resolve({
                MD5: md5,
                size,
              });
            });
          }
        } else reject(Error("event.target is undefined :<"));
      };
      reader.readAsArrayBuffer(file);
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * Async computing of md5 hash for a file located at a given URL
 * @param url The URL of the .jpg file
 * @returns A promise resolving to an object containing the MD5 checksum and size of the file
 */
export const fromUrlReturnMD5AndSize = (url: string): Promise<MD5AndSize> => {
  return new Promise<MD5AndSize>((resolve, reject) => {
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          reject(new Error(`Network response was not ok: ${response.status}`));
          return;
        }
        response
          .blob()
          .then((blob) => {
            const size = blob.size;
            const reader = new FileReader();
            reader.onload = () => {
              const binary = reader.result as ArrayBuffer;
              createMD5().then((md5Inst) => {
                md5Inst.init();
                let index = 0;
                const chunkSize = 100e7;
                while (index < binary.byteLength) {
                  let endIndex = index + chunkSize;
                  if (endIndex > binary.byteLength) endIndex = binary.byteLength;
                  const tab = new Uint8Array(binary.slice(index, endIndex));
                  md5Inst.update(tab);
                  index = endIndex;
                }
                const md5 = md5Inst.digest();
                resolve({
                  MD5: md5,
                  size,
                });
              });
            };
            reader.onerror = () => {
              reject(new Error(`Error reading file: ${reader.error?.message}`));
            };
            reader.readAsArrayBuffer(blob);
          })
          .catch((error) => {
            reject(new Error(`Error processing blob: ${error.message}`));
          });
      })
      .catch((error) => {
        reject(new Error(`Error fetching the file: ${error.message}`));
      });
  });
};

export const fromMediaTypeInferInputAcceptParam = (mediaType?: string) => {
  const AUDIO_TYPES = "audio/wav,audio/ogg,audio/oga,audio/mpeg,audio/x-wav";
  const VIDEO_TYPES = "video/mp4,video/quicktime";
  const IMAGE_TYPES = "image/jpeg,image/png,image/jpg,image/gif";
  const PDF_TYPES = "application/pdf,application/octet-stream";
  const MODEL3D_TYPES = "application/zip,application/x-zip-compressed";
  const IMAGE_TYPES_CONTENT_SCENES = "image/jpeg,image/png,image/jpg";

  switch (mediaType) {
    case "audio":
      return AUDIO_TYPES;
    case "video":
      return VIDEO_TYPES;
    case "image":
      return IMAGE_TYPES;
    case "pdf":
      return PDF_TYPES;
    case "model3d":
      return MODEL3D_TYPES;
    case "content-scene":
      return `${VIDEO_TYPES},${IMAGE_TYPES_CONTENT_SCENES}`;
    case "all":
      return `${AUDIO_TYPES},${VIDEO_TYPES},${IMAGE_TYPES},${PDF_TYPES},${MODEL3D_TYPES}`;
    default:
      return "*";
  }
};
