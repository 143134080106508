import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { RootState } from "../../common/state/store";
import {
  deleteTheme,
  getEditedProjectGroup,
  getNewProjectGroup,
  getSelectedProjectGroup,
  setNewProjectGroup,
  updateProjectGroup,
} from "../../features/projectGroups/state/projectGroupsSlice";
import { FooterButtons } from "../../common/components/FooterButtons";
import { deleteThemeInStore, fetchThemes, getThemes } from "src/features/themes/state/themeSlice";
import { useAppDispatch } from "src/common/state/hooks";
import { IsLoading } from "src/common/components/AppState/IsLoading";
import { useEffect, useState } from "react";
import { IThemeInfo } from "src/model/model";
import ModalThemeEditor from "src/features/themes/components/ModalThemeEditor";
import {
  closeConfirmationModal,
  closeStandardModal,
  openConfirmationModal,
  openStandardModal,
} from "src/common/state/slice/modal/modalSlice";
import ThemeRadioItem from "src/features/themes/components/ThemeRadioItem";
import ModalConfirmation from "src/common/components/Modal/ModalConfirmation";
import { setAlert } from "src/features/alert/state/alertsSlice";
import ThemesView from "../Themes/ThemesView";

interface ICreateProjectGroupTheme {
  incrementStep?(): void;
  decrementStep(): void;
  editMode?: boolean;
}

const CHOOSE_THEME_COMPONENT = "chooseThemeComponent";

const CreateProjectGroupTheme = ({
  incrementStep,
  decrementStep,
  editMode = false,
}: ICreateProjectGroupTheme) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const themes = useSelector(getThemes);
  const [publicThemes, setPublicThemes] = useState<IThemeInfo[]>([]);
  const [privatesThemes, setPrivatesThemes] = useState<IThemeInfo[]>([]);
  const [themeToDeleteId, setThemeToDeleteId] = useState<number>(-1);
  const [selectedRadioButton, setSelectedRadioButton] = useState<number | null>(null);

  const [createthemeWithThisBase, setCreatethemeWithThisBase] = useState<boolean>(false);

  const { handleSubmit } = useForm<any>();

  const { newProjectGroup, selectedProjectGroup, editedProjectGroup } = useSelector(
    (state: RootState) => {
      return {
        newProjectGroup: getNewProjectGroup(state),
        selectedProjectGroup: getSelectedProjectGroup(state),
        editedProjectGroup: getEditedProjectGroup(state),
      };
    },
  );

  const [selectedThemeId, setSelectedThemeId] = useState<number | null>(
    editMode ? Number(selectedProjectGroup.theme_info) : Number(newProjectGroup.themeId),
  );

  useEffect(() => {
    dispatch(fetchThemes({ componentId: CHOOSE_THEME_COMPONENT }));
  }, []);

  useEffect(() => {
    const pubThemes: IThemeInfo[] = [];
    const priThemes: IThemeInfo[] = [];
    if (themes) {
      themes?.forEach((elt) => {
        if (Number(elt.organization?.id) === 3) {
          pubThemes.push(elt);
        } else {
          priThemes.push(elt);
        }
      });

      setPublicThemes(pubThemes.sort((a, b) => a.id - b.id));
      setPrivatesThemes(priThemes);
    }
  }, [themes]);

  const onSubmit = () => {
    if (editMode) {
      dispatch(
        updateProjectGroup({
          id: selectedProjectGroup.id,
          payload: {
            name: editedProjectGroup.name,
            source_thumbnail: editedProjectGroup.source_thumbnail,
            theme_info: selectedRadioButton,
          },
          componentId: CHOOSE_THEME_COMPONENT,
        }),
      );
      navigate(`/project-groups/${selectedProjectGroup.id}/list`);
    } else {
      dispatch(setNewProjectGroup({ ...newProjectGroup, themeId: Number(selectedRadioButton) }));
      if (incrementStep) {
        incrementStep();
      }
    }
  };

  const onThemeCardClick = (themeInfo: IThemeInfo) => {
    setCreatethemeWithThisBase(false);
    setSelectedRadioButton(themeInfo.id);
    setSelectedThemeId(themeInfo.id);
  };

  const onNewThemeClick = () => {
    setCreatethemeWithThisBase(false);
    setSelectedRadioButton(selectedThemeId);
    setSelectedThemeId(0);
    dispatch(openStandardModal("modalThemeEditor"));
  };

  const onConfirmModalThemeEditor = (themeId: number) => {
    setSelectedRadioButton(themeId);
    setSelectedThemeId(themeId);
    dispatch(closeStandardModal("modalThemeEditor"));
    dispatch(fetchThemes({ componentId: CHOOSE_THEME_COMPONENT }));
  };

  const onConfirmDeleteTheme = () => {
    dispatch(closeConfirmationModal("deleteThemeModal"));

    dispatch(deleteTheme({ themeId: themeToDeleteId, componentId: CHOOSE_THEME_COMPONENT }))
      .then(() => {
        dispatch(deleteThemeInStore({ themeId: themeToDeleteId }));
        setThemeToDeleteId(-1);
        dispatch(setAlert({ msg: t("general.successDeleteTheme"), type: "success" }));
      })
      .catch(() => {
        dispatch(setAlert({ msg: t("general.errorDeleteTheme"), type: "error" }));
      });
  };

  const handleOnThemeMenuClick = (item: any, _index: number) => {
    if (item.itemId === "createthemeWithThisBase") {
      setCreatethemeWithThisBase(true);
      setSelectedRadioButton(selectedThemeId);
      setSelectedThemeId(item.themeId);
      dispatch(openStandardModal("modalThemeEditor"));
    }
    if (item.itemId === "editTheme") {
      setCreatethemeWithThisBase(false);
      setSelectedRadioButton(selectedThemeId);
      setSelectedThemeId(item.themeId);
      dispatch(openStandardModal("modalThemeEditor"));
    }
    if (item.itemId === "deleteTheme") {
      setCreatethemeWithThisBase(false);
      setSelectedRadioButton(selectedThemeId);
      setThemeToDeleteId(item.themeId);
      dispatch(openConfirmationModal("deleteThemeModal"));
    }
  };

  return (
    <div className="w-full overflow-auto h-full personalize-scroll-visible">
      <IsLoading componentId={CHOOSE_THEME_COMPONENT} showSuccess={false}>
        <form
          className="flex flex-col grow w-full justify-between h-full"
          onSubmit={handleSubmit(onSubmit)}
        >
          <h1 className="text-2xl mt-4 px-6 font-medium">{t("general.selectTheme")}</h1>
          <ThemesView
            publicThemes={publicThemes}
            privatesThemes={privatesThemes}
            onNewThemeClick={onNewThemeClick}
            onThemeClick={onThemeCardClick}
            onThemeMenuClick={handleOnThemeMenuClick}
            componentType="radio"
            selectedThemeId={selectedThemeId}
            setSelectedThemeId={setSelectedThemeId}
            selectedRadioButton={selectedRadioButton}
            setSelectedRadioButton={setSelectedRadioButton}
            ThemeItemComponent={ThemeRadioItem}
          />
          <FooterButtons
            cancelText={t("general.cancel")}
            cancelCb={() =>
              navigate(`/project-groups${editMode ? `/${selectedProjectGroup.id}/list` : ""}`)
            }
            backText={t("general.back")}
            backCb={decrementStep}
            nextText={editMode ? t("general.save") : t("general.next")}
            nextDisabled={!selectedRadioButton}
          />
        </form>
        <ModalThemeEditor
          title={
            createthemeWithThisBase
              ? t("pages.themeEditor.createthemeWithThisBase")
              : selectedThemeId === 0
              ? t("pages.themeEditor.create")
              : t("pages.themeEditor.edit")
          }
          themeId={selectedThemeId}
          createthemeWithThisBase={createthemeWithThisBase}
          onConfirm={() => onConfirmModalThemeEditor(selectedThemeId)}
          onCancel={() => {
            setSelectedThemeId(selectedRadioButton);
            dispatch(closeStandardModal("modalThemeEditor"));
          }}
        />
        <ModalConfirmation
          title={t("general.confirmTitleDeleteTheme")}
          onClickSubmit={onConfirmDeleteTheme}
          id="deleteThemeModal"
        >
          {t("general.confirmBodyDeleteTheme")}
        </ModalConfirmation>
      </IsLoading>
    </div>
  );
};

export default CreateProjectGroupTheme;
