import { Menu, Transition } from "@headlessui/react";
import Icon, { IconName } from "./Icon";
import { Tooltip } from "@mui/material";

export interface menuItem {
  itemId?: string;
  menuItemText: string;
  icon: IconName;
  disabled?: boolean;
  disabledTooltipText?: string;
  isEndUser?: boolean;
  strokeColor?: string;
  className?: string;
}

export type menuItems = Array<menuItem>;

const ThreeDotsDropdownMenu = ({
  handleClick,
  menuItems,
  isEndUser,
  strokeColor = "",
  className = "h-6 w-6",
  setEditedProjectId = () => {
    [];
  },
  projectId,
  currentProjectGroupElement,
  setEditedProjectGroupId = () => {
    [];
  },
}: {
  handleClick: (item: any, index: number) => void;
  menuItems: menuItems;
  isEndUser?: boolean;
  strokeColor?: string;
  className?: string;
  projectId?: number;
  setEditedProjectId?: (id: any) => void;
  currentProjectGroupElement?: any;
  setEditedProjectGroupId?: (id: any, projectGroup: any) => void;
}) => {
  return (
    <Menu
      as="div"
      className={`${isEndUser ? "mr-1 absolute right-1 top-2" : "relative"} z-10 h-6 w-6`}
    >
      {({ open }: { open: boolean }) => (
        <>
          <Menu.Button
            onClick={() => {
              if (projectId) {
                setEditedProjectId(projectId);
              }
              if (currentProjectGroupElement) {
                setEditedProjectGroupId(currentProjectGroupElement.id, currentProjectGroupElement);
              }
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              strokeWidth="0.5"
              stroke={strokeColor}
              className={className}
              viewBox="0 0 45 40"
              width={"50px"}
            >
              <style>
                {`
      svg:hover .circle {
        fill: #009A6D;
      }
    `}
              </style>
              <circle cx="6" cy="15" r="5" fill="#00C889" className="circle"></circle>
              <circle cx="21" cy="15" r="5" fill="#00C889" className="circle"></circle>
              <circle cx="36" cy="15" r="5" fill="#00C889" className="circle"></circle>
            </svg>
          </Menu.Button>

          <Transition
            show={open}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            className={"z-50 overflow-visible"}
          >
            <Menu.Items className="z-50 overflow-visible absolute -top-3 right-0 w-max origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none">
              <div className="py-1">
                {menuItems.length > 0 &&
                  menuItems.map((menuItem, i) => {
                    const isDeleteButton = menuItem.icon === "TrashIcon";
                    const disabled = menuItem?.disabled;
                    const disabledTooltipText =
                      disabled && Object.keys(menuItem).includes("disabledTooltipText")
                        ? menuItem.disabledTooltipText
                        : "";
                    const menuItemTextToolTip = menuItem.disabledTooltipText
                      ? menuItem.disabledTooltipText
                      : "";
                    const tooltipForEnableButton = Object.keys(menuItem).includes(
                      "disabledTooltipText",
                    )
                      ? menuItemTextToolTip
                      : "";

                    return (
                      <Menu.Item key={i}>
                        <Tooltip
                          title={
                            disabled ? (disabledTooltipText as string) : tooltipForEnableButton
                          }
                          placement="right-end"
                        >
                          <button
                            type="button" // for posibles sumbit forms send like in the case of templates delete
                            disabled={disabled}
                            onClick={() => {
                              handleClick(menuItem, i);
                            }}
                            className={`${
                              isDeleteButton && !disabled ? "text-red-900" : "text-gray-900"
                            }
                              ${disabled ? "cursor-not-allowed text-gray-400" : ""}
                              group flex w-full items-center rounded-md px-3 py-1 text-sm`}
                          >
                            <Icon
                              icon={menuItem.icon}
                              className={`mr-2 w-5 h-5 ${
                                disabled
                                  ? "stroke-gray-500"
                                  : isDeleteButton
                                  ? "stroke-red-500"
                                  : "stroke-green-500"
                              }`}
                            />
                            {menuItem.menuItemText}
                          </button>
                        </Tooltip>
                      </Menu.Item>
                    );
                  })}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default ThreeDotsDropdownMenu;
