/* eslint-disable react/prop-types */
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import { setCurrentProjectFromId } from "src/features/projects/state/projectsSlice";
import {
  fetchAllCurrentElements,
  setSelectedProjectGroupFromId,
} from "src/features/projectGroups/state/projectGroupsSlice";
import { useAppDispatch } from "src/common/state/hooks";
import { PROJECT_GROUP_LIST_COMPONENT_ID } from "src/features/projectGroups/components/ProjectGroupList";
import { forwardRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/outline";
import {
  sortByAlpha,
  sortByDate,
  sortBySize,
  sortByAlphabeticalOrder,
  sortByStatus,
  sortByUsers,
  sortByExtenxion,
} from "src/features/gallery/state/filterUtil";
import { useTranslation } from "react-i18next";
import { getBuilds } from "src/features/builds/state/buildsSlice";
import { SearchAutocomplete } from "../SearchAutocomplete/SearchAutocomplete";

// eslint-disable-next-line react/display-name
const TableComp = forwardRef<HTMLDivElement, any>(
  (
    {
      maxTableHeightPx = 0,
      isAllCollaborators = false,
      isCollaboratorAndParticipant = false,
      listTofilter = [],
      rows = [],
      colHeaders = null,
      className,
      cellClassName = "",
      emptyPlaceholder = null,
      isMediaList = false,
      isProjectList = false,
      style = {},
      checkAll = <></>,
      isUserList = false,
      selectFromListInTable = false,
      isProjectGroup = false,
      setListOfItemsFilter = () => {
        [];
      },
      createOrUpdateUserAsAdminPermissionsList = false,
      isStats = false,
      filterActivated = true,
      isCursorDesativated = false,
      showSearchBar = false,
    }: any,
    ref,
  ) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [directionArrow, setDirectionArrow] = useState("desc");
    const [searchResults, setSearchResults] = useState(rows);
    const [searchString, setSearchString] = useState("");

    const lastColumnHeaderNumber = colHeaders.length - 1;

    useEffect(() => {
      if (searchString.length === 0) {
        setSearchResults(rows);
      } else {
        const updatedSearchResults = searchResults.map((searchResult) => {
          const match = rows.find((row) => {
            return row.checkbox.props.id === searchResult.checkbox.props.id;
          });
          if (match) {
            return {
              ...match,
              checkbox: {
                ...match.checkbox,
                props: {
                  ...match.checkbox.props,
                  checked: match.checkbox.props.checked,
                },
              },
            };
          } else {
            return searchResult;
          }
        });
        setSearchResults(updatedSearchResults);
      }
    }, [rows]);

    const findIdInListToFilter = listTofilter.map((item: any) => item.id);
    const builds = useSelector(getBuilds) as any;
    const filterbuildById = Array.isArray(builds)
      ? builds.filter((build: any) => {
          return build?.project_group && findIdInListToFilter.includes(build?.project_group.id);
        })
      : [];
    const getIdAndStatusBuild = filterbuildById.map((item: any) => {
      return {
        id: item.project_group.id,
        status: item.status,
      };
    });

    const [actualColumActive, setSetActualColumActive] = useState("");
    const [currentActiveArrowFilterList, setActiveArrowFilterList] = useState("");
    const { t } = useTranslation();
    const keys = searchResults.length > 0 ? Object.keys(searchResults[0]) : [];

    const dynamicCellSize = (index: number) => {
      if (index === 0) {
        if (isProjectList) {
          return "65%";
        } else {
          return "5%";
        }
      } else if (index === 1 && !isProjectList) {
        return "22%";
      } else {
        return "";
      }
    };

    const getSortIcon = (iconHeaderName: string) => {
      const isActivated = currentActiveArrowFilterList === iconHeaderName;
      const activeColor = isActivated ? "stroke-green-500 " : "";
      const activeArrow = isActivated ? 5 : 1;
      return isActivated ? (
        directionArrow === "asc" ? (
          <ChevronUpIcon strokeWidth={activeArrow} className={`ml-2 mt-1 w-4 h-4 ${activeColor}`} />
        ) : (
          <ChevronDownIcon
            strokeWidth={activeArrow}
            className={`ml-2 mt-1 w-4 h-4 ${activeColor}`}
          />
        )
      ) : (
        <ChevronDownIcon strokeWidth={activeArrow} className={`ml-2 mt-1 w-4 h-4 ${activeColor}`} />
      );
    };

    const directionArrowState = directionArrow === "asc" ? "desc" : "asc";
    //FILTER MEDIA
    const filterListMedia = (typeFilter: any) => {
      if (typeFilter === t("general.fileName")) {
        setActiveArrowFilterList(t("general.fileName"));

        setListOfItemsFilter(sortByAlpha(listTofilter, directionArrowState));
      }
      if (typeFilter === t("general.addDate")) {
        setActiveArrowFilterList(t("general.addDate"));
        setListOfItemsFilter(sortByDate(listTofilter, directionArrowState));
      }
      if (typeFilter === t("general.weight")) {
        setActiveArrowFilterList(t("general.weight"));
        setListOfItemsFilter(sortBySize(listTofilter, directionArrowState));
      }
      if (typeFilter === "Format" || typeFilter === "Type") {
        setActiveArrowFilterList(typeFilter);

        setListOfItemsFilter(
          sortByAlphabeticalOrder(listTofilter, typeFilter, directionArrowState, true),
        );
      }
      if (typeFilter === "Extension") {
        setActiveArrowFilterList(typeFilter);
        setListOfItemsFilter(sortByExtenxion(listTofilter, directionArrowState));
      }
    };
    //FILTER USER
    const filterListUser = (typeFilter: any) => {
      if (typeFilter === t("general.firstname")) {
        setActiveArrowFilterList(t("general.firstname"));
        if (isStats) {
          setListOfItemsFilter(sortByUsers(listTofilter, directionArrowState, "firstname", true));
        } else {
          setListOfItemsFilter(
            sortByAlphabeticalOrder(listTofilter, "firstname", directionArrowState),
          );
        }
      }
      if (typeFilter === t("general.lastname")) {
        setActiveArrowFilterList(t("general.lastname"));
        if (isStats) {
          setListOfItemsFilter(sortByUsers(listTofilter, directionArrowState, "lastname", true));
        } else {
          setListOfItemsFilter(
            sortByAlphabeticalOrder(listTofilter, "lastname", directionArrowState),
          );
        }
      }
      if (typeFilter === t("general.identifier") || typeFilter === t("general.email")) {
        if (isStats) {
          setActiveArrowFilterList(t("general.email"));

          setListOfItemsFilter(sortByUsers(listTofilter, directionArrowState, "email", true));
        } else {
          setActiveArrowFilterList(t("general.identifier"));
          setListOfItemsFilter(sortByAlphabeticalOrder(listTofilter, "email", directionArrowState));
        }
      }
      if (typeFilter === t("pages.statistics.dateOfLatestSession")) {
        setActiveArrowFilterList(t("pages.statistics.dateOfLatestSession"));

        setListOfItemsFilter(
          sortByAlphabeticalOrder(listTofilter, "updatedAt", directionArrowState),
        );
      }

      if (typeFilter === t("general.projects")) {
        setActiveArrowFilterList(t("general.projects"));
        setListOfItemsFilter(
          sortByAlphabeticalOrder(listTofilter, "permissions", directionArrowState),
        );
      }
    };
    //FILTER PROJECT GROUP
    const filterListProjectGroup = (typeFilter: any) => {
      if (typeFilter === t("general.nameTable")) {
        setActiveArrowFilterList(t("general.nameTable"));
        setListOfItemsFilter(sortByAlphabeticalOrder(listTofilter, "name", directionArrowState));
      }
      if (typeFilter === t("general.authenticationType")) {
        setActiveArrowFilterList(t("general.authenticationType"));
        setListOfItemsFilter(
          sortByAlphabeticalOrder(listTofilter, "auth_type", directionArrowState),
        );
      }
      if (typeFilter === t("general.publicationStatus")) {
        setActiveArrowFilterList(t("general.publicationStatus"));
        setListOfItemsFilter(sortByStatus(listTofilter, directionArrowState, getIdAndStatusBuild));
      }
    };
    //FILTER PROJECT

    const filterListProject = (typeFilter: any) => {
      if (typeFilter === t("general.nameTable")) {
        setActiveArrowFilterList(t("general.nameTable"));
        setListOfItemsFilter(sortByAlphabeticalOrder(listTofilter, "name", directionArrowState));
      }
      if (typeFilter === "Publication") {
        setActiveArrowFilterList("Publication");
        setListOfItemsFilter(sortByAlphabeticalOrder(listTofilter, "status", directionArrowState));
      }
    };
    //FILTER COLLABORATOR in PG

    const filterListCollaborator = (typeFilter: any) => {
      if (typeFilter === t("general.firstname")) {
        setActiveArrowFilterList(t("general.firstname"));
        if (isAllCollaborators) {
          setListOfItemsFilter(
            sortByAlphabeticalOrder(listTofilter, "firstname", directionArrowState),
          );
        } else {
          setListOfItemsFilter(sortByUsers(listTofilter, directionArrowState, "firstname"));
        }
      }
      if (typeFilter === t("general.lastname")) {
        setActiveArrowFilterList(t("general.lastname"));
        if (isAllCollaborators) {
          setListOfItemsFilter(
            sortByAlphabeticalOrder(listTofilter, "lastname", directionArrowState),
          );
        } else {
          setListOfItemsFilter(sortByUsers(listTofilter, directionArrowState, "lastname"));
        }
      }
      if (typeFilter === t("general.email")) {
        setActiveArrowFilterList(t("general.email"));
        if (isAllCollaborators) {
          setListOfItemsFilter(sortByAlphabeticalOrder(listTofilter, "email", directionArrowState));
        } else {
          setListOfItemsFilter(sortByUsers(listTofilter, directionArrowState, "email"));
        }
      }
      if (typeFilter === t("general.dateAdded")) {
        setActiveArrowFilterList(t("general.dateAdded"));
        setListOfItemsFilter(sortByUsers(listTofilter, directionArrowState, "updatedAt"));
      }
      if (typeFilter === t("general.projectGroups")) {
        setActiveArrowFilterList(t("general.projectGroups"));
        setListOfItemsFilter(
          sortByAlphabeticalOrder(listTofilter, "projectGroupId", directionArrowState),
        );
      }
    };

    const filterByProjectGroupOrProject = (typeFilter: any) => {
      if (t("general.project") === typeFilter) {
        setActiveArrowFilterList(t("general.project"));
        setListOfItemsFilter(
          sortByAlphabeticalOrder(listTofilter, "projectName", directionArrowState),
        );
      }
      if (t("general.projectGroup") === typeFilter) {
        setActiveArrowFilterList(t("general.projectGroups"));
        setListOfItemsFilter(
          sortByAlphabeticalOrder(listTofilter, "projectGroupName", directionArrowState),
        );
      }
    };

    const handleOnSearch = (string) => {
      const filteredRows = [...rows].filter((row) =>
        Object.values(row).some((value) =>
          String(value).toLowerCase().includes(string.toLowerCase()),
        ),
      );
      setSearchResults(filteredRows);
      setSearchString(string);
    };

    const handleOnSelect = (item) => {
      const selectedRows = [...rows].filter((row) =>
        Object.values(row).some((value) => String(value).toLowerCase() === item.name.toLowerCase()),
      );
      setSearchResults(selectedRows);
    };

    const wrappingTableDivStyle =
      maxTableHeightPx !== 0
        ? ({ maxHeight: `${maxTableHeightPx}px`, overflowX: "hidden", overflowY: "auto" } as any)
        : { overflowX: "hidden", overflowY: "auto" };

    return (
      <div className="w-full h-full flex justify-center">
        <div
          style={wrappingTableDivStyle}
          className={`${className} overflow-visible personalize-scroll-visible`}
        >
          <div className={`w-full ${showSearchBar ? "flex justify-end" : ""}`}>
            {showSearchBar && (
              <div className="absolute z-50">
                <div className="relative top-[0.8rem] right-[1.6rem]">
                  <SearchAutocomplete
                    items={rows.map((row) => ({ name: row.name }))}
                    handleOnSearch={handleOnSearch}
                    handleOnSelect={handleOnSelect}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="h-full w-full flex relative">
            <TableContainer
              ref={ref}
              component={Paper}
              className={"personalize-scroll-visible relative h-full"}
              style={{ ...style, overflowY: "auto", overflowX: "auto" }}
            >
              <Table aria-label="simple table">
                {colHeaders && (
                  <TableHead className="bg-gray-200 sticky top-0 z-40">
                    <TableRow>
                      {checkAll && (
                        <TableCell style={{ width: "5%" }} key={"checkAll"}>
                          {checkAll}
                        </TableCell>
                      )}
                      {colHeaders.map((item: any, index: number) => (
                        <TableCell
                          key={`qs${index}`}
                          style={{
                            width:
                              isMediaList || isProjectList ? dynamicCellSize(Number(index)) : "",
                          }}
                        >
                          <button
                            className="flex"
                            type="button"
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                              event.stopPropagation();
                              if (actualColumActive === currentActiveArrowFilterList) {
                                setDirectionArrow(directionArrow === "desc" ? "asc" : "desc");
                              } else {
                                setDirectionArrow(directionArrow === "desc" ? "asc" : "desc");
                                setSetActualColumActive(currentActiveArrowFilterList);
                              }
                              if (isMediaList) {
                                filterListMedia(item);
                              }
                              if (isUserList) {
                                filterListUser(item);
                              }
                              if (isProjectGroup) {
                                filterListProjectGroup(item);
                              }
                              if (isProjectList) {
                                filterListProject(item);
                              }
                              if (isCollaboratorAndParticipant) {
                                filterListCollaborator(item);
                              }
                              if (createOrUpdateUserAsAdminPermissionsList) {
                                filterByProjectGroupOrProject(item);
                              }
                            }}
                          >
                            {item}
                            {item !== "" && filterActivated && getSortIcon(item)}
                          </button>
                          {lastColumnHeaderNumber === index && showSearchBar && (
                            <div className="w-[250px]"></div>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                )}
                <TableBody className="w-full">
                  {keys.length > 0 ? (
                    searchResults.map((row: any, i: number) => {
                      const isProjectsTable = "projectId" in row;
                      const isProjectGroupsTable = "projectGroupId" in row;
                      const rowStyle = {
                        backgroundColor: row?.checkbox?.props?.checked ? "#ced2dd" : "",
                        cursor: isCursorDesativated ? "" : "pointer",
                      };
                      return (
                        <TableRow
                          className={`${
                            isProjectsTable || isProjectGroupsTable ? "hover:bg-gray-200" : ""
                          }`}
                          key={`qq${i}`}
                          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                          style={rowStyle}
                          onClick={() => {
                            if (isProjectGroupsTable) {
                              const projectGroupId = Number(row.projectGroupId);
                              dispatch(setSelectedProjectGroupFromId(projectGroupId));
                              dispatch(
                                fetchAllCurrentElements({
                                  projectGroupId,
                                  componentId: PROJECT_GROUP_LIST_COMPONENT_ID,
                                }),
                              ).then(() => {
                                navigate(`/project-groups/${projectGroupId}/list`);
                              });
                            }
                            if (isProjectsTable) {
                              dispatch(setCurrentProjectFromId(row.projectId));
                              navigate(`${row.projectId}/graph`);
                            }
                            if (row.checkbox && isMediaList) {
                              const daCheckbox = document.getElementById(
                                `${row.checkbox.props.id}`,
                              );
                              daCheckbox?.click();
                            }
                            if (row.checkbox && selectFromListInTable) {
                              const daCheckbox = document.getElementById(
                                `${row.checkbox.props.id}`,
                              );
                              daCheckbox?.click();
                            }
                          }}
                        >
                          {keys
                            .filter((key) => key !== "projectId" && key !== "projectGroupId")
                            .map((key, i) => {
                              const value = row[key];
                              return (
                                <TableCell
                                  key={`${i}_qq`}
                                  className={`${cellClassName} ${
                                    key === "projectGroupInWhichItIs" ? "text-gray-400" : ""
                                  }`}
                                >
                                  {value}
                                </TableCell>
                              );
                            })}
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={colHeaders.length + 1}
                        align="center"
                        className="border-0"
                      >
                        {emptyPlaceholder}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    );
  },
);

export default TableComp;
