import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import Icon, { IconName } from "../Icon/Icon";

export interface SideBarItemProps {
  icon?: IconName;
  customIcon?: any;
  routeTo: string;
  title: string;
  onClick?(): void;
  disabled?: boolean;
  tooltipText?: string;
  fillIcon?: string;
  isSelected?: boolean;
  classNameOnboarding?: string;
}

const SidebarItem = ({
  icon,
  routeTo,
  title,
  disabled,
  isSelected = false,
  classNameOnboarding,
  customIcon = null,
  ...props
}: SideBarItemProps) => {
  return (
    <Link to={routeTo}>
      <button
        className={`${classNameOnboarding} flex w-full  my-4 py-2 lg:py-1 lg:my-2 2xl:my-4 2xl:py-2 md:py-1 md:my-1 xxl:my-2 xxl:py-1 flex-col items-center  ${
          isSelected ? "bg-[#414587]" : "bg-blue-500 "
        } hover:bg-blue-300 rounded-md cursor-pointer`}
        {...props}
      >
        {!icon && customIcon ? (
          customIcon
        ) : (
          <Icon
            icon={icon as IconName}
            className="w-4 h-4 lg:w-5 lg:h-5  2xl:h-6  2xl:w-6 stroke-white "
          />
        )}
        <p className="text-white text-xs 2xl:text-sm lg:text-xs">{title}</p>
      </button>
    </Link>
  );
};

const SidebarItemSmall = ({
  icon,
  routeTo,
  title,
  disabled,
  fillIcon = "none",
  isSelected = false,
  ...props
}: SideBarItemProps) => {
  let button = (
    <button
      className={`flex w-full my-1 px-2 cursor-pointer py-2 items-center md:py-px md:my-px ${
        isSelected ? "bg-[#414587]" : "bg-blue-500"
      } hover:bg-blue-300 rounded-md xxl:my-0`}
      {...props}
    >
      <div className="mr-2">
        <Icon icon={icon as IconName} fill={fillIcon} className="w-4 h-4 stroke-white" />
      </div>
      <p className="text-white text-xs lg:text-xs 2xl:text-sm">{title}</p>
    </button>
  );
  if (!disabled && routeTo !== "") {
    button = <Link to={routeTo}>{button}</Link>;
  }

  return button;
};

const SidebarItemProject = ({
  icon,
  routeTo,
  title,
  disabled,
  tooltipText,
  ...props
}: SideBarItemProps) => {
  let button = (
    <button
      className={`${
        disabled ? "disabled:opacity-50" : "hover:bg-blue-300"
      } flex flex-col w-full my-1 px-2 h-24 cursor-pointer py-2 items-center bg-blue-500 rounded-md`}
      {...props}
      disabled={disabled}
    >
      <Icon icon={icon as IconName} className="w-6 h-6 stroke-white m-auto" />
      <span className="text-white">{title}</span>
    </button>
  );
  if (tooltipText && tooltipText !== "") {
    button = <Tooltip title={tooltipText}>{button}</Tooltip>;
  }

  if (!disabled && routeTo !== "") {
    button = <Link to={routeTo}>{button}</Link>;
  }

  return button;
};

export { SidebarItem, SidebarItemSmall, SidebarItemProject };
