import { useEffect, useRef, useState } from "react";
import { Control, Controller, UseFormSetValue } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IsLoading } from "../../../common/components/AppState/IsLoading";
import { ButtonSquareIconsProject } from "../../../common/components/Button";
import { useAppDispatch } from "../../../common/state/hooks";
import { openConfirmationModal } from "../../../common/state/slice/modal/modalSlice";
import { RootState } from "../../../common/state/store";
import handleThreeDotsButtonClickForProjectsAndProjectGroups from "../../../common/util/handleThreeDotsButtonClickForProjectsAndProjectGroups";
import { IProject } from "../../../model/model";
import { mediaSelector } from "../../gallery/state/gallerySlice";
import {
  getCurrentTemplateId,
  INewProjectFormValues,
  setCurrentTemplateId,
  TemplatesForTagSelector,
} from "../state/projectsSlice";
import { useTranslation } from "react-i18next";
import useScrollbarVisibility from "src/common/util/useScrollbarVisibility";

const TemplateCustomInput = ({
  tag,
  control,
  setValue,
}: {
  setValue: UseFormSetValue<INewProjectFormValues>;
  control: Control<INewProjectFormValues, object>;
  tag: string;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [scrollPosition, setScrollPosition] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const coverDivRef = useRef<HTMLDivElement>(null);

  const windowWidth = window.innerWidth;
  const pixelsTolost = 1920 - windowWidth;

  const pixelsForDiv = 1696 - pixelsTolost;
  const pixelsTorRem = String(pixelsForDiv / 16);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    setScrollPosition(e.currentTarget.scrollLeft);
  };

  const applyGradient = () => {
    if (containerRef.current && coverDivRef.current) {
      const container = containerRef.current;
      const scrollPosition = container.scrollLeft;
      const atStart = scrollPosition === 0;
      const numericalAtEnd = Math.abs(container.scrollWidth - container.offsetWidth);
      const atEnd = Math.abs(scrollPosition - numericalAtEnd) <= 1;

      const classesToRemove = ["right-gradient", "left-gradient", "left-and-right-gradient"];
      coverDivRef.current.classList.remove(...classesToRemove);

      if (atStart) {
        coverDivRef.current.classList.add("right-gradient");
      } else if (atEnd) {
        coverDivRef.current.classList.add("left-gradient");
      } else {
        coverDivRef.current.classList.add("left-and-right-gradient");
      }
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScroll as any);
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("scroll", handleScroll as any);
      }
    };
  }, []);

  useEffect(() => {
    applyGradient();
  }, [scrollPosition]);

  const { templates } = useSelector((state: RootState) => {
    return {
      templates: TemplatesForTagSelector({ tag: tag, state }),
      medias: mediaSelector(state),
    };
  });
  const selectedTemplateId = useSelector(getCurrentTemplateId);

  useEffect(() => {
    dispatch(setCurrentTemplateId(390));
    setValue("projectId", Number(390));
  }, []);

  useEffect(() => {
    const handleWidthUpdate = () => {
      if (containerRef.current && coverDivRef.current) {
        const containerWidth = containerRef.current.clientWidth;
        coverDivRef.current.style.width = `${containerWidth}px`;
      }
    };

    handleWidthUpdate();

    window.addEventListener("resize", handleWidthUpdate);
    return () => window.removeEventListener("resize", handleWidthUpdate);
  }, []);
  const isHorizontalScrollbarVisible = templates && useScrollbarVisibility(containerRef);

  if (templates.filteredProjects.length < 1) {
    return <p className="w-96">{t("chooseTemplateType.noCustomTemplate")}</p>;
  } else {
    return (
      <>
        <div className="relative ml-4 h-[61.5%]">
          <div className=" w-fit bg-[#e6e6e6] relative rounded-3xl font-medium h-[11.5rem] bg-center bg-cover bg-gray-300 bg-opacity-50 bg-blend-soft-light shadow-md ">
            <div
              ref={containerRef}
              onScroll={handleScroll}
              // not with tailwind because it doesn't work when changin the width of the div dynamically
              style={{ maxWidth: `${pixelsTorRem}rem` }}
              className={` 
                relative horizontal-project-cards-mapping flex overflow-y-hidden scroll whitespace-nowrap scroll-smooth ml-3  mr-3 personalize-scroll-visible project-group-projects-container h-[10.7rem] pl-3 `}
            >
              {templates.filteredProjects.map((template: IProject, index: any) => (
                <Controller
                  key={index}
                  name="projectId"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <IsLoading
                      key={`template-${template.id}`}
                      componentId={`template-${template.id}`}
                      spinnerPlaceholder
                    >
                      <ButtonSquareIconsProject
                        width="w-56"
                        height={"h-32 mr-5 mt-5 mb-5"}
                        threeDotsMenuItemsPosition="top-6 right-6"
                        type="button"
                        {...field}
                        onClick={() => {
                          dispatch(setCurrentTemplateId(template.id));
                          setValue("projectId", Number(template.id));
                        }}
                        currentProject={template.id}
                        project={template}
                        handleThreeDotButtonClick={(e: any) => {
                          dispatch(setCurrentTemplateId(template.id));

                          handleThreeDotsButtonClickForProjectsAndProjectGroups({
                            payload: e,
                            deleteCallback: () => {
                              dispatch(openConfirmationModal("deleteTemplateConfirmation"));
                            },
                            t,
                            navigate,
                          });
                        }}
                        isSelected={Number(selectedTemplateId) === Number(template.id)}
                        showThreeDotsButton={tag === "custom-templates"}
                        isCustomTemplateCard={tag === "custom-templates"}
                      />
                    </IsLoading>
                  )}
                />
              ))}
            </div>
            <div
              ref={coverDivRef}
              className="absolute w-full pointer-events-none h-32 top-5 left-3 z-10"
              style={{ visibility: isHorizontalScrollbarVisible ? "visible" : "hidden" }}
            ></div>
          </div>
        </div>
      </>
    );
  }
};

export default TemplateCustomInput;
