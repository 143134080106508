import { useTranslation } from "react-i18next";
import { Outlet, useLocation } from "react-router-dom";
import ModalConfirmUserDeletion from "src/common/components/Modal/ModalConfirmUserDeletion";
import Navigation from "src/common/components/Navigation/Navigation";

const CollaboratorsWrapper = () => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <>
      <Navigation
        title={t("pages.project.projectGroupCollaborators.collaborators")}
        showGoBackButton={
          location.pathname !== "/collaborators/" && location.pathname !== "/collaborators"
        }
        backDepth={1}
      />

      <Outlet />
      <ModalConfirmUserDeletion />

      {/* <AddTeamModal />
      <ParticipantCsv /> */}
    </>
  );
};

export default CollaboratorsWrapper;
