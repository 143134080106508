import { ChevronRightIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { IProjectGroupWithProjects } from "../../../features/projectGroups/state/projectGroupsSlice";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../common/state/hooks";
import { setSelectedProjectId } from "src/features/stats/state/statsSlice";
import {
  closeStandardModal,
  getCurrentModalCurrentState,
} from "src/common/state/slice/modal/modalSlice";
import { getCurrentProject } from "src/features/projects/state/projectsSlice";

interface INavigationItem {
  name: string;
  index: any;
  titles: any;
  pathRoute: string[];
  pathnames: string[];
  pathArray: string[];
  pathname: string;
  selectedProjectGroup: IProjectGroupWithProjects;
}

export const NavigationItem = ({
  name,
  index,
  titles,
  pathRoute,
  pathnames,
  pathArray,
  pathname,
  selectedProjectGroup,
}: INavigationItem) => {
  const getModalState = useSelector(getCurrentModalCurrentState);

  const currentProject = useSelector(getCurrentProject);
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const filterWordNewInpath = pathRoute.filter((word) => word !== "new");
  const routeTo =
    name === "project-groups" ? "/project-groups" : `/${filterWordNewInpath.join("/")}`;

  const isLast = index === pathnames.length - 1;
  const titleCurrentProject = selectedProjectGroup?.name;

  if (!isLast && titles === t("general.projectGroups")) {
    return <></>;
  }

  let link = (
    <span className="inline-flex  items-center text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2">
      {titles}
    </span>
  );
  if (titles === "Projects") {
    link = (
      <>
        <Link to={routeTo} className="ml-1  text-sm font-medium text-gray-400 md:ml-2">
          {titleCurrentProject}
        </Link>
        <ChevronRightIcon className="w-5 h-5 text-gray-400" />
        <span className="inline-flex  items-center text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2">
          New
        </span>
      </>
    );
  }

  if (pathArray[0] === "themes" && titles !== t("pages.themeEditor.title")) {
    link = (
      <>
        <button
          onClick={() => {
            dispatch(closeStandardModal("modalThemeEditor"));
          }}
          className="ml-1  text-sm font-medium text-gray-400 md:ml-2"
        >
          {t("pages.themeEditor.title")}
        </button>
        <ChevronRightIcon className="w-5 h-5 text-gray-400" />
        <span className="inline-flex  items-center text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2">
          {titles}
        </span>
      </>
    );
  }

  //breadcrumb for edit Current Project name
  if (/(\/project-groups\/)(\d{1,256}\/)(list\/)(\d{1,256}\/edit)/gm.test(pathname)) {
    const replaceEditforGraph = pathArray;
    replaceEditforGraph[4] = "graph";
    const routeToEdit =
      name === "project-groups" ? "/project-groups" : `/${replaceEditforGraph.join("/")}`;

    link = (
      <>
        <Link to={routeToEdit} className="ml-1  text-sm font-medium text-gray-400 md:ml-2">
          {currentProject.name}
        </Link>
        <ChevronRightIcon className="w-5 h-5 text-gray-400" />
        <span className="inline-flex capitalize items-center text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2">
          {t("general.edit")}
        </span>
      </>
    );
  }

  // breadcrumb for userProfile
  if (/(\/profile\/)(\w+)/gm.test(pathname)) {
    const replaceEditforGraph = pathArray;

    switch (pathArray[1]) {
      case "me":
        replaceEditforGraph[1] = t("pages.profile.tabs.myDetails");
        break;
      case "organization":
        replaceEditforGraph[1] = t("pages.profile.tabs.myOrganization");
        break;
      case "subscription":
        replaceEditforGraph[1] = t("pages.profile.tabs.subscription");
        break;
      case "team":
        replaceEditforGraph[1] = t("pages.profile.tabs.team");
        break;
      default:
        pathArray[0];
    }
    link = (
      <>
        <span className="inline-flex  items-center text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2">
          {pathArray[1]}
        </span>
      </>
    );
  }
  if (
    /(\/project-groups\/)(\d{1,256}\/)(list\/)(\d{1,256}\/graph)(\/node\/)(\d{1,256})/gm.test(
      pathname,
    ) &&
    getModalState.showStandardModal.show
  ) {
    const replaceEditforGraph = pathArray;

    const routeToEdit = `/${replaceEditforGraph.join("/")}`;
    link = (
      <>
        <Link to={routeToEdit} className="ml-1  text-sm font-medium text-gray-700 md:ml-2">
          {currentProject.name}
        </Link>
        <span className="inline-flex  items-center text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2"></span>
      </>
    );
  }

  //breadcrumb for edit Projectgroups
  if (/(\/project-groups\/)(\d{1,256}\/)(edit)/gm.test(pathname)) {
    const replaceEditforGraph = pathArray;
    replaceEditforGraph[2] = "list";

    const routeToEdit =
      name === "project-groups" ? "/project-groups" : `/${replaceEditforGraph.join("/")}`;
    link = (
      <>
        <Link to={routeToEdit} className="ml-1  text-sm font-medium text-gray-400 md:ml-2">
          {titleCurrentProject}
        </Link>
        <ChevronRightIcon className="w-5 h-5 text-gray-400" />
        <span className="inline-flex  items-center text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2">
          Edit
        </span>
      </>
    );
  }

  if (titles !== t("general.projectGroups") && !isLast) {
    let linkToRoute = routeTo;
    const filterWordTemplate = pathRoute.filter((word) => word !== "template");

    let linkDisplayName =
      name === "project-groups"
        ? t("general.projectGroups")
        : name[0]?.toUpperCase() + name?.slice(1);

    if (linkDisplayName === "Statistics") {
      linkDisplayName = t("sidebar.statistics");
    }
    if (titles === t("pages.statistics.overview")) {
      linkDisplayName = t("sidebar.statistics");
    }
    if (titles === t("pages.profile.myProfile")) {
      linkDisplayName = t("pages.profile.myProfile");
    }
    if (titles === t("pages.faitTemplate.title")) {
      linkToRoute =
        name === "project-groups" ? "/project-groups" : `/${filterWordTemplate.join("/")}`;
    }
    if (/^\/download-client/gm.test(pathname)) {
      linkDisplayName = t("pages.downloadClient.download");
    }

    link = (
      <Link to={linkToRoute} className="ml-1 text-sm font-medium text-gray-400 md:ml-2">
        {linkDisplayName}
      </Link>
    );
    if (linkToRoute === "/statistics") {
      link = (
        <Link
          to={linkToRoute}
          onClick={() => {
            dispatch(setSelectedProjectId(Number(0)));
          }}
          className="ml-1  text-sm font-medium text-gray-400 md:ml-2"
        >
          {linkDisplayName}
        </Link>
      );
    }
  }

  if (pathname === "/medias") {
    link = (
      <div className="inline-flex  items-center text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2">
        {t("sidebar.media")}
      </div>
    );
  }

  return (
    <li>
      <div className="flex items-center">
        <ChevronRightIcon className="w-5 h-5 text-gray-400" />
        {link}
      </div>
    </li>
  );
};

export default NavigationItem;
