import Navigation from "../../common/components/Navigation/Navigation";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SubmitHandler, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { RootState } from "../../common/state/store";
import {
  getProjectTagList,
  getProjectTagListSelector,
  INewProjectFormValues,
  setNewProject,
} from "../../features/projects/state/projectsSlice";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../common/state/hooks";
import TemplateCategories from "../../features/projects/components/TemplateCategories";
import {
  getCurrentElements,
  getProjectGroupSize,
  getSelectedProjectGroup,
} from "../../features/projectGroups/state/projectGroupsSlice";
import { FooterButtons } from "../../common/components/FooterButtons";
import { getCurrentUploadsStateList } from "src/features/gallery/state/gallerySlice";

const GET_TEMPLATE_COMPONENT = "templateCompnent";

const Templates = () => {
  useEffect(() => {
    dispatch(getProjectTagList({ componentId: GET_TEMPLATE_COMPONENT }));
  }, []);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { tagList, currentProjectGroup, uploads } = useSelector((state: RootState) => {
    return {
      tagList: getProjectTagListSelector(state),
      currentProjectGroup: getSelectedProjectGroup(state),
      uploads: getCurrentUploadsStateList(state),
    };
  });

  const [formHeight, setFormHeight] = useState(0);

  const { handleSubmit, control, setValue } = useForm<INewProjectFormValues>();

  const onSubmit: SubmitHandler<INewProjectFormValues> = (data) => {
    // TODO: replace this quick fix of WIXAR-3750 to proper langage selector (WIXAR-3595 and its ramifications)
    dispatch(setNewProject({ ...data, languageId: 1 }));
    navigate(`${data.projectId}`);
  };

  const { selectedProjectGroup, currentElements } = useSelector((state: RootState) => {
    return {
      selectedProjectGroup: getSelectedProjectGroup(state),
      currentElements: getCurrentElements(state),
    };
  });

  const [isComputingPgSize, setIsComputingPgSize] = useState(false);
  const [isOverSizeLimitForProjectGroup, setIsOverSizeLimitForProjectGroup] = useState(false);

  useEffect(() => {
    if (currentElements.length > 0) {
      setIsComputingPgSize(true);
      dispatch(
        getProjectGroupSize({
          projectsIds: currentElements.map((pge) => pge.project.id).join(","),
        }),
      )
        .then((res: any) => {
          const projectGroupSize = res.payload.data;
          // If the current project group size is > 4.5Gb, forbid the project creation
          if (projectGroupSize > 4831838208) {
            setIsOverSizeLimitForProjectGroup(true);
          }
        })
        .finally(() => {
          setIsComputingPgSize(false);
        });
    }
  }, []);

  const getNextDisabledTooltipText = () => {
    if (isComputingPgSize) {
      return t("tooltip.computingPgSize");
    } else if (isOverSizeLimitForProjectGroup) {
      return t("tooltip.cannotCreateProjectProjectGroupTooLarge");
    } else {
      return "";
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const navbarElement = document.getElementById("navbar");
      const uploadBarElement = document.getElementById("upload-bar-overlay");
      const navbarHeight = uploadBarElement?.clientHeight || 0;
      const uploadBarHeight = navbarElement?.clientHeight || 0;
      const windowHeight = document.documentElement.clientHeight;

      setFormHeight(windowHeight - navbarHeight - uploadBarHeight);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [uploads]);

  return (
    <>
      <Navigation
        title={t("pages.createProject.title")}
        showGoBackButton
        backCustomRedirectTo={`/project-groups/${currentProjectGroup.id}/list`}
      />
      <div className="w-full xl:px-0 ">
        <form
          key={formHeight}
          onSubmit={handleSubmit(onSubmit)}
          style={{ height: `${formHeight}px` }}
          className="flex flex-col justify-between"
        >
          <h1 className="text-2xl font-medium pl-4 pr-4 pt-2">{t("chooseTemplateType.title")}</h1>
          <p className="mb-6 mt-4 pl-4 pr-4">{t("chooseTemplateType.whatsTemplate")}</p>

          <div className="inline-block w-full my-6 pl-4 pr-4 overflow-auto flex-grow  personalize-scroll-visible">
            <TemplateCategories setValue={setValue} control={control} tagList={tagList} />
          </div>

          <div className="bg-gray-100 w-full flex-shrink-0">
            <FooterButtons
              cancelText={t("general.cancel")}
              cancelCb={() => navigate(`/project-groups/${selectedProjectGroup.id}/list`)}
              backText={t("general.back")}
              backDisabled
              nextText={t("general.next")}
              nextDisabled={isComputingPgSize || isOverSizeLimitForProjectGroup}
              nextDisabledTooltipText={getNextDisabledTooltipText()}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default Templates;
