import ProjectScenes from "../../features/graph/ProjectScenes";
import { useParams } from "react-router";
import { EditNode } from "../../features/graph/components/EditNodeForm";
import ProjectSidebar from "../../common/components/Sidebar/ProjectSidebar";
import { useSelector } from "react-redux";
import { getIsLoading } from "../../features/graph/state/graphuSlice";

export const ProjectGraph = () => {
  const isGraphLoading = useSelector(getIsLoading);

  return (
    <>
      {!isGraphLoading && <ProjectSidebar />}
      <ProjectScenes />
    </>
  );
};

export const EditNodeScreen = () => {
  const { nodeId, contentType } = useParams();
  if (nodeId) {
    return <EditNode nodeId={nodeId} contentType={contentType as string} />;
  } else {
    return <>Node not found</>;
  }
};
