import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IsLoading } from "../../common/components/AppState/IsLoading";
import InfoCard from "../../common/components/Cards/InfoCard";
import { useAppDispatch } from "../../common/state/hooks";
import { roundPercentage } from "../../common/util/round";
import { getCurrentUser } from "../../features/profile/state/profileSlice";
import BarChartSessionsPerMonth from "../../features/stats/components/Charts/BarChartSessionsPerMonth";
import SuccessRatePerScene from "../../features/stats/components/Charts/SuccessRatePerScene";
import {
  getEnduserStatsPerProject,
  getSelectedProjectScorePerScene,
  getSelectedProjectWithSessions,
  getSelectedUserProjectScore,
} from "../../features/stats/state/statsSlice";
import { ISession } from "../../model/model";
import { SUCCESS_RATE_PER_SCENE_COMPONENT_ID } from "./StatisticsForProject";

export const ENDUSER_STATS_PER_PROJECT_COMPONENT_ID = "enduserStatsPerProjectComponentId";
export const ENDUSER_STATS_PER_PROJECT_PAGE_ID = "enduserStatsPerProjectPageId";
interface IProjectStatsEndUser {
  projectId: number;
  projectName: string;
}

const StatisticsEndUser = ({ projectId, projectName }: IProjectStatsEndUser) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentUser = useSelector(getCurrentUser);
  const userId = currentUser?.id;

  const sessions = useSelector(getSelectedProjectWithSessions(projectId)).sessions;
  const selectedProjectScorePerScene = useSelector(getSelectedProjectScorePerScene);

  const relevantSessions = sessions
    ? sessions
    : [].filter((s: ISession) => s.enduser && Number(s.enduser.id) === Number(userId));
  const [averageDurationCompletedSession, setAverageDurationCompletedSession] = useState("");
  const [bestScore, setBestScore] = useState("");
  const [worstScore, setWorstScore] = useState("");

  const numberOfSessions = String(relevantSessions.length);
  const countUniqueParticipants = new Set();

  const completionPercentage =
    relevantSessions.length / relevantSessions.filter((s: ISession) => s.is_completed).length;
  const prettyCompletionPercentage = roundPercentage(completionPercentage);

  const selectedUserProjectScore = useSelector(getSelectedUserProjectScore);
  useEffect(() => {
    let duration = 0;
    relevantSessions.forEach((s: ISession) => {
      countUniqueParticipants.add(s.user_name);
      duration += s.total_session_duration;
    });

    setAverageDurationCompletedSession(`${Math.round(duration / relevantSessions.length)} s`);

    const sorted = [...relevantSessions].sort(
      (a: ISession, b: ISession) => Number(b.score) - Number(a.score),
    );
    setBestScore(sorted[0]?.score ?? "0");
    setWorstScore(sorted[relevantSessions.length - 1]?.score ?? "0");

    dispatch(
      getEnduserStatsPerProject({
        userId: userId,
        projectId: projectId,
        componentId: ENDUSER_STATS_PER_PROJECT_COMPONENT_ID,
      }),
    );
  }, [userId]);

  console.log("selectedUserProjectScore ", selectedUserProjectScore);

  return (
    <div className="container w-full max-w-screen-2xl px-2.5">
      <IsLoading
        componentId={ENDUSER_STATS_PER_PROJECT_PAGE_ID}
        showSuccess={false}
        spinnerPlaceholder
      />
      <div className="border-b-2 border-color-gey-opacity mb-4">
        <h3 className="mt-1 mb-5 text-3xl font-medium">
          {t("pages.statistics.generalStats")}
          {" : "}
          {projectName}
        </h3>
      </div>
      <div className="flex ">
        <div className="xl:h-52 h-72 w-1/2 bg-white rounded-lg shadow-md ">
          <p className="text-gray-800 font-medium text-base text-center p-2">
            {t("pages.statistics.successRatePerScene")}
          </p>
          <IsLoading
            componentId={
              userId ? ENDUSER_STATS_PER_PROJECT_COMPONENT_ID : SUCCESS_RATE_PER_SCENE_COMPONENT_ID
            }
            showSuccess={false}
            spinnerPlaceholder
            spinnerStyle={{ marginTop: "-1.8vh" }}
          >
            <SuccessRatePerScene
              // If there is an userId, diplay these stats; else display for whole project
              data={userId ? selectedUserProjectScore.successRate : selectedProjectScorePerScene}
            />
          </IsLoading>
        </div>

        <div className="">
          <div className="grid grid-cols-2 gap-4 w-full mx-auto  pl-3">
            <div className="row-span-2">
              <InfoCard
                heightEndUser
                title={t("pages.statistics.numberOfSessions")}
                data={numberOfSessions}
              />
            </div>

            <div className="row-span-2">
              <InfoCard heightEndUser title={t("pages.statistics.bestScore")} data={bestScore} />
            </div>

            <div className="row-span-2">
              <InfoCard
                heightEndUser
                title={t("pages.statistics.averageDurationCompletedSession")}
                data={averageDurationCompletedSession}
              />
            </div>
            <div className="row-span-2">
              <InfoCard heightEndUser title={t("pages.statistics.worstScore")} data={worstScore} />
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-between items-center mt-2 mb-3"></div>
      <div className="flex">
        <div className="w-full max-w-9xl mx-auto mr-4">
          <div className="grid grid-cols-2 gap-4 w-full mx-auto xl:px-0 ">
            <div className="row-span-2">
              <IsLoading
                componentId={ENDUSER_STATS_PER_PROJECT_COMPONENT_ID}
                showSuccess={false}
                spinnerPlaceholder
              >
                <InfoCard
                  heightEndUser
                  title={t("pages.statistics.rank")}
                  data={`${selectedUserProjectScore.rank}/${selectedUserProjectScore.competitors}`}
                />
              </IsLoading>
            </div>
            <div className="row-span-2">
              <InfoCard
                heightEndUser
                title={t("pages.statistics.lastSessionDate")}
                data={`${new Date(selectedUserProjectScore.last_session).toLocaleString("en-GB")}`}
              />
            </div>

            <div className="row-span-2">
              <IsLoading
                componentId={ENDUSER_STATS_PER_PROJECT_COMPONENT_ID}
                showSuccess={false}
                spinnerPlaceholder
              >
                <InfoCard
                  heightEndUser
                  title={t("pages.statistics.firstSessionScore")}
                  data={`${selectedUserProjectScore.first_session_score}/${selectedUserProjectScore.project_max_score}`}
                />
              </IsLoading>
            </div>
            <div className="row-span-2">
              <InfoCard
                heightEndUser
                title={t("pages.statistics.completionRate")}
                data={`${prettyCompletionPercentage}% `}
              />
            </div>

            <div className="row-span-2">
              <IsLoading
                componentId={ENDUSER_STATS_PER_PROJECT_COMPONENT_ID}
                showSuccess={false}
                spinnerPlaceholder
              >
                <InfoCard
                  heightEndUser
                  title={t("pages.statistics.lastSessionScore")}
                  data={
                    selectedUserProjectScore.lastSessionScoreAndMaxScoreString !==
                    "incomplete_session"
                      ? String(selectedUserProjectScore.lastSessionScoreAndMaxScoreString)
                      : t("pages.statistics.incompleteSession")
                  }
                />
              </IsLoading>
            </div>
          </div>
        </div>
        <div className="xl:h-80 h-72 w-11/12 bg-white rounded-lg shadow-md pt-5">
          <p className="text-gray-800 font-medium text-base text-center p-2">
            {t("pages.statistics.sessionsPerMonth")}
          </p>
          <BarChartSessionsPerMonth isProject noSession={false} id={projectId} userId={userId} />
        </div>
      </div>
    </div>
  );
};

export default StatisticsEndUser;
