import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IsLoading } from "src/common/components/AppState/IsLoading";
import { ButtonIcon } from "src/common/components/Button";
import { CREATE_OR_UPDATE_USER_AS_ADMIN_PERMISSIONS_LIST_COMPONENT } from "src/common/components/CreateOrUpdateUserAsAdmin/CreateOrUpdateUserAsAdminPermissionsList";
import { DeleteElementsSelectedFromList } from "src/common/components/DeleteElementsSelectedFromList/DeleteElementsSelectedFromList";
import { Checkbox, Dropdown } from "src/common/components/Input";
import TableComp from "src/common/components/Table/Table";
import CheckAll from "src/common/components/UsersTable/CheckAll";
import {
  DisplayAllowedItems,
  IAllowedItem,
} from "src/common/components/UsersTable/DisplayAllowedItems";
import { UserCrudButtons } from "src/common/components/UsersTable/UserCrudButtons";
import { useAppDispatch } from "src/common/state/hooks";
import {
  getConfirmationModalStateShow,
  openConfirmationModal,
} from "src/common/state/slice/modal/modalSlice";
import { getAllProjectGroupsWithNestedElements } from "src/features/projectGroups/state/projectGroupsSlice";
import RoundImagePreview from "src/features/projects/components/RoundImagePreview";
import {
  fetchUserDetails,
  getCreators,
  getTeamAsAdmin,
  setDeleteUsersDraft,
} from "src/features/team/state/teamSlice";

// Without having several const, given two concurrent API calls, there will be a race condition causing the component to render
// (incorrect datas) once the quickest API call returns. Here it happened with the "GET" permissions being quicker than the "PUT" permissions
// while sharing a single spinner.
export const COLLABORATORS_LIST_COMPONENT = "collaboratorsListComponent";
export const COLLABORATORS_TABLE_COMPONENT = "collaboratorsTableComponent";
export const ANOTHER_COLLABORATORS_TABLE_COMPONENT = "anotherCollaboratorsTableComponent";

const CollaboratorsList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const tableContainerRef = useRef(null);

  const projectGroups = useSelector(getAllProjectGroupsWithNestedElements);
  const collaborators = useSelector(getCreators);
  const confirmationModalStateShow = useSelector(getConfirmationModalStateShow);
  const [filteredCreators, setFilteredCreators] = useState(collaborators);

  const [selectedProjectGroupId, setSelectedProjectGroupId] = useState("0");
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isCheck, setIsCheck] = useState<Array<any>>([]);
  // We're computing table max height manually to avoid a lot of CSS headaches *PTSD images of Vietnam and failing scrollbars *
  const [tableHeight, setTableHeight] = useState(0);
  const [tableKey, setTableKey] = useState(0);

  // Useful to determine if any filter has been applied
  const maxRowsLengthRef = useRef(collaborators.length);

  const handleSelectAll = () => {
    if (isCheck.length > 0) {
      setIsCheck([]);
    } else {
      setIsCheck(filteredCreators.map((li) => String(li.id)));
    }
  };

  const handleClick = (e: any) => {
    const { id, checked } = e.target;

    // Save scroll position before state update
    if (tableContainerRef.current) {
      const current = tableContainerRef.current as HTMLDivElement;
      setScrollPosition(current.scrollTop);
    }

    // State update logic
    if (checked) {
      const nextValues = [...isCheck, id];
      setIsCheck(nextValues);
    } else {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  useEffect(() => {
    dispatch(getTeamAsAdmin({ componentId: COLLABORATORS_LIST_COMPONENT })).then((res: any) => {
      setFilteredCreators(res.payload.data.creators);
      if (res.payload.data.creators.length > maxRowsLengthRef.current) {
        maxRowsLengthRef.current = res.payload.data.creators.length;
      }
    });
  }, []);

  useEffect(() => {
    const calculateHeight = () => {
      const divinaProportione = Math.round(window.innerHeight * (2 / 3));
      setTableHeight(divinaProportione);
    };

    calculateHeight();

    window.addEventListener("resize", calculateHeight);
    return () => {
      window.removeEventListener("resize", calculateHeight);
    };
  }, []);

  useEffect(() => {
    if (selectedProjectGroupId === "0") {
      setFilteredCreators(collaborators);
      if (collaborators.length === 0 || collaborators.length > maxRowsLengthRef.current) {
        maxRowsLengthRef.current = collaborators.length;
      }
    } else {
      setFilteredCreators(
        collaborators.filter((collaborator) => {
          return collaborator.permissions.find(
            (p) => String(p.project_group?.id) === String(selectedProjectGroupId),
          );
        }),
      );
      if (
        collaborators.filter((collaborator) => {
          return collaborator.permissions.find(
            (p) => String(p.project_group?.id) === String(selectedProjectGroupId),
          );
        }).length > maxRowsLengthRef.current
      ) {
        maxRowsLengthRef.current = collaborators.filter((collaborator) => {
          return collaborator.permissions.find(
            (p) => String(p.project_group?.id) === String(selectedProjectGroupId),
          );
        }).length;
      }
    }
    setIsCheck([]);
  }, [selectedProjectGroupId, collaborators.length]);

  // This avoids the problems caused by the "delete multiple" button staying accessible after the "delete multiple users confirmation modal" have been clicked
  useEffect(() => {
    if (!confirmationModalStateShow) {
      setIsCheck([]);
    }
  }, [confirmationModalStateShow]);

  // Update the state when the store was updated (typically, by an API call)
  useEffect(() => {
    setFilteredCreators(collaborators);
    setTableKey(tableKey + 1);
    setIsCheck([]);
    if (collaborators.length > maxRowsLengthRef.current) {
      maxRowsLengthRef.current = collaborators.length;
    }
  }, [collaborators]);

  // Use effect hook to set scroll position after state update
  useEffect(() => {
    if (tableContainerRef.current) {
      const current = tableContainerRef.current as HTMLDivElement;
      current.scrollTop = scrollPosition;
    }
  }, [isCheck]);

  const colHeaders = [
    "",
    t("general.firstname"),
    t("general.lastname"),
    t("general.email"),
    t("general.projectGroups"),
    "",
  ];

  const rows = filteredCreators.map((collaborator, i: number) => {
    const hasAccessToEverything = collaborator.permissions.length === projectGroups.length;
    const allowedItems: IAllowedItem[] = [];

    collaborator.permissions.forEach((p) => {
      if (p.project_group) {
        allowedItems.push({ name: p.project_group.name });
      }
    });

    return {
      checkbox: (
        <Checkbox
          checked={isCheck.includes(String(collaborator.id))}
          key={`${i}-${collaborator.id}`}
          id={String(collaborator.id)}
          value={collaborator.id}
          onClick={(e) => {
            e.stopPropagation();
            handleClick(e);
          }}
        />
      ),
      avatar: <RoundImagePreview media={collaborator.source_avatar} />,
      firstName: collaborator.firstname,
      lastName: collaborator.lastname,
      email: collaborator.email,
      projectGroups: (
        <DisplayAllowedItems
          itemsAreProjectGroups
          hasAccessToEverything={hasAccessToEverything}
          allowedItems={allowedItems}
        />
      ),
      crudButtons: (
        <UserCrudButtons
          onClickEdit={() => {
            dispatch(
              fetchUserDetails({
                ...collaborator,
                componentId: CREATE_OR_UPDATE_USER_AS_ADMIN_PERMISSIONS_LIST_COMPONENT,
              }),
            );
            navigate(`${collaborator.id}`);
          }}
          onClickDelete={() => {
            dispatch(
              setDeleteUsersDraft({
                type: "delete_creator",
                usersToBeDeleted: [{ id: collaborator.id, email: collaborator.email }],
              }),
            );
            dispatch(openConfirmationModal("confirm-delete-users"));
          }}
        />
      ),
    };
  });

  return (
    <>
      <div className="flex flex-col items-center w-full h-full overflow-y-auto personalize-scroll-visible">
        <div className="flex mt-4 w-[96%] justify-between items-end">
          <Dropdown
            label={`${t("general.projectGroup")} :`}
            options={projectGroups.map((pg) => {
              return {
                value: String(pg.id),
                optionText: pg.name,
              };
            })}
            onChangeCb={(e: any) => {
              setSelectedProjectGroupId(e.target.value);
            }}
          />
          <ButtonIcon
            icon="PlusIcon"
            styled="btn-primary-fill h-2/3"
            text={t("pages.collaborators.addNewCollaborator")}
            onClick={() => navigate("/collaborators/new")}
          />
        </div>
        <DeleteElementsSelectedFromList
          className="self-start ml-12 mt-2"
          isCheck={isCheck}
          selectedTextSingular={t("pages.participants.selectedUserSingular")}
          selectedTextPlurial={t("pages.participants.selectedUserPlurial")}
          deleteButtonTextSingular={t("pages.participants.deleteSelectedUserSingular")}
          deleteButtonTextPlurial={t("pages.participants.deleteSelectedUserPlural")}
          deleteCallback={() => {
            dispatch(
              setDeleteUsersDraft({
                type: isCheck.length > 1 ? "delete_creators" : "delete_creator",
                usersToBeDeleted: collaborators.filter((c) => isCheck.includes(String(c.id))),
              }),
            );
            dispatch(openConfirmationModal("confirm-delete-users"));
          }}
        />
        <IsLoading componentId={COLLABORATORS_LIST_COMPONENT} showSuccess={false}>
          <IsLoading componentId={COLLABORATORS_TABLE_COMPONENT} showSuccess={false}>
            <IsLoading componentId={ANOTHER_COLLABORATORS_TABLE_COMPONENT} showSuccess={false}>
              <TableComp
                key={`${tableHeight}${tableKey}`}
                style={{ maxHeight: `${tableHeight}px` }} // Long story short, Tailwind doesn't do well with dynamic class values
                className="w-[96%] mt-2 mb-2"
                colHeaders={colHeaders}
                rows={rows}
                disableCriteria={() => false}
                isAllCollaborators={true}
                isCollaboratorAndParticipant={true}
                setListOfItemsFilter={setFilteredCreators}
                listTofilter={filteredCreators}
                checkAll={
                  <CheckAll
                    className={filteredCreators.length === 0 ? "hidden" : ""}
                    checked={isCheck.length > 0}
                    onChange={handleSelectAll}
                  />
                }
                isUserList={true}
                selectFromListInTable={true}
                emptyPlaceholder={
                  <div className="flex m-8 items-center justify-center">
                    {maxRowsLengthRef.current
                      ? t("pages.collaborators.noCollaboratorMatchTheseFilters")
                      : t("pages.collaborators.noCollaboratorCTA")}
                  </div>
                }
              />
            </IsLoading>
          </IsLoading>
        </IsLoading>
      </div>
    </>
  );
};

export default CollaboratorsList;
