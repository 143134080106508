import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { setFindMediaFilenames } from "../state/gallerySlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { BiSearchAlt2 } from "react-icons/bi";
import { useEffect, useState } from "react";

export const SearchMediaTitle = (mediaForSearchTitle: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [inputBorder, setInputBorder] = useState(false);

  const items = mediaForSearchTitle.mediaForSearchTitle.map((item: any) => {
    return {
      id: item.id,
      name: item.name,
    };
  });

  const handleOnClear = () => {
    dispatch(setFindMediaFilenames(""));
  };

  const handleOnSearch = (item: any) => {
    dispatch(setFindMediaFilenames(item ?? ""));
  };

  const handleOnSelect = (item: any) => {
    dispatch(setFindMediaFilenames(item.name));
  };

  const formatResult = (item: any) => {
    return (
      <>
        <span style={{ display: "block", textAlign: "left" }}>{item.name}</span>
      </>
    );
  };
  useEffect(() => {
    dispatch(setFindMediaFilenames(""));
  }, []);

  return (
    <div className="search-filter" style={{ marginBottom: "0.4rem" }}>
      <p className="text-gray-500 font-medium mb-2 align-bottom">{t("general.search")}</p>
      <div className="2xl:w-60 w-[9.188rem]" onBlur={() => setInputBorder(false)}>
        <ReactSearchAutocomplete
          items={items}
          onClear={handleOnClear}
          onSelect={handleOnSelect}
          onSearch={handleOnSearch}
          autoFocus
          onFocus={() => setInputBorder(true)}
          formatResult={formatResult}
          showIcon={false}
          placeholder={t("general.fileName")}
          styling={{
            borderRadius: "6px",
            iconColor: "#00c889",
            clearIconMargin: "10px 30px 10px 10px",
            boxShadow: "none",
            border: inputBorder ? "2px solid #d4d5d8" : "1px solid rgba(0, 0, 0, 0.07)",
            height: "2.8125rem",
            placeholderColor: "#ccd7e0",
          }}
          showNoResultsText={t("general.noResults")}
        />
      </div>
      <BiSearchAlt2 className="absolute ml-2 top-11 left-28 2xl:left-[12.6rem] w-6 h-6 fill-gray-400" />
    </div>
  );
};
