import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getCurrentProjectGroupForSearch,
  setFilterProjectGroup,
} from "../../../features/projectGroups/state/projectGroupsSlice";
import { Dropdown } from "../Input";
import { useForm } from "react-hook-form";

export interface IProjectGroupsFilter {
  order: "date_asc" | "date_desc" | "size_asc" | "size_desc";
}
import { useEffect } from "react";

export const ProjectGroupFilter = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const projectGroupNoUpdated = useSelector(getCurrentProjectGroupForSearch);

  const { register, watch, setValue } = useForm<any>({
    defaultValues: {
      order: "date_desc",
    },
  });
  const watchFields = watch();

  useEffect(() => {
    if (watchFields.order !== "") {
      const forGroupFilterSearch = {
        type: watchFields.order,
        actualGroupList: projectGroupNoUpdated,
      };
      dispatch(setFilterProjectGroup(forGroupFilterSearch));
    }
  }, [watchFields.order, projectGroupNoUpdated]);

  return (
    <div className="mb-5 ml-2 pt-13">
      <div className="space-y-4">
        <Dropdown
          borderOff
          options={[
            { value: "date_desc", optionText: t("general.date_desc") },
            { value: "date_asc", optionText: t("general.date_asc") },
            { value: "alphanum_desc", optionText: t("general.alphanum_desc") },
            { value: "alphanum_asc", optionText: t("general.alphanum_asc") },
          ]}
          label=""
          registerFct={() => register("order")}
          onChangeCb={(value: any) => setValue("order", value)}
          asDefaultValue={true}
        />
      </div>
    </div>
  );
};
