import { useEffect, useState } from "react";
import { Dropdown, Input } from "src/common/components/Input";
import TextArea from "src/common/components/Input/TextArea";
import {
  IBlockadeLabsModel,
  addBlockadeImageToDatabase,
  generateBlockadeLabsImagePreview,
  getBlockadeLabsPreview,
  resetBlockadeLabsImagePreview,
  setCurrentMediaGallery,
} from "../state/gallerySlice";
import { useAppDispatch } from "src/common/state/hooks";
import { useTranslation } from "react-i18next";
import { IBlockadeLabsPreview } from "../../gallery/state/gallerySlice";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Spinner from "src/common/components/Loader/Spinner";
import { setAlert } from "src/features/alert/state/alertsSlice";
import { IMedia } from "src/model/unityObject";
import { fromUrlReturnMD5AndSize } from "../state/uploadUtil";
import { IsLoading } from "src/common/components/AppState/IsLoading";
import { FETCH_AI_MODELS_FROM_BLOCKADE_LABS } from "./GenerateMediaPanel";

export const GENERATE_BLOCKADE_IMAGE_COMPONENT = "generateBlockadeImageComponent";

export const GenerateBlockadeLabsImage = ({
  models = [],
  closeSliderAnimation,
}: {
  models: IBlockadeLabsModel[];
  closeSliderAnimation(): any;
}) => {
  useEffect(() => {
    const defaultModel = [...models].find((m) => m.id === 90) || {
      id: 90,
      name: "M3 Neo Tokyo",
      "max-char": 600,
      image:
        "https://images.blockadelabs.com/images/skybox/O0wGV3yQ5aVDytx1neT8BqPLnXyJuX7yR2K6Oy50.webp",
    };
    setDefaultModel(defaultModel);
    setSelectedModel(defaultModel);
  }, [models]);

  const [defaultModel, setDefaultModel] = useState(
    [...models].find((m) => m.id === 90) || {
      id: 90,
      name: "M3 Neo Tokyo",
      "max-char": 600,
      image:
        "https://images.blockadelabs.com/images/skybox/O0wGV3yQ5aVDytx1neT8BqPLnXyJuX7yR2K6Oy50.webp",
    },
  );
  const [selectedModel, setSelectedModel] = useState(
    [...models].find((m) => m.id === 90) || {
      id: 90,
      name: "M3 Neo Tokyo",
      "max-char": 600,
      image:
        "https://images.blockadelabs.com/images/skybox/O0wGV3yQ5aVDytx1neT8BqPLnXyJuX7yR2K6Oy50.webp",
    },
  );
  const [prompt, setPrompt] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const [hasGenerateRequestBeenSent, setGenerateRequestHasBeenSent] = useState(false);
  const [tagEditorKey, setTagEditorKey] = useState(0);
  const preview = useSelector(getBlockadeLabsPreview) as IBlockadeLabsPreview;

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { register, handleSubmit } = useForm<{ name: string }>({
    defaultValues: {
      name: "",
    },
  });

  const handlePromptChange = (e) => {
    setPrompt(e.target.value);
  };

  const handleModelChange = (event) => {
    const newModel = models.find((model) => model.id === parseInt(event.target.value, 10));
    setSelectedModel(newModel || defaultModel);
  };

  const generatePreview = () => {
    setIsGenerating(true);
    setGenerateRequestHasBeenSent(true);
    const payload = {
      modelId: selectedModel.id,
      prompt,
      componentId: GENERATE_BLOCKADE_IMAGE_COMPONENT,
    };
    dispatch(generateBlockadeLabsImagePreview(payload));
  };

  useEffect(() => {
    if (isGenerating) {
      if (preview && preview?.thumbnailUrl) {
        setIsGenerating(false);
      }
    }
    if (preview && preview?.faultyImageId) {
      // something went wrong with Blockade API; reset state
      cancelPreview();
      setSelectedModel(defaultModel);
      alert(t("error.blockadeLabsApiError"));
    }
  }, [preview]);

  useEffect(() => {
    cancelPreview();
  }, []);

  const saveImage = async (formData) => {
    try {
      const { name } = formData; // Destructure to get the name from the form data
      const md5AndSize = await fromUrlReturnMD5AndSize(preview.imageUrl);
      const result = await addBlockadeImageToDatabase({
        name: name,
        imageUrl: preview.imageUrl,
        thumbnailUrl: preview.thumbnailUrl,
        fileSizeInByte: md5AndSize.size,
        fileName: preview.imageUrl,
        MD5: md5AndSize.MD5,
        dispatch,
      }).then((res: IMedia) => {
        dispatch(setCurrentMediaGallery(res.id));
        cancelPreview();
        dispatch(
          setAlert({
            type: "success",
            msg: t("pages.gallery.generateMediaPanel.uploadBlockadeImageSuccess"),
          }),
        );

        closeSliderAnimation();
      });
      console.log("Image added to database:", result);
    } catch (error) {
      console.error("Error saving image:", error);
    }
  };

  const cancelPreview = () => {
    // Reset other states as needed
    dispatch(resetBlockadeLabsImagePreview());
    setIsGenerating(false);
    setTagEditorKey(tagEditorKey + 1);
    setGenerateRequestHasBeenSent(false);
  };

  return (
    <div>
      <h3 className={"font-medium text-lg mb-6  mt-2"}>
        {t("pages.gallery.generateMediaPanel.generate360Image")}
      </h3>
      {!preview?.thumbnailUrl && (
        <div>
          <IsLoading
            componentId={FETCH_AI_MODELS_FROM_BLOCKADE_LABS}
            spinnerPlaceholder
            showSuccess={false}
          >
            <Dropdown
              options={models.map((model) => ({ value: String(model.id), optionText: model.name }))}
              asDefaultValue
              defaultValue={defaultModel.id}
              defaultValueIndex={models.findIndex((m) => m.id === 90)}
              label={t("pages.gallery.generateMediaPanel.selectAModel")}
              labelFontClassName={"font-medium"}
              value={selectedModel.id}
              onChange={handleModelChange}
              className="mb-4"
              noStatsAvailable={isGenerating} // beautifully named props ikr
            />
            {selectedModel && selectedModel?.id !== 0 && (
              <>
                <div className="font-medium text-gray-700 mt-4">
                  <p>{t("pages.gallery.generateMediaPanel.stylePreview")}</p>
                  <img src={selectedModel.image} alt={selectedModel.name} className="my-2" />
                </div>
                <div className="font-medium text-gray-700 mt-4">
                  <p>{t("pages.gallery.generateMediaPanel.writeYourPrompt")}</p>
                  <TextArea
                    className="mt-4"
                    placeholder={t("pages.gallery.generateMediaPanel.writeYourPrompt")}
                    maxLength={selectedModel["max-char"]}
                    value={prompt}
                    onChange={handlePromptChange}
                    disabled={isGenerating}
                  />
                  {prompt.length === selectedModel["max-char"] && (
                    <span className="text-red-600 text-sm mt-2">{t("alerts.charactersMax")}</span>
                  )}
                </div>
              </>
            )}
          </IsLoading>
        </div>
      )}
      {!hasGenerateRequestBeenSent ? (
        <button
          disabled={!selectedModel || !prompt || isGenerating}
          className={`btn-primary-fill mr-3 mt-2 ${
            selectedModel.id === 0 || prompt.length === 0 ? "cursor-not-allowed" : ""
          }`}
          onClick={generatePreview}
        >
          {t("pages.gallery.generateVoicePanel.generateButton")}
        </button>
      ) : preview?.thumbnailUrl ? (
        <div className="mt-4">
          <form onSubmit={handleSubmit(saveImage)}>
            <div className="block font-medium text-gray-700 ">{t("general.fileName")}</div>
            <Input
              className="mb-4"
              name="name"
              type="text"
              maxLength={30}
              placeholder={t("general.fileName")}
              autoFocus
              required
              registerFct={() => register("name", { required: "Name is required" })}
            />

            <div>
              <p className="font-medium text-gray-700 mt-4">
                {t("pages.gallery.generateMediaPanel.yourGeneratedImage")}
              </p>
              <img src={preview.thumbnailUrl} />
            </div>

            <button className={"btn-primary-fill mr-3 mt-2"} type="submit">
              {t("general.save")}
            </button>
            <button
              className={"btn-primary-outline mr-3 mt-2"}
              onClick={cancelPreview}
              type="button"
            >
              {t("general.cancel")}
            </button>
          </form>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};
