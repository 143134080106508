import { Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IsLoading } from "src/common/components/AppState/IsLoading";
import { ButtonIcon } from "src/common/components/Button";
import { Checkbox, Dropdown } from "src/common/components/Input";
import CheckAll from "src/common/components/UsersTable/CheckAll";
import {
  DisplayAllowedItems,
  IAllowedItem,
} from "src/common/components/UsersTable/DisplayAllowedItems";
import { UserCrudButtons } from "src/common/components/UsersTable/UserCrudButtons";
import { useAppDispatch } from "src/common/state/hooks";
import { getAllProjectGroupsWithNestedElements } from "src/features/projectGroups/state/projectGroupsSlice";
import {
  fetchUserDetails,
  getEndusers,
  getTeamAsAdmin,
  setDeleteUsersDraft,
} from "src/features/team/state/teamSlice";
import { SearchAutocomplete } from "src/common/components/SearchAutocomplete/SearchAutocomplete";
import { DeleteElementsSelectedFromList } from "src/common/components/DeleteElementsSelectedFromList/DeleteElementsSelectedFromList";
import { useNavigate } from "react-router-dom";
import {
  getConfirmationModalStateShow,
  openConfirmationModal,
} from "src/common/state/slice/modal/modalSlice";
import { CREATE_OR_UPDATE_USER_AS_ADMIN_PERMISSIONS_LIST_COMPONENT } from "src/common/components/CreateOrUpdateUserAsAdmin/CreateOrUpdateUserAsAdminPermissionsList";
import SimplifiedUserIcon from "../../assets/simplified-user-round-marker.png";
import RoundImagePreview from "src/features/projects/components/RoundImagePreview";
import TableComp from "src/common/components/Table/Table";
import { IProject } from "src/model/model";

// Without having several const, given two concurrent API calls, there will be a race condition causing the component to render
// (incorrect datas) once the quickest API call returns. Here it happened with the "GET" permissions being quicker than the "PUT" permissions
// while sharing a single spinner.
export const ENDUSERS_LIST_COMPONENT = "endusersListComponent";
export const PARTICIPANTS_TABLE_COMPONENT = "participantsTableComponent";
export const ANOTHER_PARTICIPANTS_TABLE_COMPONENT = "anotherParticipantsTableComponent";

const ParticipantsList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const tableContainerRef = useRef(null);
  const navigate = useNavigate();
  const projectGroups = useSelector(getAllProjectGroupsWithNestedElements);
  const projectsThatDontBelongToAnonymousProjectGroup = [] as IProject[];

  // Useful to determine if any filter has been applied
  const maxRowsLengthRef = useRef(0);

  projectGroups.forEach((pg) => {
    if (pg.auth_type !== "none") {
      [...pg.project_group_elements]
        .sort((a, b) => a.order - b.order)
        .forEach((pge) => {
          projectsThatDontBelongToAnonymousProjectGroup.push(pge.project as IProject);
        });
    }
  });

  const endusers = useSelector(getEndusers);
  const confirmationModalStateShow = useSelector(getConfirmationModalStateShow);

  const [filteredEndusers, setFilteredEndusers] = useState(endusers);
  const [isCheck, setIsCheck] = useState<Array<any>>([]);

  const [filterByProjectGroup, setFilterByProjectGroup] = useState("0");
  const [filterByProject, setFilterByProject] = useState("0");
  const [filterByText, setFilterByText] = useState("");
  const [scrollPosition, setScrollPosition] = useState(0);
  // We're computing table max height manually to avoid a lot of CSS headaches *PTSD images of Vietnam and failing scrollbars *
  const [tableHeight, setTableHeight] = useState(0);

  // Update the state when the store was updated (typically, by an API call)
  useEffect(() => {
    setFilteredEndusers(endusers);
    setIsCheck([]);
    if (endusers.length > maxRowsLengthRef.current) {
      maxRowsLengthRef.current = endusers.length;
    }
  }, [endusers]);

  // FILTERS logic below
  const handleOnSearch = (string: string) => {
    // We reset selection on search. Not doing this leads to several clunky UX behaviours that would need to be adressed.
    setIsCheck([]);
    if (string === "") {
      setFilterByText("");
    }
  };

  const handleOnSelect = (item: { name: string }) => {
    // the item selected
    setFilterByText(item.name);
  };

  useEffect(() => {
    let filteredUsers = endusers;

    if (filterByProjectGroup !== "0") {
      const selectedProjectGroupProjects = projectGroups.find(
        (pg) => String(pg.id) === String(filterByProjectGroup),
      );

      filteredUsers = filteredUsers.filter((user) => {
        if (!selectedProjectGroupProjects) {
          return false;
        }
        const projectIdsInGroup = [...selectedProjectGroupProjects.project_group_elements]
          .sort((a, b) => a.order - b.order)
          .map((pge) => String(pge.project.id));

        return user.permissions.find((p) => {
          const userProjectId = String(p?.project ? p.project.id : "");
          return projectIdsInGroup.includes(userProjectId);
        });
      });
    }

    if (filterByProject !== "0") {
      filteredUsers = filteredUsers.filter((user) => {
        const allowedUserProjectIds = user.permissions.map((p) => String(p.project?.id));
        return allowedUserProjectIds.includes(filterByProject);
      });
    }

    if (filterByText) {
      filteredUsers = filteredUsers.filter((user) =>
        user.email.toLowerCase().includes(filterByText.toLowerCase()),
      );
    }

    setFilteredEndusers(filteredUsers);
    if (filteredUsers.length > maxRowsLengthRef.current) {
      maxRowsLengthRef.current = filteredUsers.length;
    }
  }, [filterByProjectGroup, filterByProject, filterByText, endusers]);

  useEffect(() => {
    const calculateHeight = () => {
      const divinaProportione = Math.round(window.innerHeight * (2 / 3));
      setTableHeight(divinaProportione);
    };

    calculateHeight();

    window.addEventListener("resize", calculateHeight);
    return () => {
      window.removeEventListener("resize", calculateHeight);
    };
  }, []);

  // This avoids the problems caused by the "delete multiple" button staying accessible after the "delete multiple users confirmation modal" have been clicked
  useEffect(() => {
    if (!confirmationModalStateShow) {
      setIsCheck([]);
    }
  }, [confirmationModalStateShow]);

  const handleSelectAll = () => {
    if (isCheck.length > 0) {
      setIsCheck([]);
    } else {
      setIsCheck(filteredEndusers.map((li) => String(li.id)));
    }
  };

  const handleClick = (e: any) => {
    const { id, checked } = e.target;

    // Save scroll position before state update
    if (tableContainerRef.current) {
      const current = tableContainerRef.current as HTMLDivElement;
      setScrollPosition(current.scrollTop);
    }

    // State update logic
    if (checked) {
      const nextValues = [...isCheck, id];
      setIsCheck(nextValues);
    } else {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  // Use effect hook to set scroll position after state update
  useEffect(() => {
    if (tableContainerRef.current) {
      const current = tableContainerRef.current as HTMLDivElement;
      current.scrollTop = scrollPosition;
    }
  }, [isCheck]);

  useEffect(() => {
    dispatch(getTeamAsAdmin({ componentId: ENDUSERS_LIST_COMPONENT }));
  }, []);

  const colHeaders = [
    "",
    t("general.firstname"),
    t("general.lastname"),
    t("general.identifier"),
    t("general.projects"),
    "",
  ];

  const rows = filteredEndusers
    // Dropdown filter(s) are applied in .filter() below:
    .map((enduser, i: number) => {
      const hasAccessToEverything = enduser.permissions.length === projectGroups.length;
      const enduserIsSimplified = !enduser.email.includes("@");
      const allowedItems: IAllowedItem[] = [];
      enduser.permissions.forEach((p) => {
        if (p.project?.name) {
          allowedItems.push({ name: p.project.name, published: p.project.status === "Published" });
        }
      });

      return {
        checkbox: (
          <Checkbox
            checked={isCheck.includes(String(enduser.id))}
            key={`${i}-${enduser.id}`}
            id={`${enduser.id}`}
            value={Number(enduser.id)}
            onClick={(e) => {
              e.stopPropagation();
              handleClick(e);
            }}
          />
        ),
        avatar: <RoundImagePreview media={enduser.source_avatar} />,
        firstName: !enduserIsSimplified ? (
          enduser.firstname
        ) : (
          <div className="flex relative">
            <Tooltip title={t("general.userWithSimplifiedLogin")}>
              <div className="absolute -left-8 top-[2px] flex items-center justify-center rounded-full bg-blue-500 text-white">
                <img src={SimplifiedUserIcon} className="w-4 h-4" />
              </div>
            </Tooltip>
            <span> {enduser.firstname}</span>
          </div>
        ),
        lastName: enduser.lastname,
        email: enduser.email,
        projectGroups: (
          <DisplayAllowedItems
            itemsAreProjectGroups
            hasAccessToEverything={hasAccessToEverything}
            allowedItems={allowedItems}
          />
        ),
        crudButtons: (
          <UserCrudButtons
            editDisabled={enduserIsSimplified}
            editDisabledTooltip={t("pages.participants.simplifiedUsersCannotBeEdited")}
            onClickEdit={() => {
              dispatch(
                fetchUserDetails({
                  ...enduser,
                  componentId: CREATE_OR_UPDATE_USER_AS_ADMIN_PERMISSIONS_LIST_COMPONENT,
                }),
              );
              navigate(`${enduser.id}`);
            }}
            onClickDelete={() => {
              dispatch(
                setDeleteUsersDraft({
                  type: "delete_enduser",
                  usersToBeDeleted: [{ id: enduser.id, email: enduser.email }],
                }),
              );
              dispatch(openConfirmationModal("confirm-delete-users"));
            }}
          />
        ),
      };
    });

  return (
    <>
      <div className="flex flex-col items-center w-full h-full">
        <div className="flex mt-4 w-[96%] justify-between items-end">
          <Dropdown
            label={`${t("general.projectGroup")} :`}
            options={projectGroups
              .filter((pg) => {
                return pg.auth_type !== "none";
              })
              .map((pg) => {
                return {
                  value: String(pg.id),
                  optionText: pg.name,
                };
              })}
            onChangeCb={(e: any) => {
              setFilterByProjectGroup(e.target.value);
              setFilterByProject("0");
              setIsCheck([]);
            }}
          />
          <Dropdown
            label={`${t("general.project")} :`}
            options={projectsThatDontBelongToAnonymousProjectGroup
              // If there is a project-group selected in the first filter, only display the projects inside this PG. Else display all
              .filter((project) => {
                if (filterByProjectGroup === "0") {
                  return true;
                }
                const selectedProjectGroupProjects = projectGroups.find(
                  (pg) => String(pg.id) === String(filterByProjectGroup),
                );
                return (
                  selectedProjectGroupProjects &&
                  selectedProjectGroupProjects.project_group_elements.find(
                    (pge) => String(pge.project.id) === String(project.id),
                  )
                );
              })
              .map((project) => {
                return {
                  value: String(project.id),
                  optionText: project.name,
                };
              })}
            onChangeCb={(e: any) => {
              setFilterByProject(e.target.value);
              setIsCheck([]);
            }}
          />
          <SearchAutocomplete
            items={filteredEndusers.map((user) => {
              return { name: user.email };
            })}
            handleOnSearch={handleOnSearch}
            handleOnSelect={handleOnSelect}
          />
          <ButtonIcon
            icon="PlusIcon"
            styled="btn-primary-fill h-2/3"
            text={t("pages.participants.addNewParticipants")}
            onClick={() => navigate("/participants/new")}
          />
        </div>
        <DeleteElementsSelectedFromList
          className="self-start ml-12 mt-2"
          isCheck={isCheck}
          selectedTextSingular={t("pages.participants.selectedUserSingular")}
          selectedTextPlurial={t("pages.participants.selectedUserPlurial")}
          deleteButtonTextSingular={t("pages.participants.deleteSelectedUserSingular")}
          deleteButtonTextPlurial={t("pages.participants.deleteSelectedUserPlural")}
          deleteCallback={() => {
            dispatch(
              setDeleteUsersDraft({
                type: isCheck.length > 1 ? "delete_endusers" : "delete_enduser",
                usersToBeDeleted: endusers.filter((c) => isCheck.includes(String(c.id))),
              }),
            );
            dispatch(openConfirmationModal("confirm-delete-users"));
          }}
        />
        <IsLoading componentId={ENDUSERS_LIST_COMPONENT} showSuccess={false} spinnerPlaceholder>
          <IsLoading
            componentId={PARTICIPANTS_TABLE_COMPONENT}
            showSuccess={false}
            spinnerPlaceholder
          >
            <IsLoading
              componentId={ANOTHER_PARTICIPANTS_TABLE_COMPONENT}
              showSuccess={false}
              spinnerPlaceholder
            >
              <TableComp
                key={tableHeight}
                style={{ maxHeight: `${tableHeight}px` }} // Long story short, Tailwind doesn't do well with dynamic class values
                className="w-[96%] mt-2 mb-2 personalize-scroll-visible"
                colHeaders={colHeaders}
                rows={rows}
                disableCriteria={() => false}
                checkAll={
                  <CheckAll
                    className={filteredEndusers.length === 0 ? "hidden" : ""}
                    checked={isCheck.length > 0}
                    onChange={handleSelectAll}
                  />
                }
                listTofilter={filteredEndusers}
                selectFromListInTable
                setListOfItemsFilter={setFilteredEndusers}
                isUserList={true}
                emptyPlaceholder={
                  <div className="flex m-8 items-center justify-center">
                    {maxRowsLengthRef.current
                      ? t("pages.participants.noParticipantMatchTheseFilters")
                      : t("pages.participants.noParticipantCTA")}
                  </div>
                }
              />
            </IsLoading>
          </IsLoading>
        </IsLoading>
      </div>
    </>
  );
};

export default ParticipantsList;
