import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Input from "../../common/components/Input/Input";
import { useForm } from "react-hook-form";
import { INewProjectFormValues } from "../../features/projects/state/projectsSlice";
import { useAppDispatch } from "../../common/state/hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../common/state/store";
import {
  getEditedProjectGroup,
  getNewProjectGroup,
  setEditedName,
  setNewProjectGroup,
} from "../../features/projectGroups/state/projectGroupsSlice";
import { FooterButtons } from "../../common/components/FooterButtons";
import isStringLegalProjectName from "../../common/util/isStringLegalProjectName";

interface ICreateProjectGroupName {
  incrementStep(): void;
  editMode?: boolean;
}

const CreateProjectGroupName = ({ incrementStep, editMode = false }: ICreateProjectGroupName) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [inputValueNewProjectGroup, setinputValueNewProjectGroup] = useState<string>("");
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<INewProjectFormValues>();

  const { newProjectGroup, editedProjectGroup } = useSelector((state: RootState) => {
    return {
      newProjectGroup: getNewProjectGroup(state),
      editedProjectGroup: getEditedProjectGroup(state),
    };
  });

  const onSubmit = (data: INewProjectFormValues) => {
    if (isStringLegalProjectName(inputValueNewProjectGroup)) {
      if (editMode) {
        dispatch(setEditedName(data.newName));
      } else {
        dispatch(setNewProjectGroup({ ...newProjectGroup, newName: data.newName }));
      }
      incrementStep();
    }
  };

  useEffect(() => {
    setValue("newName", editMode ? editedProjectGroup.name : newProjectGroup.newName);
  }, []);

  return (
    <>
      <h1 className="text-2xl my-5 mx-6 font-medium">
        {editMode
          ? t("pages.editProjectGroupName.pleaseRename")
          : t("pages.createProjectGroup.projectGroupName")}
      </h1>
      <form className="flex flex-col grow w-full justify-between" onSubmit={handleSubmit(onSubmit)}>
        <div className="mx-6 max-w-md">
          <Input
            autoFocus
            type="text"
            placeholder={t("pages.createProjectGroup.namePlaceholder")}
            registerFct={() =>
              register("newName", {
                required: true,
                maxLength: 24,
                onChange(event) {
                  setinputValueNewProjectGroup(event.target.value);
                },
              })
            }
          />

          {errors.newName?.type === "maxLength" && (
            <span className="text-red-600 text-sm mt-2">{t("alerts.maxCharacters")}</span>
          )}
          {isStringLegalProjectName(inputValueNewProjectGroup) === false && (
            <span className="text-red-600 text-sm mt-2">
              {t("alerts.wrongProjectGroupNameFormat")}
            </span>
          )}
          {errors.newName?.type === "required" && (
            <span className="text-red-600 text-sm mt-2">
              {t("pages.createProjectGroup.nameProjectGroupValidation")}
            </span>
          )}
        </div>

        <FooterButtons
          cancelText={t("general.cancel")}
          cancelCb={() => navigate(-1)}
          backText={t("general.back")}
          backDisabled
          nextText={t("general.next")}
        />
      </form>
    </>
  );
};

export default CreateProjectGroupName;
