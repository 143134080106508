import PlaceholderWithIcon from "../components/PlaceholderWithIcon/PlaceholderWithIcon";
import { useTranslation } from "react-i18next";
import { ButtonSquare } from "../components/Button";
import { useNavigate } from "react-router-dom";
import { getSelectedProjectGroup } from "../../features/projectGroups/state/projectGroupsSlice";
import { useSelector } from "react-redux";
import { NoMediaPlaceholder } from "src/features/gallery/components/NoMediaPlaceholder";

interface INoItemsList {
  isModeProjectList: boolean;
  isModeMediaList: boolean;
}

const NoItemsList = ({ isModeProjectList, isModeMediaList }: INoItemsList) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const selectedProjectGroup = useSelector(getSelectedProjectGroup);
  const textModeProjectList = {
    title: t("pages.project.noProjectTitle"),
    body: t("pages.project.noProjectBody"),
    link: `/project-groups/${selectedProjectGroup.id}/new`,
    buttonText: t("pages.project.newProject"),
  };
  const textModeProjectGroupList = {
    title: t("pages.project.noProjectGroupTitle"),
    body: t("pages.project.noProjectGroupBody"),
    link: "/project-groups/new",
    buttonText: t("general.newProjectGroup"),
  };
  return (
    <div>
      {isModeMediaList ? (
        <div className="px-6 mt-6">
          <NoMediaPlaceholder />
        </div>
      ) : (
        <div className="block px-6 w-full py-6">
          <div className="inline-flex justify-between w-full">
            <div className="mr-6">
              <ButtonSquare
                variant="btn-primary-fill"
                iconColor="stroke-white"
                onClick={() =>
                  navigate(
                    isModeProjectList ? textModeProjectList.link : textModeProjectGroupList.link,
                  )
                }
                text={
                  isModeProjectList
                    ? textModeProjectList.buttonText
                    : textModeProjectGroupList.buttonText
                }
              />
            </div>
            <div className="absolute h-72 left-[40%] pt-3">
              <PlaceholderWithIcon
                title={
                  isModeProjectList ? textModeProjectList.title : textModeProjectGroupList.title
                }
                text={isModeProjectList ? textModeProjectList.body : textModeProjectGroupList.body}
                heroIcon="FolderAddIcon"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NoItemsList;
