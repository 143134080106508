import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useTranslation } from "react-i18next";

type StepperProps = {
  step: number;
  mode:
    | "newProject"
    | "newProjectFromCustomTemplate"
    | "newProjectGroup"
    | "editProject"
    | "editProjectGroup"
    | "faitTemplate"
    | "publish";
};

const StepperMUI = ({ step, mode }: StepperProps) => {
  const { t } = useTranslation();
  const newProjectSteps = [
    t("stepper.chooseTemplate"),
    t("stepper.nameproject"),
    t("pages.editProjectLanguage.selectLanguages"),
    t("general.selectThumbnail"),
  ];
  const newProjectGroupSteps = [
    t("pages.createProjectGroup.projectGroupName"),
    t("general.selectThumbnail"),
    t("general.selectTheme"),
    t("pages.createProjectGroup.projectGroupAuthType"),
  ];
  const editProjectSteps = [
    t("pages.editProjectName.renameProject"),
    t("pages.editProjectLanguage.selectLanguages"),
    t("general.selectThumbnail"),
  ];
  const editProjectGroupSteps = [
    t("pages.editProjectGroupName.renameProjectGroup"),
    t("general.selectThumbnail"),
    t("general.selectTheme"),
  ];
  const faitTemplateSteps = [t("pages.faitTemplate.nameTemplate"), t("general.selectThumbnail")];
  const newProjectFromCustomTemplate = [
    t("stepper.nameproject"),
    t("pages.editProjectLanguage.selectLanguages"),

    t("general.selectThumbnail"),
  ];
  const publishSteps = [
    t("pages.projectGroup.publish.selectProjects"),
    t("pages.projectGroup.publish.selectPlatforms"),
  ];

  const deduceSteps = (mode: string) => {
    switch (mode) {
      case "newProject":
      default:
        return newProjectSteps;
      case "newProjectGroup":
        return newProjectGroupSteps;
      case "editProject":
        return editProjectSteps;
      case "editProjectGroup":
        return editProjectGroupSteps;
      case "faitTemplate":
        return faitTemplateSteps;
      case "newProjectFromCustomTemplate":
        return newProjectFromCustomTemplate;
      case "publish":
        return publishSteps;
    }
  };

  const steps = deduceSteps(mode);

  return (
    <Box sx={{ width: "100%", marginTop: "2rem" }}>
      <Stepper activeStep={step} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default StepperMUI;
