import { Control, UseFormSetValue } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ButtonText } from "../../../common/components/Button";
import ModalConfirmation from "../../../common/components/Modal/ModalConfirmation";
import { useAppDispatch } from "../../../common/state/hooks";
import { closeConfirmationModal } from "../../../common/state/slice/modal/modalSlice";
import { IProjectTag } from "../../../model/model";
import { deleteProject, getCurrentTemplateId, INewProjectFormValues } from "../state/projectsSlice";
import TemplateCustomInput from "./TemplateCustomInput";

const TemplateCategories = ({
  tagList,
  control,
  setValue,
}: {
  setValue: UseFormSetValue<INewProjectFormValues>;
  control: Control<INewProjectFormValues, object>;
  tagList: IProjectTag[];
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const currentTemplateId = useSelector(getCurrentTemplateId);

  const sortedTaglist = tagList
    .filter((tag: IProjectTag) => {
      // Filter "custom" & "favorites" when there is none
      return tag.projects.filter((p) => !p.is_deleted).length >= 1;
    })
    // Sort so Custom template comes first, then Structure, then Gaming mechanics
    .sort((a: any, b: any) => b.id - a.id);

  return (
    <>
      {sortedTaglist.map((tag: IProjectTag, index: number) => (
        <div key={index}>
          <div className="flex mb-5">
            <h3 className="mr-4 font-medium text-lg">{t(`chooseTemplateType.${tag.slug}`)}</h3>
            <ButtonText text={""} />
          </div>
          <div
            className={
              "grid grid-cols-2 xl:grid-cols-7 md:grid-cols-4 lg:grid-cols-6 gap-6 md:gap-10 mx-auto pb-2"
            }
          >
            <TemplateCustomInput setValue={setValue} control={control} tag={tag.slug} />
          </div>
        </div>
      ))}

      <ModalConfirmation
        id="deleteTemplateConfirmation"
        onClickSubmit={() => {
          dispatch(closeConfirmationModal("deleteTemplateConfirmation"));
          dispatch(
            deleteProject({ id: currentTemplateId, componentId: `template-${currentTemplateId}` }),
          );
        }}
        title={t("pages.faitTemplate.deletionModal.title")}
        text={t("pages.faitTemplate.deletionModal.body")}
        submitButtonText={t("general.delete")}
      />
    </>
  );
};

export default TemplateCategories;
