import { configureStore } from "@reduxjs/toolkit";
import { authReducer } from "../../features/auth/state/authSlice";
import modalReducer from "./slice/modal/modalSlice";
import { appStateReducer } from "./slice/appStateSlice";
import { mediaReducer } from "../../features/gallery/state/gallerySlice";
import { loadingHandler } from "./loaderHandleMiddleware";
import { contentTypesReducer } from "./slice/contentTypesSlice";
import graphuReducer from "../../features/graph/state/graphuSlice";
import mediasLegacyReducer from "../../features/graph/state/mediasSliceLegacy";
import graphLegacyReducer from "../../features/graph/state/graphLegacy";
import projectsReducerLegacy from "../../features/graph/state/projectsSliceLegacy";
import { createLogger } from "redux-logger";
import { projectsReducer } from "../../features/projects/state/projectsSlice";
import { teamReducer } from "../../features/team/state/teamSlice";
import { profileReducer } from "../../features/profile/state/profileSlice";
import { downloadClientReducer } from "../../features/downloadClient/state/downloadClientSlice";
import { statsReducer } from "../../features/stats/state/statsSlice";
import { projectGroupReducer } from "../../features/projectGroups/state/projectGroupsSlice";
import { alertsReducer } from "../../features/alert/state/alertsSlice";
import { subscriptionReducer } from "../../features/subscription/state/subscriptionSlice";
import { buildsReducer } from "../../features/builds/state/buildsSlice";
import { enduserStatsReducer } from "../../features/enduserStats/state/enduserStatsSlice";
import { themesReducer } from "src/features/themes/state/themeSlice";

const logger = createLogger({});

export const store = configureStore({
  reducer: {
    modals: modalReducer,
    auth: authReducer,
    app: appStateReducer,
    builds: buildsReducer,
    media: mediaReducer,
    mediaLegacy: mediasLegacyReducer,
    graphu: graphuReducer,
    graphLegacy: graphLegacyReducer,
    profile: profileReducer,
    projects: projectsReducer,
    projectGroups: projectGroupReducer,
    projectLegacy: projectsReducerLegacy,
    contentTypes: contentTypesReducer,
    stats: statsReducer,
    enduserStats: enduserStatsReducer,
    team: teamReducer,
    wixarClientLatestUris: downloadClientReducer,
    alerts: alertsReducer,
    subscription: subscriptionReducer,
    themes: themesReducer,
  },
  middleware: (getDefaultMiddleware) => {
    const defaultMiddlewalu = getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ["media/resetMediaFilters"],
        // Ignore these field paths in all actions
        // ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
        // Ignore these paths in the state
        // ignoredPaths: ['items.dates'],
      },
    }).concat(loadingHandler);
    return (process.env.NODE_ENV === "development" || process.env.REACT_APP_DEBUG) &&
      !process.env.REACT_APP_NO_REDUX_LOGGER
      ? defaultMiddlewalu.concat(logger)
      : defaultMiddlewalu;
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

if (window.Cypress) {
  window.getStore = () => store;
}
