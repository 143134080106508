import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getGraphLayout } from "../../../features/graph/state/graphLegacy";
import { SidebarItemProject } from "./SidebarItem";
import { getCurrentProject } from "../../../features/projects/state/projectsSlice";
import canSaveProject from "../../../features/graph/util/canSaveProject";
import { useNavigate, useLocation } from "react-router-dom";

const ProjectSidebar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const hiddenSceneNodes = useSelector(getGraphLayout)[3];
  const currentProject = useSelector(getCurrentProject);
  const canSave = canSaveProject(currentProject);
  const location = useLocation();
  const path = location.pathname.split("/");
  const maxUnlinkedScenes = 15;

  const onMakeTemplate = () => {
    navigate(`/project-groups/${path[2]}/template/${path[4]}`);
  };

  const getTooltipText = () => {
    if (!canSave.canSave) {
      return canSave.reason ? t(canSave.reason) : t("tooltip.sceneMustHaveNameAndMedia");
    }

    if (hiddenSceneNodes.length >= maxUnlinkedScenes) {
      return t("pages.project.tooMuchUnlinkedScene");
    }

    return "";
  };

  return (
    <div className="bg-blue-500 rounded-md absolute left-12 top-80 px-2 py-3 max-w-min block space-y-2 z-10">
      <div className="onboardingHowToAddScene-step-1">
        <SidebarItemProject
          title={t("sidebar.createScene")}
          icon="VideoCameraIcon"
          routeTo="create/scene"
          disabled={hiddenSceneNodes.length >= maxUnlinkedScenes || !canSave.canSave}
          tooltipText={getTooltipText()}
        />
      </div>
      <div className="onboardingHowToAddScene-step-4">
        <SidebarItemProject
          title={t("sidebar.sound")}
          icon="MusicNoteIcon"
          routeTo="background-music/edit"
        />
      </div>

      <SidebarItemProject
        title={t("general.makeTemplate")}
        icon="SaveAsIcon"
        routeTo=""
        onClick={onMakeTemplate}
        tooltipText={t("tooltip.faitTemplate")}
      />
    </div>
  );
};

export default ProjectSidebar;
