import React, { createRef, ReactElement, useEffect, useMemo } from "react";
import { RiFullscreenLine } from "react-icons/ri";
import { Unity, useUnityContext } from "react-unity-webgl";
import { isBrowserSupportingWebGL } from "../../../common/util/isBrowserSupportingWeb";
import UnsupportedWebGL from "../../../common/components/UnsupportedWebGL/UnsupportedWebGL";
import { useTranslation } from "react-i18next";
import ProgressBar from "../../../common/components/ProgressBar/ProgressBar";

const viewerFolder = "/Player360/";
let currentUnload: (() => Promise<any>) | null = null;

interface Props {
  mediaUrl: string;
  mediaTag360: "media2d" | "media360" | "video180stereo";
  mediaType: "video" | "image";
  className?: string;
  canvasClassName?: string;
  disableFullScreen?: boolean;
}

export default function Player360({
  mediaUrl,
  mediaTag360,
  mediaType,
  className,
  canvasClassName,
  disableFullScreen = false,
}: Props): ReactElement {
  const [isLoading, setIsLoading] = React.useState(true);
  const isSupportingWebGL = useMemo(() => isBrowserSupportingWebGL(), []);
  const canvasRef = createRef<HTMLCanvasElement>();
  const { t } = useTranslation();

  const { unityProvider, sendMessage, isLoaded, loadingProgression, unload } = useUnityContext({
    loaderUrl: viewerFolder + "Build/Player360.loader.js",
    dataUrl: viewerFolder + "Build/Player360.data",
    frameworkUrl: viewerFolder + "Build/Player360.framework.js",
    codeUrl: viewerFolder + "Build/Player360.wasm",
  });

  useEffect(() => {
    currentUnload = unload;
  }, [unload]);

  const configureUnityApp = () => {
    if (mediaTag360 === "video180stereo") {
      sendMessage("VCR", "ConfigureFor180Stereo");
    }

    if (mediaType === "video") {
      sendMessage("VCR", "PlayVideo", mediaUrl);
    } else {
      sendMessage("VCR", "ShowImage", mediaUrl);
    }

    canvasRef.current?.setAttribute("tabindex", "1");
    canvasRef.current?.focus();

    setIsLoading(false);
  };

  useEffect(() => {
    return () => {
      if (currentUnload) {
        currentUnload();
      }
    };
  }, []);

  useEffect(() => {
    if (isLoaded) {
      configureUnityApp();
    }
  }, [isLoaded]);

  const toggle = () => {
    const elem = document.querySelector("#div-unity");

    if (!document.fullscreenElement) {
      elem?.requestFullscreen().catch((err) => {
        alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
      });
    } else {
      document.exitFullscreen();
    }
  };

  return (
    <div className={className ?? ""}>
      <div
        className={`w-full h-full flex flex-col justify-center items-center ${
          isLoading ? "" : "hidden"
        }`}
      >
        <p>{t("general.loading")}</p>
        <ProgressBar
          wrapperClassName="w-1/4 flex bg-gray-500 h-[3%]"
          barClassName="bg-gray-900"
          value={loadingProgression * 100}
        />
      </div>
      <div
        id="div-unity"
        className={`h-full ${isLoading ? "hidden" : "flex"} flex-col items-center`}
      >
        {isSupportingWebGL ? (
          <>
            <Unity
              unityProvider={unityProvider}
              ref={canvasRef}
              className={`${isLoading ? "hidden" : "visible"} ${canvasClassName ?? ""}`}
              style={{ height: "100%", width: "100%" }}
            />
          </>
        ) : (
          <UnsupportedWebGL />
        )}
        {isSupportingWebGL && !disableFullScreen && (
          <button
            className={`relative mr-2 mt-[-3rem] self-end btn-fullscreen ${
              isLoading ? "hidden" : "visible"
            }`}
            onClick={() => {
              toggle();
            }}
          >
            <RiFullscreenLine fill="white" size={40} />
          </button>
        )}
      </div>
    </div>
  );
}
