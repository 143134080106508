import axios from "axios";

export const setAuthToken = (token: string) => {
  axios.defaults.headers.common["Authorization"] = "";
  delete axios.defaults.headers.common["Authorization"];

  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
};

export const setAxiosResponseHandler = (alertCb: any) => {
  axios.interceptors.response.use(
    (response: any) => {
      // Check if the response has a 'data' key at the top-level object
      // and unwrap it so that you don't have to access response.data.data everywhere
      if (response.data && typeof response.data === "object" && response.data.data !== undefined) {
        // console.log(
        //   "setAxiosResponseHandler - strapi-v4 structure detected: unwrapping 'data.data' from response: ",
        //   response.data.data,
        // );
        // Modify the response to unwrap the data key
        return { ...response, data: response.data.data };
      }
      // console.log("setAxiosResponseHandler - returning response 'as is': ", response.data);
      return response;
    },
    (error) => {
      console.error("Axios interceptor error: ", error);
      return Promise.reject(alertCb(error.response));
    },
  );
};

export const setAxiosRequestHandler = () => {
  axios.interceptors.request.use(
    (config) => {
      // Check if the method is POST or PUT
      if (config.method === "post" || config.method === "put") {
        if (config.data) {
          const hasDataKey = Object.prototype.hasOwnProperty.call(config.data, "data");

          // Check if the payload is an object and not already wrapped with a "data" key
          if (config.data && typeof config.data === "object" && !hasDataKey) {
            config.data = { data: config?.data };
          }
        }
      }

      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    },
  );
};

export const isTokenExpired = (jwt: string): boolean => {
  const decodedToken: any = JSON.parse(window.atob(jwt.split(".")[1]));
  const currentDate = new Date();

  // JWT exp is in seconds
  if (decodedToken.exp * 1000 < currentDate.getTime()) {
    return true;
  }
  return false;
};
