import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SearchAutocomplete } from "src/common/components/SearchAutocomplete/SearchAutocomplete";
import { Dropdown } from "src/common/components/Input";
import { IThemeInfo } from "src/model/model";

interface ThemesViewProps {
  publicThemes: IThemeInfo[];
  privatesThemes: IThemeInfo[];
  onNewThemeClick: () => void;
  onThemeClick: (theme: IThemeInfo) => void;
  onThemeMenuClick: (item: any, index: number) => void;
  componentType: "list" | "radio";
  selectedThemeId: number | null;
  setSelectedThemeId: (id: number | null) => void;
  selectedRadioButton?: number | null;
  setSelectedRadioButton?: (id: number | null) => void;
  ThemeItemComponent: React.ComponentType<any>;
}

const ThemesView = ({
  publicThemes,
  privatesThemes,
  onNewThemeClick,
  onThemeClick,
  onThemeMenuClick,
  componentType, // can be "list" or "radio"
  selectedThemeId,
  setSelectedThemeId,
  selectedRadioButton,
  setSelectedRadioButton,
  ThemeItemComponent,
}: ThemesViewProps) => {
  const { t } = useTranslation();
  const [filteredPrivatesThemes, setFilteredPrivatesThemes] = useState<IThemeInfo[]>([]);
  const [showCollapseButton, setShowCollapseButton] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const themesContainerRef = useRef(null);

  const [filterByText, setFilterByText] = useState("");
  const [filterByOrder, setFilterByOrder] = useState("date_desc"); // ["date_desc", "date_asc", "alphanum_desc", "alphanum_asc"]

  const handleToggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const itemHeight = 208;
  const additionalHeight = componentType === "radio" ? 48 : 0;
  const allPaddingsHight = 68;
  const maxVisibleHeight = 2 * itemHeight + allPaddingsHight + additionalHeight;

  const checkContainerHeight = () => {
    if (themesContainerRef.current) {
      const containerHeight = themesContainerRef.current.getBoundingClientRect().height;
      setShowCollapseButton(containerHeight > maxVisibleHeight);
    }
  };

  useEffect(() => {
    checkContainerHeight();
    window.addEventListener("resize", checkContainerHeight);
    return () => {
      window.removeEventListener("resize", checkContainerHeight);
    };
  }, [filteredPrivatesThemes]);

  useEffect(() => {
    checkContainerHeight();
  }, [collapsed]);

  useEffect(() => {
    const filterThemes = (themesToFilter: IThemeInfo[]) => {
      let localFilteredPrivatesThemes = [...themesToFilter];

      if (filterByText !== "") {
        localFilteredPrivatesThemes = localFilteredPrivatesThemes.filter((theme) =>
          theme.name.toLowerCase().includes(filterByText.toLowerCase()),
        );
      }

      if (filterByOrder === "date_desc") {
        localFilteredPrivatesThemes = localFilteredPrivatesThemes?.sort(
          (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
        );
      }
      if (filterByOrder === "date_asc") {
        localFilteredPrivatesThemes = localFilteredPrivatesThemes?.sort(
          (a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime(),
        );
      }
      if (filterByOrder === "alphanum_desc") {
        localFilteredPrivatesThemes = localFilteredPrivatesThemes?.sort((a, b) =>
          a.name.localeCompare(b.name),
        );
      }
      if (filterByOrder === "alphanum_asc") {
        localFilteredPrivatesThemes = localFilteredPrivatesThemes?.sort((a, b) =>
          b.name.localeCompare(a.name),
        );
      }

      setFilteredPrivatesThemes(localFilteredPrivatesThemes);

      if (setSelectedRadioButton) {
        setSelectedRadioButton(
          localFilteredPrivatesThemes.length > 0
            ? localFilteredPrivatesThemes[0].id
            : publicThemes.length > 0
            ? publicThemes.sort((a, b) => a.id - b.id)[0].id
            : 0,
        );

        setSelectedThemeId(
          localFilteredPrivatesThemes.length > 0
            ? localFilteredPrivatesThemes[0].id
            : publicThemes.length > 0
            ? publicThemes.sort((a, b) => a.id - b.id)[0].id
            : 0,
        );
      }
    };

    filterThemes(privatesThemes);
  }, [privatesThemes, filterByText, filterByOrder]);

  return (
    <div className="w-full overflow-auto h-full personalize-scroll-visible">
      <div className="flex flex-col grow w-full justify-between h-full">
        <div className="whitespace-no-wrap w-full space-y-6 my-6">
          <div className="px-12 flex">
            <div className="mt-1">
              <SearchAutocomplete
                items={privatesThemes?.map((theme) => ({ name: theme.name }))}
                handleOnSearch={setFilterByText}
                handleOnSelect={(item) => setFilterByText(item.name)}
              />
            </div>
            <div className="pl-6">
              <Dropdown
                borderOff
                options={[
                  { value: "date_desc", optionText: t("general.date_desc") },
                  { value: "date_asc", optionText: t("general.date_asc") },
                  { value: "alphanum_desc", optionText: t("general.alphanum_desc") },
                  { value: "alphanum_asc", optionText: t("general.alphanum_asc") },
                ]}
                label=""
                onChange={(e) => setFilterByOrder(e.target.value)}
                value={filterByOrder}
                asDefaultValue={true}
              />
            </div>
          </div>
          <div className="w-full overflow-x-auto">
            <div className="px-12 flex w-full">
              <h3 className="flex font-medium text-lg items-center">
                {t("pages.createProjectGroup.customThemes")}
              </h3>
              {showCollapseButton && (
                <div className="flex ml-4">
                  <button
                    className="text-green-500 text-sm"
                    onClick={(e) => {
                      e.preventDefault();
                      handleToggleCollapse();
                    }}
                  >
                    {collapsed ? t("general.showMore") : t("general.showLess")}
                  </button>
                </div>
              )}
            </div>
            <div
              ref={themesContainerRef}
              className="px-12 py-6 grid gap-6 sm:gap-5 w-full personalize-scroll-visible"
              style={{
                gridTemplateColumns: "repeat(auto-fit, minmax(200px, max-content))",
                maxHeight: collapsed
                  ? `calc(2 * ${itemHeight}px + ${allPaddingsHight}px + ${additionalHeight}px + 0.1px)`
                  : "none",
                overflowY: "auto",
              }}
            >
              <div
                className="w-52 h-52 p-2 cursor-pointer relative"
                onClick={(e) => {
                  e.preventDefault();
                  onNewThemeClick();
                }}
              >
                <img
                  src="https://s3.eu-west-3.amazonaws.com/storage.wixar.io/newThemeButton.png"
                  className="w-full h-full"
                />
                <div className="flex justify-center absolute top-1/2 left-1/2 translate-y-[-50%] translate-x-[-50%] w-3/4 bg-green-500 rounded-full font-medium text-white py-2 px-3">
                  {t("pages.createProjectGroup.newTheme")}
                </div>
              </div>
              {filteredPrivatesThemes?.map((theme) => (
                <ThemeItemComponent
                  key={`chooseThemeListPrivate_${theme.id}`}
                  theme={theme}
                  onClick={onThemeClick}
                  threeDotsMenuItems={[
                    {
                      menuItemText: t("pages.themeEditor.createthemeWithThisBase"),
                      icon: "PlusIcon",
                      itemId: "createthemeWithThisBase",
                      themeId: theme.id,
                    },
                    {
                      menuItemText: t("pages.themeEditor.edit"),
                      icon: "PencilAltIcon",
                      itemId: "editTheme",
                      themeId: theme.id,
                    },
                    {
                      menuItemText: t("general.delete"),
                      icon: "TrashIcon",
                      itemId: "deleteTheme",
                      themeId: theme.id,
                    },
                  ]}
                  threeDotsMenuHandleClick={onThemeMenuClick}
                  componentType={componentType}
                  selectedThemeId={selectedThemeId}
                  setSelectedThemeId={setSelectedThemeId}
                  inputElementParams={{
                    checked: selectedRadioButton === theme.id,
                  }}
                />
              ))}
            </div>
          </div>
          <div className="w-full overflow-x-auto">
            <h3 className="px-12 font-medium text-lg">
              {t("pages.createProjectGroup.publicThemes")}
            </h3>
            <div
              className="px-12 py-6 grid gap-6 sm:gap-5 w-full"
              style={{ gridTemplateColumns: "repeat(auto-fit, minmax(200px, max-content))" }}
            >
              {publicThemes?.map((theme) => (
                <ThemeItemComponent
                  key={`chooseThemeListPublic_${theme.id}`}
                  theme={theme}
                  onClick={onThemeClick}
                  threeDotsMenuItems={[
                    {
                      menuItemText: t("pages.themeEditor.createthemeWithThisBase"),
                      icon: "PlusIcon",
                      itemId: "createthemeWithThisBase",
                      themeId: theme.id,
                    },
                  ]}
                  threeDotsMenuHandleClick={onThemeMenuClick}
                  componentType={componentType}
                  selectedThemeId={selectedThemeId}
                  setSelectedThemeId={setSelectedThemeId}
                  inputElementParams={{
                    checked: selectedRadioButton === theme.id,
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThemesView;
