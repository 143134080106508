import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { ButtonSquare, ButtonText } from "../../common/components/Button";
import StatsOverview from "../../features/stats/components/StatsOverview/StatsOverview";
import { useAppDispatch } from "../../common/state/hooks";
import { IProjectGroup } from "../../model/model";
import {
  deleteProjectGroup,
  fetchAllCurrentElements,
  getProjectGroupPermissionsFromServer,
  setCurrentProjectGroupPermissions,
  setSelectedProjectGroup,
  setSelectedProjectGroupFromId,
  getAllProjectGroupsWithNestedElements,
  IProjectGroupWithProjects,
  fetchProjectGroupsWithProjects,
} from "../../features/projectGroups/state/projectGroupsSlice";
import ProjectGroupList, {
  PROJECT_GROUP_LIST_COMPONENT_ID,
} from "../../features/projectGroups/components/ProjectGroupList";
import { getCurrentUser } from "../../features/profile/state/profileSlice";
import handleThreeDotsButtonClickForProjectsAndProjectGroups from "../../common/util/handleThreeDotsButtonClickForProjectsAndProjectGroups";
import {
  closeConfirmationModal,
  openConfirmationModal,
} from "../../common/state/slice/modal/modalSlice";
import ModalConfirmation from "../../common/components/Modal/ModalConfirmation";
import InfoButton from "../../common/components/InfoButton/InfoButton";
import OnboardingComponent from "../../common/util/OnBoardingComponent";
import stepsOnboarding from "../../common/util/OnboardingTour";

const CreatorDashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const user = useSelector(getCurrentUser);
  const projectGroupsWithNestedElements = useSelector(getAllProjectGroupsWithNestedElements);
  const [clickedProjectGroupId, setClickedProjectGroupId] = useState(0);

  const handleClick = (projectGroup: IProjectGroup) => {
    const projectGroupId = projectGroup.id;
    dispatch(
      fetchAllCurrentElements({
        projectGroupId,
        componentId: PROJECT_GROUP_LIST_COMPONENT_ID,
      }),
    );

    dispatch(getProjectGroupPermissionsFromServer({ projectGroupId })).then((res) => {
      dispatch(setCurrentProjectGroupPermissions((res.payload as any).data));
    });
    navigate(`../project-groups/${projectGroupId}/list`);

    const pgObject = projectGroupsWithNestedElements.find(
      (pg: IProjectGroupWithProjects) => Number(pg.id) === projectGroupId,
    );
    if (pgObject) {
      dispatch(setSelectedProjectGroup(pgObject));
    }
  };

  const setEditedProjectGroupId = (id: number, projectGroup: IProjectGroupWithProjects) => {
    const pg = projectGroup as IProjectGroupWithProjects;
    setClickedProjectGroupId(id);
    dispatch(
      fetchAllCurrentElements({
        projectGroupId: pg.id,
      }),
    );
    dispatch(setSelectedProjectGroup(pg));
  };

  useEffect(() => {
    const defaultAxiosJwt = axios.defaults.headers.common["Authorization"];
    if (defaultAxiosJwt) {
      dispatch(
        fetchProjectGroupsWithProjects({
          componentId: PROJECT_GROUP_LIST_COMPONENT_ID,
        }),
      );
    }
  }, [location.key]);

  return (
    <>
      <header className="bg-white shadow sticky top-0 z-50">
        <div className="mx-auto py-6 px-4 ml-4 overflow-y-auto">
          <h1 className="text-3xl font-bold text-black">
            {t("pages.dashboard.hello")} {user?.firstname}
          </h1>
        </div>
      </header>
      <OnboardingComponent
        id={"getStarted"}
        relevantArticle="getStarted"
        steps={stepsOnboarding().introOnboarding}
      />
      <div className="w-full h-full max-w-9xl mx-auto pt-2 sm:px-6 lg:px-8 overflow-y-auto personalize-scroll-visible">
        <InfoButton
          haveButtonToOnboarding
          classNameOnboarding="introOnboarding-step-6"
          relevantArticle="getStarted"
        />

        <div className="flex space-x-6 items-end">
          <h2 className="text-2xl text-black font-medium">{t("general.projectGroups")}</h2>
          <ButtonText text={t("general.viewAll")} onClick={() => navigate("project-groups")} />
        </div>

        <div className="block w-full pt-4">
          <div className="relative inline-flex justify-between items-center w-full">
            <div className="mr-6 introOnboarding-step-1 h-full">
              <ButtonSquare
                innerClassName="grid grid-rows-6 w-32 h-48"
                variant="btn-primary-fill"
                iconColor="stroke-white h-8 w-8"
                onClick={() => navigate("/project-groups/new")}
                text={t("general.newProjectGroup")}
                textClassName="text-lg"
              />
            </div>
            <ProjectGroupList
              setEditedProjectGroupId={setEditedProjectGroupId}
              projectGroupsWithNestedElements={projectGroupsWithNestedElements}
              handleClick={handleClick}
              handleThreeDotButtonClick={(e: any) => {
                setClickedProjectGroupId(e.itemId);
                dispatch(setSelectedProjectGroupFromId(e.itemId));

                dispatch(
                  fetchAllCurrentElements({
                    projectGroupId: e.itemId,
                    componentId: PROJECT_GROUP_LIST_COMPONENT_ID,
                  }),
                );
                handleThreeDotsButtonClickForProjectsAndProjectGroups({
                  payload: e,
                  deleteCallback: () => {
                    dispatch(openConfirmationModal("deleteProjectGroupConfirmation"));
                  },
                  t,
                  navigate,
                });
              }}
              simplified
            />
          </div>
        </div>
        <div className="introOnboarding-step-2 mt-8">
          <StatsOverview />
        </div>

        <ModalConfirmation
          id="deleteProjectGroupConfirmation"
          onClickSubmit={() => {
            dispatch(closeConfirmationModal("deleteProjectGroupConfirmation"));
            dispatch(
              deleteProjectGroup({
                id: clickedProjectGroupId,
                componentId: `project-group-${clickedProjectGroupId}`,
              }),
            );
          }}
          title={t("pages.projectGroup.deletionModal.title")}
          text={t("pages.projectGroup.deletionModal.body")}
          componentId={PROJECT_GROUP_LIST_COMPONENT_ID}
        />
      </div>
    </>
  );
};

export default CreatorDashboard;
