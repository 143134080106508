import { PlusIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "../../../common/components/Tooltip/Tooltip";
import { getIsAdminOfOrganization } from "../../profile/state/profileSlice";

export default function NewPublicationButton() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isAdmin = useSelector(getIsAdminOfOrganization);

  return (
    <Tooltip
      message={isAdmin ? "" : t("pages.projectGroup.publish.publishingIsAPrivilege")}
      classNameChildren="right-0"
    >
      <button
        className="btn-primary-fill"
        onClick={() => {
          navigate("./new");
        }}
        disabled={!isAdmin}
      >
        <div className="flex items-center justify-center">
          <PlusIcon className="mr-2" height={15} width={15} />
          {t("pages.projectGroup.publish.newPublication")}
        </div>
      </button>
    </Tooltip>
  );
}
