import { Outlet } from "react-router-dom";
import { getCurrentUploadsStateList } from "../../features/gallery/state/gallerySlice";
import Alert from "../components/Alert/Alert";
import Sidebar from "../components/Sidebar/Sidebar";
import UploadBar from "../components/UploadBar/UploadBar";
import { PrivatePage } from "./PrivatePage";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";

const Layout = () => {
  const currentUploadsStateList = useSelector(getCurrentUploadsStateList);
  const animationActivated = () => {
    switch (location.pathname) {
      case "/":
      case "/project-groups":
      case "/participants":
      case "/collaborators":
      case "/medias":
      case "/statistics":
      case "/profile/me":
      case "/download-client":
        return 0;
      default:
        return 1;
    }
  };

  return (
    <PrivatePage>
      <div className="fixed w-full min-h-screen">
        <div className="h-screen flex flex-col relative">
          <div
            className="flex w-full bg-slate-100"
            style={{
              height: currentUploadsStateList.length > 0 ? "calc(100% - 4rem)" : "100%",
            }}
          >
            <div className="text-center">
              <Alert />
            </div>
            <Sidebar />
            <main className="relative flex flex-col w-[calc(100%-8rem)]">
              {animationActivated() === 0 ? (
                <motion.div
                  className="relative flex flex-col w-full h-full"
                  initial={{ opacity: animationActivated() }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: animationActivated(), transition: { duration: 2 } }}
                >
                  <Outlet />
                </motion.div>
              ) : (
                <Outlet />
              )}
            </main>
          </div>
          {currentUploadsStateList.length > 0 && (
            <div id="upload-bar-overlay" className="h-[4rem] w-full bg-gray-200 fixed bottom-0">
              <UploadBar className="h-full min-w-full" />
            </div>
          )}
        </div>
      </div>
    </PrivatePage>
  );
};

export default Layout;
