import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { useAppDispatch } from "../../common/state/hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../common/state/store";
import {
  duplicateProject,
  getCurrentProject,
  getEditedProject,
  getNewProjectSelector,
  makeTemplate,
  setCurrentProject,
  updateProject,
} from "../../features/projects/state/projectsSlice";
import { DynamicMedia } from "../../features/graph/components/DynamicField";
import {
  mediaSelector,
  setMediaFilter,
  setOriginalMediaGallery,
} from "../../features/gallery/state/gallerySlice";
import { useEffect, useState } from "react";
import { IMedia } from "../../model/unityObject";
import { closeStandardModal, openStandardModal } from "../../common/state/slice/modal/modalSlice";
import ModalGallery from "../../features/graph/components/ModalGallery";
import {
  addCurrentElement,
  getCurrentElements,
  getSelectedProjectGroup,
  setCurrentElements,
} from "../../features/projectGroups/state/projectGroupsSlice";
import { IsLoading } from "../../common/components/AppState/IsLoading";
import { FooterButtons } from "../../common/components/FooterButtons";
import { PROJECT_LIST_COMPONENT_ID } from "../../features/projects/components/ProjectList";
import { PROJECT_LIST_COMPONENT_CLONE_LOADING_ID } from "./ProjectsList";
import { setAlert } from "../../features/alert/state/alertsSlice";
import { loadGraph } from "src/features/graph/state/graphLegacy";

interface IProjectThumbnail {
  decrementStep(): void;
  mode?: string;
}

const ProjectThumbnail = ({ decrementStep, mode = "" }: IProjectThumbnail) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { register, handleSubmit, setValue, control } = useForm<any>();

  const selectedProjectElements = useSelector(getCurrentElements);

  const mediasList = useSelector(mediaSelector);
  const currentFilter = useSelector((state: RootState) => state.media.currentFilter);

  const [currentThumbnail, setCurrentThumbnail] = useState<number>(47); // logoWixar by default

  const [fieldToDisplay, setFieldToDisplay] = useState({
    type: "media",
    mediaType: "image",
    fieldName: "source_thumbnail",
    currentValue: currentThumbnail,
    currentMedia: mediasList.find((m: IMedia) => Number(m.id) === Number(currentThumbnail)),
  });

  const { newProject, currentProjectGroup, editedProject, currentProject } = useSelector(
    (state: RootState) => {
      return {
        newProject: getNewProjectSelector(state),
        currentProjectGroup: getSelectedProjectGroup(state),
        currentProject: getCurrentProject(state),
        editedProject: getEditedProject(state),
      };
    },
  );

  useEffect(() => {
    if (mode === "edit") {
      const tbnId = Number(currentProject.source_thumbnail.id);
      setValue("thumbnailId", tbnId);
      setCurrentThumbnail(tbnId);
    }
  }, [currentProject.source_thumbnail]);

  // Dance around the media preview issues that arose from repurposing the "EditNodeForm" component
  const getDefaultTbn = () => {
    const enhancedField = {
      type: "media",
      mediaType: "image",
      fieldName: "source_thumbnail",
      currentValue: currentThumbnail,
      relevantSourceMedia: mediasList.find(
        (m: IMedia) => Number(m.id) === Number(currentThumbnail),
      ),
    };
    if (!currentThumbnail) {
      enhancedField.currentValue = -1;
      enhancedField.relevantSourceMedia = mediasList.find(
        (m: IMedia) => Number(m.id) === Number(currentThumbnail),
      );
    }
    return enhancedField;
  };

  const openGallery = () => {
    dispatch(setMediaFilter({ ...currentFilter, mediaType: "image" }));
    dispatch(setOriginalMediaGallery(currentThumbnail));
    dispatch(openStandardModal("modal-gallery"));
  };

  const onSubmit = () => {
    // openGallery();
  };

  // Callback from ModalGallery selection
  const onMediaChange = (selectedMedia: IMedia) => {
    const updatedFields: any = { ...fieldToDisplay, currentValue: selectedMedia.id };
    setFieldToDisplay(updatedFields);
    setCurrentThumbnail(Number(selectedMedia.id));

    dispatch(closeStandardModal("modal-gallery"));
  };

  const enhancedField = getDefaultTbn();

  useEffect(() => {
    dispatch(closeStandardModal("modal-gallery"));
  }, []);

  return (
    <>
      <IsLoading
        componentId={PROJECT_LIST_COMPONENT_CLONE_LOADING_ID}
        showSuccess={false}
        spinnerPlaceholder
      >
        <h1 className="text-2xl my-5 px-6 font-medium">{t("general.selectThumbnail")}</h1>
        <ModalGallery nodeId={"new-project-group-tbn"} onMediaChange={onMediaChange} />

        <form
          className="flex flex-col grow w-full justify-between"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="mb-10 w-full my-5 px-6">
            <div className="flex flex-col items-center justify-center bg-green-200 p-6 w-full max-w-max">
              <Controller
                control={control}
                name={"source_thumbnail"}
                render={({ field }) => (
                  <DynamicMedia
                    field={field as any}
                    fieldType={enhancedField.type}
                    register={register}
                    handleButtonClick={() => {
                      openGallery();
                    }}
                    currentMedia={enhancedField.currentValue as number}
                    media={enhancedField.relevantSourceMedia as IMedia}
                  />
                )}
              />
            </div>
          </div>

          <FooterButtons
            cancelText={t("general.cancel")}
            cancelCb={() =>
              navigate(
                mode === "edit"
                  ? `/project-groups/${currentProjectGroup.id}/list/${currentProject.id}/graph`
                  : `/project-groups/${currentProjectGroup.id}/list`,
              )
            }
            backText={t("general.back")}
            backCb={() => decrementStep()}
            nextText={mode === "edit" ? t("general.save") : t("general.create")}
            nextCb={() => {
              if (mode === "edit") {
                dispatch(
                  updateProject({
                    id: currentProject.id,
                    payload: {
                      name: editedProject.name,
                      source_thumbnail: currentThumbnail,
                      enabled_languages: editedProject.enabledLanguagesIds,
                    },
                    componentId: PROJECT_LIST_COMPONENT_ID,
                  }),
                ).then((res) => {
                  const updatedProjectElements = [...selectedProjectElements].map((element) => {
                    if (element.project.id === res.payload.id) {
                      return {
                        ...element,
                        project: res.payload,
                      };
                    }

                    return element;
                  });
                  dispatch(setCurrentElements(updatedProjectElements));
                  dispatch(loadGraph(currentProject.id));
                });
                navigate(
                  `/project-groups/${currentProjectGroup.id}/list/${currentProject.id}/graph`,
                );
              } else if (mode === "faitTemplate") {
                // TODO: call makeTemplate API
                dispatch(
                  makeTemplate({
                    project: newProject.projectId,
                    thumbnail: currentThumbnail,
                    newName: newProject.newName,
                    componentId: PROJECT_LIST_COMPONENT_CLONE_LOADING_ID,
                  }),
                ).then(() => {
                  navigate(`/project-groups/${currentProjectGroup.id}/list`);
                  dispatch(
                    setAlert({
                      type: "success",
                      msg: t("pages.faitTemplate.createdSuccessfully"),
                    }),
                  );
                });
              } else {
                dispatch(
                  duplicateProject({
                    project: newProject.projectId,
                    thumbnail: currentThumbnail,
                    projectGroup: newProject.projectGroupId,
                    newName: newProject.newName,
                    enabledLanguagesIds: newProject.enabledLanguagesIds,
                    componentId: PROJECT_LIST_COMPONENT_CLONE_LOADING_ID,
                  }),
                ).then((res: any) => {
                  dispatch(setCurrentProject(res.payload.data));
                  if (
                    Array.isArray(res.payload.data.projectGroupElement) &&
                    res.payload.data.projectGroupElement.length > 0
                  )
                    dispatch(
                      addCurrentElement({
                        order: res.payload.data.projectGroupElement[0].order,
                        project_group: currentProjectGroup,
                        project: res.payload.data,
                      }),
                    );
                  navigate(
                    `/project-groups/${currentProjectGroup.id}/list/${res.payload.data.id}/graph`,
                  );
                });
              }
            }}
          />
        </form>
      </IsLoading>
    </>
  );
};

export default ProjectThumbnail;
