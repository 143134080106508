/* eslint-disable indent */
import { IMedia } from "../../../model/unityObject";
import { ContentType, isSceneContentType } from "./dataTypes/ContentType";

export type FieldType = Field["type"];
export type MediaType = MediaField["mediaType"];

export type Field = ScalarField | MediaField;
export type ScalarField = TextField | BooleanField;

type TextField = {
  type: "text";
  fieldName: string;
  currentValue?: string;
};
export type MediaField = {
  type: "media";
  mediaType: "video" | "image" | "audio" | "pdf" | "model3d";
  fieldName: string;
  currentValue?: number | IMedia;
};
type BooleanField = {
  type: "boolean";
  fieldName: string;
  currentValue?: boolean;
};

export const isScalarField = (field: Field): field is ScalarField =>
  field.type === "text" || field.type === "boolean";
export const isMediaField = (field: Field): field is MediaField => field.type === "media";

export const extractFieldsFromContentType = (contentType: ContentType): Field[] => {
  // NO VALIDATION FOR THE FOLLOWING ELEMENTS
  if (contentType.modelName === "content-scoring-scene") {
    return [];
  }

  // the result array
  // there is always the name field to validate
  const fields: Field[] = [
    { type: "text", fieldName: "name", currentValue: contentType.object.name },
  ];

  if (isSceneContentType(contentType)) {
    fields.push({
      type: "media",
      mediaType: "image",
      fieldName: "source_media",
      currentValue: contentType.object.source_media,
    });
    fields.push({
      type: "media",
      mediaType: "model3d",
      fieldName: "source_model3d",
      currentValue: contentType.object.source_model3d,
    });
  } else {
    switch (contentType.modelName) {
      case "content-audio":
        fields.push({
          type: "media",
          mediaType: "audio",
          fieldName: "source_audio",
          currentValue: contentType.object.source_audio,
        });
        break;
      case "content-onboarding":
        fields.push({
          type: "media",
          mediaType: "image",
          fieldName: "source_image_2d",
          currentValue: contentType.object.source_image_2d,
        });
        break;
      case "content-thumbnail":
        fields.push({
          type: "media",
          mediaType: "image",
          fieldName: "source_image",
          currentValue: contentType.object.source_image,
        });
        break;
      case "content-quizz-2":
        fields.push(
          {
            type: "media",
            mediaType: "image",
            fieldName: "answer_1_image",
            currentValue: contentType.object.answer_1_image,
          },
          {
            type: "media",
            mediaType: "image",
            fieldName: "answer_2_image",
            currentValue: contentType.object.answer_2_image,
          },
          {
            type: "media",
            mediaType: "image",
            fieldName: "answer_3_image",
            currentValue: contentType.object.answer_3_image,
          },
        );
        break;
      case "content-tuto":
        fields.push({
          type: "media",
          mediaType: "image",
          fieldName: "source_logo",
          currentValue: contentType.object.source_logo,
        });
        break;
      case "content-video-2-d":
        fields.push({
          type: "media",
          mediaType: "video",
          fieldName: "source_media",
          currentValue: contentType.object.source_media,
        });
        break;
      case "content-pdf-viewer":
        fields.push({
          type: "media",
          mediaType: "pdf",
          fieldName: "source_pdf",
          currentValue: contentType.object.source_pdf,
        });
        break;
      case "content-3-d-viewer":
        fields.push({
          type: "media",
          mediaType: "model3d",
          fieldName: "source_model3d",
          currentValue: contentType.object.source_model3d,
        });
        break;
      case "content-speech-to-check":
      case "content-photo-catch":
      case "content-quizz-1":
      case "content-hotspot-scene-image":
      case "content-hotspot-scene-video":
      case "content-text":
      case "content-zone-detect":
      case "content-quizz-3":
      case "content-yes-no-question":
      case "content-zone-sort":
      case "content-element-catch":
      case "content-highlight":
      case "content-danger-catch":
      case "content-tuto2":
      case "content-quizz-sort":
      case "content-fire-extinguisher":
      case "content-fire":
      case "content-quizz-image-multiple":
        break;
      /*
      case "content-button-1":
      case "content-button-2":
      case "content-clean":
      case "content-scoring-general":
      case "content-vr-locator":
      case "content-zone-detect":
        break; // only the name field is of interest
       */
    }
  }

  return fields;
};
