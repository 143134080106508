import { useTranslation } from "react-i18next";
import { Outlet, useLocation } from "react-router-dom";
import ModalConfirmUserDeletion from "src/common/components/Modal/ModalConfirmUserDeletion";
import Navigation from "src/common/components/Navigation/Navigation";

const ParticipantsWrapper = () => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <>
      <Navigation
        title={t("pages.project.projectGroupParticipants.participants")}
        showGoBackButton={
          location.pathname !== "/participants/" && location.pathname !== "/participants"
        }
        backDepth={1}
      />

      <Outlet />
      <ModalConfirmUserDeletion />
      {/* <AddTeamModal />
      <ParticipantCsv /> */}
    </>
  );
};

export default ParticipantsWrapper;
