import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AppDispatch } from "src/common/state/store";
import OrganizationInputStyle from "../Input/OrganizationInputStyle";
import { IMoodleEnv, IOrganization, IUser } from "src/model/model";
import { generateMoodleEnv, revokeMoodleEnv } from "src/features/profile/state/profileSlice";
import { ORGANIZATION_COMPONENT_ID } from "src/pages/Profile/Organization";
import { setAlert } from "src/features/alert/state/alertsSlice";
import { useState } from "react";
import { useCallbackPrompt } from "src/common/util/useCallbackPrompt";
import ModalConfirmBeforeNavigatingOut from "../Modal/ModalConfirmBeforeNavigatingOut";
import { Input } from "../Input";
import { Tooltip } from "@mui/material";
import { ButtonIcon } from "../Button";

interface IMoodleManagement {
  organization: IOrganization;
  currentUser: IUser;
  moodleEnv?: IMoodleEnv;
  key: string;
}

type FormValues = {
  moodle_url?: string;
  token?: string;
};

const validateUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (_) {
    return false;
  }
};

const MoodleManagement = ({ currentUser, moodleEnv, key }: IMoodleManagement) => {
  const { t } = useTranslation();

  const dispatch: AppDispatch = useDispatch();

  const [disabledMoodleUrl, setDisabledMoodleUrl] = useState(true);

  const { register, handleSubmit, getValues, reset } = useForm<FormValues>({
    defaultValues: {
      moodle_url: moodleEnv?.url,
      token: moodleEnv?.token,
    },
  });

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(moodleEnv?.token)
      .then(() =>
        dispatch(
          setAlert({
            type: "success",
            msg: t("alerts.tokenCopiedToClipboard"),
          }),
        ),
      )
      .catch((err) => console.error("Failed to copy text: ", err));
  };

  const CopyToClipboard = () => (
    <ButtonIcon
      type="button"
      text={t("general.copy")}
      onClick={handleCopyToClipboard}
      icon="ClipboardCopyIcon"
      styled=""
    />
  );

  const onSubmit = (data: FormValues) => {
    dispatch(
      generateMoodleEnv({ componentId: ORGANIZATION_COMPONENT_ID, moodleUrl: data.moodle_url }),
    )
      .then(() => {
        setDisabledMoodleUrl(true);
        dispatch(
          setAlert({
            type: "success",
            msg: t("alerts.generateTokenSuccess"),
          }),
        );
      })
      .catch((e: any) => {
        console.error(e);
      });
  };

  const hasSomethingChanged = () => {
    if (moodleEnv?.url !== getValues("moodle_url")) {
      return true;
    }

    return false;
  };

  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
    hasSomethingChanged(),
  );
  return (
    <div key={key}>
      <h2 className="text-2xl font-medium mb-2 mt-2 xl:mt-4">
        {t("pages.profile.organization.moodleSectionTitle")}
      </h2>
      <h3 className="py-3 mb-2">{t("pages.profile.organization.moodleSectionSubtitle")}</h3>

      <form className="h-full mb-2" onSubmit={handleSubmit(onSubmit)}>
        <OrganizationInputStyle
          title={t("pages.profile.organization.moodleUrl")}
          currUserId={currentUser?.id}
          disabled={disabledMoodleUrl}
          setDisabled={setDisabledMoodleUrl}
        >
          <Input
            style={{ minWidth: "30vw" }}
            autoFocus
            type="text"
            highlight={disabledMoodleUrl}
            readOnly={disabledMoodleUrl}
            registerFct={() =>
              register("moodle_url", {
                required: "Moodle URL is required",
                validate: {
                  validUrl: (value) => validateUrl(value) || "Please enter a valid URL",
                },
              })
            }
            className={`font-medium bg-white read-only:bg-slate-100 read-only:border-transparent read-only:shadow-none read-only:focus:ring-0 text-gray-600 max-w-max text-center sm:text-left border-0
                 `}
            placeholder={t("pages.profile.organization.moodlePlaceholder")}
          />
        </OrganizationInputStyle>

        <OrganizationInputStyle
          hidden
          title={t("pages.profile.organization.generatedApiToken")}
          currUserId={currentUser?.id}
          disabled={disabledMoodleUrl}
          setDisabled={setDisabledMoodleUrl}
          customButton={moodleEnv?.token && <CopyToClipboard />}
        >
          <Input
            style={{ minWidth: "30vw" }}
            readOnly
            type="text"
            highlight={false}
            registerFct={() => register("token")}
            className={`min-w-80 font-medium bg-white read-only:bg-slate-100 read-only:border-transparent read-only:shadow-none read-only:focus:ring-0 text-gray-600 max-w-max text-center sm:text-left border-0
                 `}
            placeholder={t("pages.profile.organization.noActiveToken")}
            value={getValues("token")}
          />
        </OrganizationInputStyle>

        <div className="flex justify-end">
          {moodleEnv && (
            <button
              type="button"
              className="btn-alternative-outline mr-4"
              onClick={() => {
                dispatch(revokeMoodleEnv({})).then((res) => {
                  if (
                    JSON.stringify(res?.payload).includes("Moodle API key revoked successfully")
                  ) {
                    reset({
                      moodle_url: undefined,
                      token: undefined,
                    });
                    dispatch(
                      setAlert({
                        type: "success",
                        msg: t("alerts.revokeTokenSuccess"),
                      }),
                    );
                  }
                });
              }}
            >
              {t("pages.profile.organization.revoke")}
            </button>
          )}
          <Tooltip
            title={
              !validateUrl(getValues("moodle_url"))
                ? t("pages.profile.organization.enterValidUrl")
                : ""
            }
          >
            <button
              type="submit"
              className="btn-primary-fill"
              disabled={
                Boolean(moodleEnv?.token) ||
                Boolean(moodleEnv?.url) ||
                !validateUrl(getValues("moodle_url"))
              }
            >
              {t("pages.profile.organization.generate")}
            </button>
          </Tooltip>
        </div>
      </form>

      <ModalConfirmBeforeNavigatingOut
        showDialog={showPrompt}
        cancelNavigation={cancelNavigation}
        confirmNavigation={confirmNavigation}
      />
    </div>
  );
};

export default MoodleManagement;
